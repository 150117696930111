import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { Cropper, ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Tooltip } from "react-tippy";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import Slider, { Range } from "rc-slider";
import { isMobile } from "react-device-detect";
import Toggle from "react-toggle";
import Modal from "react-responsive-modal";
import CurrencyDisplay from "components/helpers/CurrencyDisplay";
import { RadioGroup } from "@headlessui/react";

class PrintSizeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cropOrientationPortrait: null,
      loading: false,
      zoom: 0,
      initialZoom: 1,
      lowDPI: null,
      cropperReady: false,
      saving: false,
      tabURLS: {
        0: props.print_size_path,
        1: props.square_print_size_path,
        2: props.original_print_sizes_path,
      },
    };

    this.saveSizes = this.saveSizes.bind(this);
    this.saveOriginalSizes = this.saveOriginalSizes.bind(this);
    this.setSizes = this.setSizes.bind(this);
    this.sizeSelected = this.sizeSelected.bind(this);
    this.paperTypeSelected = this.paperTypeSelected.bind(this);
    this.validateAndFrame = this.validateAndFrame.bind(this);
    this.completeResponse = this.completeResponse.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
    this.handleOrientationChange = this.handleOrientationChange.bind(this);
    this.flipSizes = this.flipSizes.bind(this);
    this.changeZoom = this.changeZoom.bind(this);
    this.cropperMounted = this.cropperMounted.bind(this);
    this.cropUpdated = this.cropUpdated.bind(this);
    this.dpiUpdate = this.dpiUpdate.bind(this);
  }

  componentDidMount() {
    this.onTabChange(0);
  }

  saveOriginalSizes(data) {
    const {
      source_ratio,
      shortest_print_length,
      image,
      max_glass_short_mm,
      max_glass_long_mm,
    } = this.props;
    const dpi = 100;
    const longEdgePX = Math.max(image.width, image.height);

    let sizes = data.sizes.filter(
      (item) => item.longest_inches <= longEdgePX / dpi
    );

    sizes.map((item) => {
      let x = source_ratio * 2 + 2;
      let long = Math.floor((item.perimeter_mm / x) * source_ratio);
      let short = Math.floor(long / source_ratio);

      item.short = source_ratio > 1 ? short : long;
      item.long = source_ratio > 1 ? long : short;
      item.long_mm = item.short;
      item.short_mm = item.long;
      item.unit = "mm";
      item.title =
        source_ratio > 1
          ? `${item.long} x ${item.short}mm`
          : `${item.short} x ${item.long}mm`;
      return item;
    });

    this.setSizes(sizes);
  }

  saveSizes(data) {
    const { shortest_print_length } = this.props;

    let sizes = data.sizes.filter(
      (item) => item.shortest_inches <= shortest_print_length
    );
    this.setSizes(sizes);
  }

  setSizes(sizes) {
    const { source_ratio } = this.props;
    const { cropper } = this.state;

    let middle = sizes[Math.floor(sizes.length / 2)];

    let ratio =
      source_ratio > 1
        ? middle.long / middle.short
        : middle.short / middle.long;

    this.setState(
      {
        sizes: sizes,
        print_size: middle,
        print_size_costs: middle.prices,
        paper_type_id: middle.prices[0].paper_type_id,
        print_price: middle.prices[0].price,
        ratio: ratio,
        loading: false,
        cropOrientationPortrait: source_ratio > 1,
      },
      () => this.dpiUpdate()
    );

    cropper.setAspectRatio(ratio);
  }

  sizeSelected(active) {
    const { cropOrientationPortrait, paper_type_id, cropper } = this.state;
    const { source_ratio } = this.props;

    let print_cost = active.prices.find(
      (item) => item.paper_type_id == paper_type_id
    );
    let new_price = print_cost ? print_cost.price : active.prices[0].price;
    let new_paper_type = print_cost
      ? print_cost.paper_type_id
      : active.prices[0].paper_type_id;
    let isPortrait = cropOrientationPortrait || source_ratio > 1 ? true : false;
    let ratio = cropOrientationPortrait
      ? active.long / active.short
      : active.short / active.long;

    this.setState(
      {
        print_size: active,
        ratio: ratio,
        print_size_costs: active.prices,
        print_price: new_price,
        paper_type_id: new_paper_type,
      },
      () => this.dpiUpdate()
    );

    cropper.setAspectRatio(ratio);
  }

  paperTypeSelected(active) {
    this.setState({
      paper_type_id: active.paper_type_id,
      print_price: active.price,
    });
  }

  validateAndFrame(e) {
    const { crop, preview_width } = this.state;
    const { crop_image_path, image } = this.props;

    this.setState({ saving: true });

    e.preventDefault();
    $.ajax({
      method: "PATCH",
      url: crop_image_path,
      data: {
        image: {
          id: image.id,
          cx: crop.x,
          cy: crop.y,
          cw: crop.width,
          ch: crop.height,
        },
        preview_width: preview_width,
      },
    }).done(this.completeResponse);
  }

  completeResponse(data) {
    const { print_size, paper_type_id, cropOrientationPortrait, source_ratio } =
      this.state;
    const { frame_effects_path } = this.props;

    let isPortrait = cropOrientationPortrait || source_ratio > 1 ? true : false;
    let width = isPortrait ? print_size.long : print_size.short;
    let height = isPortrait ? print_size.short : print_size.long;

    var params = {
      width: width,
      height: height,
      unit: print_size.unit,
      image_id: data.image.id,
      paper_type_id: paper_type_id,
      frame_size_id: print_size.id,
    };
    var url = frame_effects_path + "?" + jQuery.param(params);
    window.location.href = url;
  }

  onTabChange(tabIndex) {
    const handler = tabIndex == 2 ? this.saveOriginalSizes : this.saveSizes;

    this.setState({
      loading: true,
      tabIndex: tabIndex,
    });

    $.ajax({
      method: "GET",
      url: this.state.tabURLS[tabIndex],
      data: {
        image_id: this.props.image.id,
      },
    }).done(handler);
  }

  handleOrientationChange(e) {
    this.flipSizes();

    this.setState(
      { cropOrientationPortrait: !this.state.cropOrientationPortrait },
      () => this.dpiUpdate()
    );
  }

  flipSizes() {
    const { sizes, print_size, ratio, initialZoom, cropper } = this.state;
    var newSizes = [];
    for (var i = sizes.length - 1; i >= 0; i--) {
      let item = sizes[i];
      let w = item.width;
      let h = item.height;
      item.ratio = 1 / item.ratio;
      item.width = h;
      item.height = w;
      newSizes.unshift(item);
    }
    let ps = print_size;
    ps.ratio = 1 / ratio;

    this.setState({
      sizes: newSizes,
      print_size: ps,
      ratio: ps.ratio,
      zoom: initialZoom,
    });

    cropper.setAspectRatio(ps.ratio);
  }

  changeZoom(val) {
    this.setState({ zoom: val });
    this.state.cropper.zoomTo(parseFloat(val));
  }

  cropperMounted() {
    const canvasData = this.state.cropper.getCanvasData();
    const zoomRatio = canvasData.width / canvasData.naturalWidth;

    this.setState({
      initialZoom: zoomRatio,
      zoom: zoomRatio,
      cropperReady: true,
      preview_width: this.state.cropper.getImageData().naturalWidth,
    });
  }

  cropUpdated() {
    let data = this.state.cropper.getData();

    this.setState({ crop: data }, () => this.dpiUpdate());
  }

  dpiUpdate() {
    const { print_size, crop, preview_width } = this.state;
    const { image } = this.props;

    if (print_size && crop && preview_width) {
      let longest = Math.max(crop.width, crop.height);
      let scale = image.width / preview_width;
      let dpi = Math.round((longest * scale) / print_size.longest_inches);
      this.setState({ dpi: dpi, lowDPI: dpi <= 90 });
    }
  }

  render() {
    const { print_size, zoom, ratio, initialZoom, crop, dpi, sizes, loading } =
      this.state;
    const { preview_url } = this.props;
    let cropperHeight = isMobile ? window.innerWidth : 400;
    let cropperWidth = isMobile ? window.innerWidth : 500;

    return (
      <div className="grid md:grid-cols-2 md:gap-x-10">
        <div className="md:mt-5 px-3">
          <Cropper
            ref="cropper"
            src={preview_url}
            style={{ height: cropperHeight, maxWidth: cropperWidth }}
            dragMode="move"
            aspectRatio={ratio}
            viewMode={1}
            autoCrop={true}
            autoCropArea={1}
            cropBoxMovable={false}
            cropBoxResizable={false}
            zoomOnWheel={false}
            movable={true}
            guides={true}
            onInitialized={(instance) => this.setState({ cropper: instance })}
            ready={this.cropperMounted}
            crop={this.cropUpdated}
          />

          <div className="m-4 text-center">
            <Slider
              min={initialZoom}
              max={1}
              step={0.025}
              value={zoom}
              onChange={this.changeZoom}
            />
            {print_size && crop && <DPIControl dpi={dpi} />}

            {this.props.image.height * this.props.image.width < 2048 * 2048 && (
              <UpscaleModalButton
                upscale_available={this.props.upscale_available}
                frame_upscale_path={this.props.frame_upscale_path}
                image={this.props.image}
                ai_icon_path={this.props.ai_icon_path}
                ai_icon_loading_path={this.props.ai_icon_loading_path}
                lowDPI={this.state.lowDPI}
              />
            )}
          </div>
        </div>
        <div className="">
          {sizes || loading ? (
            <SelectContainer
              {...this.state}
              image_id={this.props.image.id}
              custom_size_path={this.props.custom_size_path}
              preloader={this.props.preloader}
              is_pro={this.props.is_pro}
              tax_rate={this.props.tax_rate}
              validateAndFrame={this.validateAndFrame}
              sizeSelected={this.sizeSelected}
              handleOrientationChange={this.handleOrientationChange}
              paperTypeSelected={this.paperTypeSelected}
              max_glass_short_mm={this.props.max_glass_short_mm}
              max_glass_long_mm={this.props.max_glass_long_mm}
              onTabChange={this.onTabChange}
            />
          ) : (
            <BlankState />
          )}
        </div>
      </div>
    );
  }
}

class UpscaleModalButton extends React.Component {
  constructor(props) {
    super();
    this.state = {
      modalIsOpen: false,
      isWaiting: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.upscaleImage = this.upscaleImage.bind(this);
    this.completeResponse = this.completeResponse.bind(this);
    this.handleImageLoaded = this.handleImageLoaded.bind(this);
    this.saveUpscaledImage = this.saveUpscaledImage.bind(this);
    this.completeSave = this.completeSave.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
    segment_track("Upscale Modal: Opened");
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  upscaleImage() {
    this.setState({ isWaiting: true });
    segment_track("Upscale Modal: Processed");

    $.ajax({
      method: "POST",
      url: this.props.frame_upscale_path,
      data: {
        image: {
          id: this.props.image.id,
        },
      },
    }).done(this.completeResponse);
  }

  completeResponse(data) {
    this.setState({
      isWaiting: false,
      upscaledImageURL: data.image_url,
      isLoadingImage: true,
    });
  }

  saveUpscaledImage() {
    this.setState({ isWaiting: true });
    segment_track("Upscale Modal: Accepted");

    $.ajax({
      method: "PATCH",
      url: this.props.frame_upscale_path,
      data: {
        image: {
          id: this.props.image.id,
          url: this.state.upscaledImageURL,
        },
      },
    }).done(this.completeSave);
  }

  completeSave(data) {
    window.location.href = data.redirect_to;
  }

  handleImageLoaded() {
    this.setState({ isLoadingImage: false });
  }

  render() {
    let modalClasses = classNames(
      this.state.upscaledImageURL && "w-11/12",
      "custom-modal upscale-modal"
    );
    return (
      <div>
        {!this.props.image.ai_upscaled &&
          this.props.upscale_available == "available" && (
            <Tooltip
              title={"Increase photo resolution"}
              arrow={true}
              position="bottom"
              trigger="mouseenter"
              open={this.props.lowDPI}
            >
              <button
                onClick={this.openModal}
                className="btn btn-pale mt-5 mini-heading text-xss cursor-pointer"
              >
                <i className="fa-solid fa-microchip-ai mr-1 text-xl"></i>
                Upscale
              </button>
            </Tooltip>
          )}

        <Modal
          open={this.state.modalIsOpen}
          onOpen={this.afterOpenModal}
          onClose={this.closeModal}
          classNames={{
            overlay: "custom-overlay",
            modal: modalClasses,
          }}
        >
          <div className="modal-scroll">
            {this.state.upscaledImageURL ? (
              <>
                <div className="text-center">
                  <h1 className="mb-3 text-3xl md:text-4xl">
                    Verify Upscaled Photo
                  </h1>
                  <p className="text-lg">
                    Please inspect the new image before approving.
                  </p>
                  <button
                    disabled={this.state.isWaiting}
                    onClick={this.saveUpscaledImage}
                    className="btn btn-primary md:btn-lg mt-5 disabled:bg-brand-400 disabled:cursor-not-allowed"
                  >
                    {this.state.isWaiting ? "Saving..." : "Approve Image"}
                    {this.state.isWaiting ? (
                      <i className="fa-solid fa-spinner fa-spin ml-2"></i>
                    ) : (
                      <i className="fa-solid fa-check ml-2"></i>
                    )}
                  </button>
                  <button
                    onClick={this.closeModal}
                    className="btn btn-primary md:btn-lg btn-pale mt-5 ml-3"
                  >
                    No thanks
                  </button>

                  <div className="grid grid-cols-2 pt-5">
                    <div>
                      <span className="mini-heading">Previous Resolution</span>
                      <div>
                        <span className="mt-2 text-sm px-2 py-1 badge badge-pale">
                          {this.props.image.width}px x {this.props.image.height}
                          px
                        </span>
                      </div>
                    </div>
                    <div>
                      <span className="mini-heading">New Resolution</span>
                      <div>
                        <span className="mt-2 text-sm px-2 py-1 badge bg-stone-900 text-white">
                          {this.props.image.width * 2}px x{" "}
                          {this.props.image.height * 2}
                          px
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="my-3 relative">
                  <ReactCompareSlider
                    itemOne={
                      <CompareImage
                        labelText="Original"
                        src={this.props.image.url}
                        classAdd="left"
                      />
                    }
                    itemTwo={
                      <CompareImage
                        labelText="New Version"
                        src={this.state.upscaledImageURL}
                        classAdd="right"
                      />
                    }
                  />
                </div>
              </>
            ) : (
              <>
                <div className="text-center max-w-2xl mx-auto">
                  {this.state.isWaiting ? (
                    <img
                      src={this.props.ai_icon_loading_path}
                      className="w-24 mx-auto"
                    />
                  ) : (
                    <img
                      src={this.props.ai_icon_path}
                      className="w-24 mx-auto"
                    />
                  )}
                  <h2 className="mb-3">Increase Your Photo's Resolution</h2>
                  <p className="text-lg">
                    AI Upscale can increase the resolution of your photo for
                    better print quality and more size options.
                  </p>

                  <button
                    disabled={this.state.isWaiting}
                    onClick={this.upscaleImage}
                    className="btn btn-primary btn-lg mt-5 disabled:bg-brand-400 disabled:cursor-not-allowed"
                  >
                    {this.state.isWaiting ? "Processing..." : "Try it"}
                    {this.state.isWaiting && (
                      <i className="fa-solid fa-spinner fa-spin ml-2"></i>
                    )}
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

const CompareImage = ({ src, classAdd, onLoad, labelText }) => {
  return (
    <div>
      <div className={`compare-label ${classAdd}`}>{labelText}</div>
      <img src={src} className="compare-image" onLoad={onLoad} />
    </div>
  );
};

const ISOSize = ({ long_mm, title }) => {
  switch (long_mm) {
    case 297:
      return "A4";
    case 420:
      return "A3";
    case 594:
      return "A2";
    case 841:
      return "A1";
    case 1189:
      return "A0";
    default:
      return title;
  }
};

const SelectContainer = (props) => {
  let selector = (
    <PrintSizeSelect
      is_pro={props.is_pro}
      tax_rate={props.tax_rate}
      sizes={props.sizes}
      print_size={props.print_size}
      print_price={props.print_price}
      onSelect={props.sizeSelected}
      loading={props.loading}
      preloader={props.preloader}
      max_glass_short_mm={props.max_glass_short_mm}
      max_glass_long_mm={props.max_glass_long_mm}
    />
  );

  let isDisabled =
    props.loading || props.lowDPI || !props.cropperReady || props.saving
      ? true
      : false;

  return (
    <div className="">
      <h2 className="text-3xl text-center md:text-left">Select a print size</h2>
      <p className="text-muted hidden md:block mt-3">
        Your final frame size will be determined by the border and frame style
        you choose in a later step.
      </p>

      <div className="mt-4 py-3">
        <div className="row">
          <div className="col-sm-24">
            <Tabs onSelect={props.onTabChange}>
              <TabList>
                <Tab>Recommended</Tab>
                <Tab>Square</Tab>
                <Tab>Original</Tab>
              </TabList>
              <TabPanel>
                <p className="mb-4">
                  Based on the resolution of your photo, these are sizes we
                  recommend for a high-quality print.
                </p>
                {selector}
                {props.print_size && (
                  <OrientationSwitch
                    cropOrientationPortrait={props.cropOrientationPortrait}
                    handleOrientationChange={props.handleOrientationChange}
                  />
                )}
              </TabPanel>
              <TabPanel>
                <p>
                  Select a size below and adjust the position of your photo.
                </p>
                {selector}
              </TabPanel>
              <TabPanel>
                <p>
                  These sizes preserve the original aspect ratio of your image.
                </p>
                {selector}
              </TabPanel>
            </Tabs>
            {props.print_size && (
              <div>
                <div className="bg-pale p-6 pt-0 pb-3">
                  {props.print_size_costs && (
                    <div className="">
                      <PaperTypeSelect
                        print_size_costs={props.print_size_costs}
                        paper_type_id={props.paper_type_id}
                        onSelect={props.paperTypeSelected}
                        loading={props.loading}
                        preloader={props.preloader}
                      />
                    </div>
                  )}
                  <span className="text-muted text-sm block my-3">
                    {props.is_pro && (
                      <span>
                        <div className="badge badge-brand">Pro feature: </div>{" "}
                        Need a custom size? Select your paper type then{" "}
                        <a
                          href={
                            props.custom_size_path +
                            "?" +
                            "paper_type_id=" +
                            props.paper_type_id +
                            "&image_id=" +
                            props.image_id
                          }
                        >
                          click here.
                        </a>
                      </span>
                    )}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="text-xs mt-2">
            Can't see the size you want? <a href="/contact">Contact us</a> for
            help.
          </div>
        </div>
      </div>

      {props.lowDPI && (
        <div className="alert alert-danger mb-4">
          ❗️ Image resolution is too low at this zoom level and print size.
          Zoom out or choose a smaller print size.
        </div>
      )}
      <div className="text-center md:text-left mt-2">
        <button
          onClick={props.validateAndFrame}
          disabled={isDisabled}
          className="btn btn-primary btn-lg block w-full md:w-auto  mr-2 disabled:bg-brand-300 disabled:cursor-not-allowed"
        >
          Next: Effects
          {props.saving || props.loading ? (
            <i className="fa-solid fa-spinner fa-spin ml-2"></i>
          ) : (
            <i className="fa-solid fa-arrow-right ml-2 text-base" />
          )}
        </button>
      </div>
    </div>
  );
};

const OrientationSwitch = ({
  cropOrientationPortrait,
  handleOrientationChange,
}) => {
  return (
    <div className="mt-5">
      <Toggle
        id="orientation"
        icons={false}
        defaultChecked={!cropOrientationPortrait}
        onChange={handleOrientationChange}
      />
      <label
        style={{ top: "-8px", position: "relative" }}
        className="ml-2 mini-heading text-xs"
        htmlFor="orientation"
      >
        <span
          className={
            !cropOrientationPortrait ? "text-stone-900" : "text-stone-400"
          }
        >
          Portrait
        </span>{" "}
        ·
        <span
          className={
            cropOrientationPortrait ? "text-stone-900" : "text-stone-400"
          }
        >
          {" "}
          Landscape
        </span>
      </label>
    </div>
  );
};

const BlankState = () => {
  return (
    <div className="alert alert-warning mt-2 py-3">
      <h4>Whoops 😕</h4>
      <span>
        This image isn't a high enough resolution for us to print. Please try
        another file and avoid cropping too tightly.
      </span>
      <p className="text-muted small mt-1">
        Please feel free to <a href="/contact">contact us</a> if you're having
        problems.
      </p>
    </div>
  );
};

class PaperTypeSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
    this.onSelect = this.onSelect.bind(this);
    this.expandOptions = this.expandOptions.bind(this);
  }

  onSelect(e) {
    console.log(e);
    this.props.onSelect(
      this.props.print_size_costs.find((item) => item.paper_type_id == e)
    );
  }

  expandOptions(e) {
    e.preventDefault();
    this.setState({ expanded: true });
  }

  render() {
    const { print_size_costs, paper_type_id } = this.props;
    const { expanded } = this.state;

    return (
      <div>
        <div className="mb-2 text-sm text-muted">Paper type</div>
        <div
          className={`categories-radio-buttons block-radio white-bgs ${
            !expanded && "contracted"
          }`}
        >
          <RadioGroup value={paper_type_id} onChange={this.onSelect}>
            <div className="space-y-4">
              {print_size_costs.map((item, i) => (
                <RadioGroup.Option
                  value={item.paper_type_id}
                  key={item.paper_type_id}
                  className={({ checked, active }) =>
                    classNames(
                      expanded || i == 0 ? "flex" : "hidden",
                      checked ? "border-transparent" : "border-white",
                      active ? "border-brand-600 ring-2 ring-brand-600" : "",
                      "relative cursor-pointer rounded-lg border bg-white px-4 py-4 focus:outline-none sm:justify-between"
                    )
                  }
                >
                  {({ active, checked }) => (
                    <>
                      <span
                        className={classNames(
                          checked
                            ? "bg-brand-600 border-transparent"
                            : "bg-white border-gray-300",
                          active ? "ring-2 ring-offset-2 ring-brand-600" : "",
                          "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                        )}
                        aria-hidden="true"
                      >
                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                      </span>
                      <span className="flex items-center ml-3">
                        <span className="flex flex-col text-sm">
                          <RadioGroup.Label
                            as="span"
                            className="font-bold text-gray-900"
                          >
                            {item.paper_type}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className="text-gray-500"
                          >
                            <span className="block sm:inline">
                              {item.paper_desc}
                            </span>
                          </RadioGroup.Description>
                        </span>
                      </span>
                      <RadioGroup.Description
                        as="span"
                        className="mt-2 flex text-sm sm:ml-4 sm:mt-0 sm:flex-col sm:text-right"
                      >
                        <span className="font-bold text-gray-900">
                          {item.price_jump != "0" &&
                            `+${global.App.currencySymbol}${item.price_jump}`}
                        </span>
                      </RadioGroup.Description>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>
        {!expanded && print_size_costs.length > 1 && (
          <a
            href="#"
            className="text-sm block my-3"
            onClick={this.expandOptions}
          >
            + More paper options
          </a>
        )}
      </div>
    );
  }
}

const DPIControl = ({ dpi }) => {
  return (
    <div className="mini-heading text-dark mt-3">
      Print resolution {dpi} DPI · <DPIWarning dpi={dpi} />
    </div>
  );
};

const DPIWarning = ({ dpi }) => {
  if (dpi <= 90) {
    return <span className="text-danger">Consider a smaller print size</span>;
  } else if (dpi > 90 && dpi <= 125) {
    return <span className="text-warning">Consider a smaller print size</span>;
  } else {
    return <span className="text-success">Lookin' good</span>;
  }
};

const PrintSizeSelect = ({
  sizes,
  onSelect,
  loading,
  print_size,
  preloader,
  print_price,
  is_pro,
  tax_rate,
  max_glass_long_mm,
  max_glass_short_mm,
}) => {
  return (
    <div>
      {sizes && !loading ? (
        <div>
          <div className="categories-radio-buttons">
            <RadioGroup
              value={print_size}
              onChange={(e) => onSelect(e)}
              className="mt-2"
            >
              <div className="">
                {sizes.map((option) => (
                  <RadioGroup.Option
                    key={option.id}
                    value={option}
                    className={({ active, checked }) =>
                      classNames(
                        active ? "ring-2 ring-brand-600 ring-offset-2" : "",
                        checked
                          ? "bg-brand-600 text-white hover:bg-brand-500"
                          : "ring-1 ring-inset ring-white bg-white text-gray-900 hover:bg-gray-50",
                        "items-center justify-center rounded-md mr-2 mb-2 py-2.5 px-2.5 text-sm font-semibold  inline-block cursor-pointer"
                      )
                    }
                  >
                    <RadioGroup.Label as="span">
                      <ISOSize long_mm={option.long_mm} title={option.title} />
                    </RadioGroup.Label>
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
          <div className="smallish">
            <strong className="block mt-2 mb-1">
              Printed & custom framed from{" "}
              <strong>
                <CurrencyDisplay value={print_size.frame_price_no_mat} />
              </strong>
              {is_pro && (
                <small>
                  {" "}
                  inc {global.App.taxName}{" "}
                  <span className="badge badge-brand">Pro</span>
                </small>
              )}
            </strong>

            {print_size.frame_price && (
              <div>
                {Math.max(print_size.long_mm, print_size.short_mm) + 100 <
                  max_glass_long_mm &&
                Math.min(print_size.long_mm, print_size.short_mm) + 100 <
                  max_glass_short_mm ? (
                  <div className="text-xs text-muted">
                    With {global.App.mat} from{" "}
                    <strong>
                      <CurrencyDisplay value={print_size.frame_price} />
                    </strong>
                  </div>
                ) : (
                  <div className="text-xs text-red-800">
                    {window.App.mat.charAt(0).toUpperCase() +
                      window.App.mat.slice(1)}{" "}
                    unavailable at this size
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="bg-white p-3 mt-3">
          <img src={preloader} className="w-8 h-8 mx-auto" />
        </div>
      )}
    </div>
  );
};

export default PrintSizeForm;
