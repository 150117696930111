import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import Modal from "react-responsive-modal";

class OrderItemGiftOptions extends React.Component {
  constructor(props) {
    super();
    this.state = {
      modalIsOpen: false,
      gift_options: props.gift_options,
      gift_option: props.gift_option,
      gift_option_note: props.order_item.gift_option_note || "",
      focussed: false,
      isLoading: false,
      remaining_char: 140,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.completeResponse = this.completeResponse.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.saveState = this.saveState.bind(this);
  }
  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleSubmit(e) {
    e.preventDefault();

    $.ajax({
      method: "PATCH",
      url: this.props.url,
      data: {
        order_item: {
          gift_option_note: this.state.gift_option_note,
          gift_option_id: this.state.gift_option.id,
        },
      },
    }).done(this.completeResponse);
  }

  completeResponse(data) {
    if (data.success) {
      this.setState({ modalIsOpen: false });
      location.reload();
    } else {
      alert("Something went wrong");
    }
  }

  changeHandler(e) {
    this.setState({
      gift_option_note: e.target.value,
      remaining_char: 140 - e.target.value.length,
    });
  }

  saveState(active) {
    this.setState({
      gift_option: active,
    });
  }

  render() {
    return (
      <div>
        <a href="#" onClick={this.openModal} className="font-weight-bold">
          <i className="fa-solid fa-gift"></i>
          {this.state.gift_option.price_cents > 0
            ? " " +
              this.state.gift_option.title +
              " - $" +
              this.state.gift_option.price_cents / 100
            : " Add gift card"}
        </a>

        <Modal
          open={this.state.modalIsOpen}
          onClose={this.closeModal}
          classNames={{ overlay: "custom-overlay", modal: "custom-modal" }}
        >
          <div className="modal-scroll">
            <div className="text-center mb-5">
              <h3>Make an unforgettable gift</h3>
              <p className="text-muted mb-1">
                Select an option below to add a personal touch for someone
                special.
              </p>
              <p className="text-muted small">
                (No packing slips/invoices will be included in the gift)
              </p>
            </div>
            <div className="categories-radio-buttons block-radio my-4">
              {this.state.gift_options.map((radio, i) => (
                <div
                  className="categories-radio-button radio-text"
                  key={radio.id}
                >
                  <input
                    type="radio"
                    id={"gift_option_" + i}
                    key={radio.id}
                    value={radio.id}
                    name={"order_item[gift_option_id]"}
                    checked={this.state.gift_option.id == radio.id}
                    onChange={this.saveState.bind(null, radio)}
                    disabled={
                      this.props.outside_mm > 2000 && radio.price_cents == 1000
                    }
                  />

                  <label
                    className={radio.price_cents}
                    htmlFor={"gift_option_" + i}
                  >
                    <div className="row justify-content-between">
                      {radio.image && (
                        <div className="col-sm-10">
                          <img src={radio.image} className="mr-2 img-fluid" />
                        </div>
                      )}
                      <div className="col-sm-12">
                        <div className="mt-2 mb-1">{radio.title}</div>

                        {radio.require_message &&
                        this.state.gift_option.id == radio.id ? (
                          <div>
                            <textarea
                              autoFocus
                              maxLength={140}
                              className="mt-3 form-control"
                              rows="3"
                              value={this.state.gift_option_note}
                              placeholder={"Enter your message here..."}
                              onChange={this.changeHandler}
                            />
                            <div className="mt-2 small">
                              {this.state.remaining_char} remaining
                            </div>
                          </div>
                        ) : (
                          <small>{radio.description}</small>
                        )}
                        {this.props.outside_mm > 1500 &&
                          radio.price_cents == 1000 && (
                            <div className="font-weight-bold small mt-3">
                              Not available above A3 size
                            </div>
                          )}
                      </div>
                      <div className="col-sm-2">
                        <div className="float-right price">
                          ${radio.price_cents / 100}
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              ))}
            </div>

            <button
              className="btn-block btn btn-primary btn-lg mt-2 d-block"
              onClick={this.handleSubmit}
            >
              Save
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default OrderItemGiftOptions;
