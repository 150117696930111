import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-responsive-modal";
import { isMobile } from "react-device-detect";

class AfterpayBreakdown extends React.Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.buildPrice = this.buildPrice.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  buildPrice() {
    return this.props.total
      ? window.App.currencySymbol + (this.props.total / 4).toFixed(2)
      : "...";
  }

  render() {
    return (
      <div>
        <div className="text-xs text-muted mt-1">
          Or 4 payments of {this.buildPrice()} with
          <img src={this.props.afterpayUI.logo} className="inline w-14 ml-1" />
          <button
            type="button"
            onClick={this.openModal}
            className="text-muted text-xs ml-2"
          >
            Info
          </button>
        </div>
        <Modal
          open={this.state.modalIsOpen}
          onClose={this.closeModal}
          classNames={{ overlay: "custom-overlay", modal: "custom-modal" }}
        >
          <a href="https://www.afterpay.com/terms" target="_blank">
            <img
              src={
                isMobile
                  ? this.props.afterpayUI.modalMob
                  : this.props.afterpayUI.modalDesk
              }
              className="img-fluid"
            />
          </a>
        </Modal>
      </div>
    );
  }
}

export default AfterpayBreakdown;
