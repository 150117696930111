import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import Select from "react-select";
import ShippingEstimate from "components/cart/ShippingEstimate";
import ViewInSpace from "components/frame-designer/designers/ViewInSpace";
import AfterpayBreakdown from "components/afterpay/AfterpayBreakdown";
import StyleColourSelect from "components/gallery-walls/StyleColourSelect";
import SizeSelect from "components/gallery-walls/SizeSelect";
import { RadioGroup } from "@headlessui/react";

class GalleryWallTemplateShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageLoaded: false,
      title: props.title,
      description: props.description,
      styles: props.gallery_wall_styles,
      style: props.gallery_wall_styles[0],
      sizes: props.style_sizes,
      size: props.style_sizes[0],
      preview_mode_index: 0,
      preview_modes: [],
      preview_mode: null,
      framesOpen: false,
      thumbItems: [],
    };
    this.switchPreviewMode = this.switchPreviewMode.bind(this);
    this.handleImageLoaded = this.handleImageLoaded.bind(this);
    this.switchStyle = this.switchStyle.bind(this);
    this.setSize = this.setSize.bind(this);
    this.visitBuilder = this.visitBuilder.bind(this);
    this.toggleFrames = this.toggleFrames.bind(this);
  }

  componentDidMount(params) {
    this.setState({ size: this.state.style.size }, () =>
      this.setSize(this.state.size)
    );

    const script = document.createElement("script");
    script.src = "//widget.reviews.io/modern-widgets/nuggets.js";
    script.async = true;
    document.body.appendChild(script);
  }

  switchPreviewMode(e) {
    this.setState({ activeThumb: e });
  }

  handleImageLoaded() {
    this.setState({ imageLoaded: true });
  }

  switchStyle(style) {
    let addOn = {
      id: 0,
      thumbnail_url:
        "https://res.cloudinary.com/framefox/image/upload/c_scale,w_100/v1598578390/yf9tqbmcvcjdkyjjzr71.jpg",
      image:
        "https://res.cloudinary.com/framefox/image/upload/v1598578390/yf9tqbmcvcjdkyjjzr71.jpg",
    };
    let thumbItems = [
      {
        id: style.id,
        thumbnail_url: style.thumbnail_url,
        image: style.image,
      },
      addOn,
    ];

    this.setState({
      style: style,
      thumbItems: thumbItems,
      activeThumb: thumbItems[0],
    });
  }

  setSize(size) {
    let filteredStyles = this.props.gallery_wall_styles.filter(
      (style) => style.size == size
    );
    let newStyle =
      this.state.style.size == size ? this.state.style : filteredStyles[0];

    this.setState({ size: size, styles: filteredStyles }, () =>
      this.switchStyle(newStyle)
    );
  }

  visitBuilder(e) {
    e.preventDefault();
    window.location.href =
      this.props.build_path + "?gws=" + this.state.style.id;
  }

  toggleFrames(e) {
    e.preventDefault();
    var active = !this.state.framesOpen;
    this.setState({ framesOpen: active });
  }

  render() {
    return (
      <div>
        <div className="md:grid md:grid-cols-12 md:gap-x-8">
          <div className="col-span-6">
            <div className="relative">
              {this.state.activeThumb && (
                <img
                  className="img-fluid"
                  onLoad={this.handleImageLoaded}
                  src={this.state.activeThumb.image}
                />
              )}
              {!this.state.imageLoaded && (
                <div className="p-2 bg-white rounded absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 shadow">
                  <img src={this.props.preloader} className="w-8" />
                </div>
              )}
            </div>
          </div>

          <div className="col-span-1 md:order-first my-5 md:my-0">
            <ThumbSelect
              thumbs={this.state.thumbItems}
              thumb={this.state.activeThumb}
              onChange={this.switchPreviewMode}
            />
          </div>

          <div className="col-span-5">
            <div className="mx-3">
              <div className="text-center mb-2">
                <h1 className="frame-heading mb-1">{this.state.title}</h1>
                <p className="mb-4 mt-2 text-muted">
                  {this.props.num_frames} frames · {this.state.style.final_size}{" "}
                  display size
                </p>

                <hr />
              </div>

              {this.state.sizes.length > 1 ? (
                <div className="mt-5">
                  <div className="mini-heading text-dark mb-2">
                    Display Size
                  </div>
                  <SizeSelect
                    style={this.state.style}
                    styles={this.props.gallery_wall_styles}
                    size={this.state.size}
                    sizes={this.state.sizes}
                    setSize={this.setSize}
                  />
                </div>
              ) : (
                <div className="mt-5 flex">
                  <div className="grow">
                    <span className="mini-heading text-dark">Display Size</span>
                  </div>
                  <div className="text-right">
                    <span className="">{this.state.style.final_size}</span>
                  </div>
                </div>
              )}
              <div className="text-right pt-1 hidden md:block">
                <ViewInSpace
                  width_cm={this.state.style.width_cm}
                  height_cm={this.state.style.height_cm}
                />
              </div>

              {this.state.styles.length > 1 && (
                <div className="mt-4">
                  <div className="mini-heading text-dark mb-2">Style</div>
                  <StyleColourSelect
                    style={this.state.style}
                    styles={this.state.styles}
                    setStyle={this.switchStyle}
                  />
                </div>
              )}
              <div className="text-right mt-2">
                <AfterpayBreakdown
                  total={this.props.price_from}
                  afterpayUI={this.props.afterpayUI}
                />
              </div>

              <a
                className="mt-6 btn btn-primary block btn-lg mb-3 text-center"
                href="#"
                onClick={this.visitBuilder}
              >
                <div className="text-xs text-brand-300  mb-0.5">Next Step:</div>
                Upload Photos{" "}
                <i className="fa-solid fa-caret-right fa-sm ml-1"></i>
              </a>

              <ShippingEstimate
                shipping_icon={this.props.shipping_icon}
                shipping_estimates={this.props.shipping_estimates}
                print_and_frame={true}
                country={this.props.country_name}
                location="gallery-wall"
              />

              <hr className="my-4" />

              <p>{this.state.description}</p>
              <p className="mt-3">
                Upload your images and add them into each frame. We'll print
                them, custom frame them and ship them to your door with a
                lifesized hanging guide to make hanging your gallery wall a
                piece of cake.
              </p>

              {this.state.style.templates && (
                <div>
                  <a
                    className="my-4 block btn btn-lg btn-pale text-center"
                    href="#"
                    onClick={this.toggleFrames}
                  >
                    <i
                      className={classNames(
                        "fa-solid  text-sm mr-1",
                        this.state.framesOpen
                          ? "fa-circle-minus"
                          : "fa-circle-plus"
                      )}
                    />
                    {this.state.framesOpen ? "Hide" : "Show"} Frame Details
                  </a>
                  {this.state.framesOpen && (
                    <div>
                      <div className="flex mt-4">
                        <div className="mr-2">
                          <img
                            src="https://res.cloudinary.com/framefox/image/upload/c_scale,w_100/v1598578390/yf9tqbmcvcjdkyjjzr71.jpg"
                            className="img-fluid img-50"
                          />
                        </div>
                        <div className="text-sm">
                          <strong>Hanging template & nails</strong>
                          <p className=" text-muted">
                            + Easy to follow instructions
                          </p>
                        </div>
                      </div>
                      {this.state.style.templates.map(
                        function (template, i) {
                          return (
                            <div className="flex mt-4" key={template.id}>
                              <div className="mr-2">
                                <img
                                  src={template.thumb}
                                  className="img-fluid img-50"
                                />
                              </div>
                              <div className="text-sm">
                                <strong>
                                  {template.style} frame (x{template.count})
                                </strong>
                                <p className=" text-muted">
                                  {template.size_description} print ·{" "}
                                  {template.total_dimensions_cm} frame
                                </p>
                              </div>
                            </div>
                          );
                        }.bind(this)
                      )}
                    </div>
                  )}
                </div>
              )}

              <div
                className="reviews-io-nuggets-widget mt-10"
                widget-id="ITzQhxBJdm7lFH26"
                data-store-name="www.framefox.co"
                lang="en"
                data-sku=""
                tags=""
                branch=""
              ></div>

              <>
                <div className="mt-12 font-bold">
                  Museum-Quality in Every Frame
                </div>
                <div className="border-t border-gray-200 pt-5 mt-5">
                  <dt className="mini-heading text-gray-900">
                    Printing & Paper
                  </dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    Your photos will be printed on gallery-quality 255gsm Luster
                    Paper paper using 12 colour archival pigment inks on the
                    latest Epson printers.
                  </dd>
                </div>
                <div className="border-t border-gray-200 pt-5 mt-5">
                  <dt className="mini-heading text-gray-900">The Frame</dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    Sustainably sourced, solid wood frame handmade in{" "}
                    {this.props.country_name}.
                  </dd>
                </div>
                <div className="border-t border-gray-200 pt-5 mt-5">
                  <dt className="mini-heading text-gray-900">Glazing</dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    {this.props.glass_types_sentence}
                  </dd>
                </div>
                <div className="border-t border-gray-200 pt-5 mt-5">
                  <dt className="mini-heading text-gray-900">Borders</dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    Acid-free bevel-cut {window.App.mat} to make your images
                    pop.
                  </dd>
                </div>
                <div className="border-t border-gray-200 pt-5 mt-5">
                  <dt className="mini-heading text-gray-900">
                    Hanging Hardware
                  </dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    Your frames arrive with hanging hardware already attached,
                    along with a life-sized hanging to make hanging your gallery
                    wall a piece of cake.
                  </dd>
                </div>
              </>
              <a
                className="mt-6 btn btn-primary block btn-lg mb-3 text-center"
                href="#"
                onClick={this.visitBuilder}
              >
                <div className="text-xs text-brand-300  mb-0.5">Next Step:</div>
                Upload Photos{" "}
                <i className="fa-solid fa-caret-right  fa-sm ml-1"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ThumbSelect = ({ thumbs, thumb, onChange }) => (
  <RadioGroup
    value={thumb}
    onChange={onChange}
    className="mt-3 text-center md:text-left"
  >
    {thumbs.map((radio) => (
      <RadioGroup.Option
        key={radio.id}
        value={radio}
        className={({ active, checked }) =>
          classNames(
            active ? "ring-2 ring-brand-600 ring-offset-2" : "",
            checked
              ? "ring-2 ring-brand-600 ring-offset-2"
              : "bg-pale ring-1 ring-inset ring-white bg-white text-gray-900 hover:bg-gray-50",
            "items-center justify-center rounded-md mr-2 mb-2 text-sm font-semibold  inline-block cursor-pointer"
          )
        }
      >
        <RadioGroup.Label as="span">
          <img
            src={radio.thumbnail_url}
            className="rounded-md w-14 h-14 block"
          />
        </RadioGroup.Label>
      </RadioGroup.Option>
    ))}
  </RadioGroup>
);

export default GalleryWallTemplateShow;
