import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import axios from "axios";
import ShippingMethodShow from "components/cart/ShippingMethodShow";
import ShippingEstimate from "components/cart/ShippingEstimate";
import AfterpayBreakdown from "components/afterpay/AfterpayBreakdown";

class AdminPricingBreakdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      discount: props.discount,
      tax: props.tax,
      total: props.total,
      formOpen: !props.discount,
    };
    this.onDiscountUpdate = this.onDiscountUpdate.bind(this);
    this.onShippingUpdate = this.onShippingUpdate.bind(this);
    this.renderDiscount = this.renderDiscount.bind(this);
    this.removeDiscount = this.removeDiscount.bind(this);
    this.completeRemove = this.completeRemove.bind(this);
  }

  componentDidMount() {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
  }

  onDiscountUpdate(data) {
    this.setState({
      discount: data.discount,
      total: data.total,
      tax: data.tax,
      formOpen: false,
    });
  }

  onShippingUpdate(new_total, new_tax) {
    this.setState({
      total: new_total,
      tax: new_tax,
    });
  }

  removeDiscount(e) {
    e.preventDefault();
    axios.get(this.props.url_remove).then((res) => {
      this.completeRemove;
    });
  }

  completeRemove(data) {
    this.onDiscountUpdate(data);
    this.setState({
      discount: null,
      formOpen: true,
    });
  }

  renderDiscount() {
    if (this.state.discount) {
      return (
        <div className="grid grid-cols-2">
          <div className="col">
            {this.state.discount.description}
            <span className="badge badge-default font-weight-bold ml-2">
              {this.state.discount.code}
              <button
                type="button"
                onClick={(e) => this.removeDiscount(e)}
                className="btn btn-xs p-0"
              >
                <i className="fa-solid fa-circle-xmark" />
              </button>
            </span>
          </div>
          <div className="col text-success">
            <div className="h5 text-right font-weight-normal shipping-price">
              - {this.state.discount.reduction}
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="p-5 pt-2 bg-brand-100">
        <DiscountCode
          open={this.state.formOpen}
          onDiscountUpdate={this.onDiscountUpdate}
          url={this.props.url}
          is_pro={this.props.is_pro}
        />

        <hr />
        <div className="grid grid-cols-2  my-3">
          <div className="col">
            Subtotal
            {this.props.items_count > 1 && (
              <small className="text-gray-400 ml-1">
                ({this.props.items_count} items)
              </small>
            )}
          </div>
          <div className="col">
            <div className="h5 text-right font-weight-normal">
              {this.props.items_total}
            </div>
          </div>
        </div>
        {this.renderDiscount()}

        {this.props.studio_framing_addon && (
          <div className="grid grid-cols-3  my-3">
            <div className="col-span-2">
              <p className="mb-2">
                In-Studio Framing{" "}
                <small className="text-gray-400">
                  ($25 for up to 3 frames)
                </small>
              </p>
            </div>
            <div className="col-span-1">
              <div className="h5 text-right font-weight-normal">
                {this.props.studio_framing_addon}
              </div>
            </div>
          </div>
        )}

        {this.props.shipping_method ? (
          <div className="grid grid-cols-3  my-3">
            <div className="col-span-2">
              <div>
                Shipping:{" "}
                {this.props.shipping_overide ? (
                  <span className="text-gray-400">Custom</span>
                ) : (
                  <>
                    <span className="text-gray-400">
                      {this.props.shipping_method.title}
                    </span>{" "}
                    <span className="badge badge-pale badge-sm uppercase">
                      {this.props.shipping_method.size}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="col-span-1">
              <div className="h5 text-right font-weight-normal shipping-price mb-0">
                {this.props.shipping_overide
                  ? this.props.shipping_overide
                  : this.props.shipping_method.price}
              </div>
            </div>
          </div>
        ) : (
          <div className="grid">
            <hr />
            <div className="col my-3">
              <p className="mb-2">
                Shipping{" "}
                <small className="text-gray-400">(finalised at checkout)</small>
              </p>
              <div>
                {/* <ShippingEstimate
                  shipping_icon={this.props.shipping_icon}
                  shipping_estimates={this.props.shipping_estimates}
                  print_and_frame={true}
                  country={this.props.country_name}
                  location="cart"
                /> */}
              </div>
            </div>
          </div>
        )}
        {this.props.credit && (
          <div className="grid grid-cols-3 my-3">
            <div className="col-span-2">Store credit applied</div>
            <div className="col-span-1">
              <div className="h5 text-right font-weight-normal shipping-price">
                -{this.props.credit.amount}
              </div>
            </div>
          </div>
        )}

        <hr />
        <div className="grid grid-cols-3  my-3">
          <div className="col-span-2">
            <h3 className="mb-0">Total</h3>
            <div className="text-gray-400">
              Including <span className="gst">{this.state.tax}</span>{" "}
              {window.App.taxName}
            </div>
          </div>
          <div className="col-span-1 text-right">
            <h1 className="mb-0">
              <span className="mini-currency">{window.App.currencySymbol}</span>
              {this.state.total}
            </h1>
          </div>
        </div>
        <div className="grid mt-1">
          <div className="col">
            {/* <AfterpayBreakdown
                total={this.state.total.replace(",", "")}
                afterpayUI={this.props.afterpayUI}
              /> */}
          </div>
        </div>
      </div>
    );
  }
}

class DiscountCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      message: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.completeResponse = this.completeResponse.bind(this);
    this.renderMessage = this.renderMessage.bind(this);
    this.renderForm = this.renderForm.bind(this);
  }

  onChange(e) {
    this.setState({ text: e.target.value });
  }

  onFocus(e) {
    this.setState({ focused: true });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ message: "" });

    console.log(this.props.url);

    axios
      .patch(this.props.url, { voucher_code: this.state.text })
      .then((res) => {
        this.completeResponse(res.data);
      });
  }

  completeResponse(data) {
    if (data.success) {
      this.setState({
        success: true,
        message: data.message,
      });
      segment_track("Coupon Applied", data.tracking_detail);
      this.props.onDiscountUpdate(data);
    } else {
      this.setState({
        success: false,
        message: data.message,
      });
    }
  }

  renderMessage() {
    var color = this.state.success ? "text-green-600" : "text-red-600";
    var classes = classNames(
      "mb-3",
      "animate-wiggle",
      "rounded",
      "bg-white",
      "p-3",
      "shadow",
      color
    );

    if (this.state.message) {
      return <div className={classes}>{this.state.message}</div>;
    }
  }

  renderForm() {
    var focused = this.state.focused
      ? "form-group mb-0 focused"
      : "form-group mb-0";

    return (
      <form
        className="simple_form floating-fields mt-2"
        onSubmit={this.handleSubmit}
      >
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-6 text-stone-900"
          >
            Discount code
          </label>
          <div className="mt-2 flex rounded-md shadow-sm">
            <div className="relative flex flex-grow items-stretch focus-within:z-10">
              <input
                className="form-control"
                onFocus={this.onFocus}
                onChange={this.onChange}
                value={this.state.text}
              />
            </div>
            <a
              className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2.5 text-sm font-semibold bg-brand-500 text-white hover:bg-brand-400"
              href="#"
              onClick={this.handleSubmit}
            >
              Apply
            </a>
          </div>
        </div>

        <div className="grid">
          <div className="col text-gray-400 my-2">
            <small>
              Gift card code? <a href="/redeem/new">Redeem here</a>
            </small>
          </div>
        </div>
      </form>
    );
  }

  render() {
    return (
      <div className="">
        <div>{this.props.open && this.renderForm()}</div>

        {this.renderMessage()}
      </div>
    );
  }
}

export default AdminPricingBreakdown;
