import React from "react";
import classNames from "classnames";
import ReactDOM from "react-dom";
import { RadioGroup } from "@headlessui/react";

const FrameColourSelector = (props) => (
  <RadioGroup
    value={props.defaultChecked}
    onChange={(e) => props.saveValues({ frame_colour: e })}
    className="mt-2"
  >
    <div className="">
      {props.options.map((option, i) => (
        <RadioGroup.Option
          key={option.id}
          value={option}
          className={({ active, checked }) =>
            classNames(
              active ? "ring-2 ring-brand-600 ring-offset-2" : "",
              checked
                ? "bg-brand-600 text-white hover:bg-brand-500"
                : "bg-pale ring-1 ring-inset ring-white bg-brand-100 text-gray-900 hover:bg-gray-50",
              "items-center justify-center rounded-md mr-2 mb-2 py-2.5 px-2.5 text-sm font-semibold  inline-block cursor-pointer"
            )
          }
        >
          <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
        </RadioGroup.Option>
      ))}
    </div>
  </RadioGroup>
);

export default FrameColourSelector;
