import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import Cropper from 'react-cropper';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Slider, { Range } from 'rc-slider';
import Toggle from 'react-toggle';

class AdminCrop extends React.Component{ 
  constructor(props){
    super(props);
    this.state = {
      source_ratio:              props.source_ratio,
      preview_url:               props.preview_url,
      image:                     props.image,
      crop_image_path:           props.crop_image_path,
      image_path:                props.image_path,
      loading:                   false,
      zoom:                      0,
      initialZoom:               1,
      options:                   [
        {title: "Original", ratio: props.source_ratio},
        {title: "Square", ratio: 1},
        {title: "3:2", ratio: 3/2},
        {title: "2:3", ratio: 2/3},
        {title: "4:3", ratio: 4/3},
        {title: "3:4", ratio: 3/4},
        {title: "5:4", ratio: 5/4},
        {title: "4:5", ratio: 4/5},
        {title: "A4 Land", ratio: 1.414 },
        {title: "A4 Port", ratio: 1/1.414 },
        {title: "16:9", ratio: 16/9},
     ]
    }
    this.validateAndSend   = this.validateAndSend.bind(this);
    this.completeResponse  = this.completeResponse.bind(this);
    this.changeZoom        = this.changeZoom.bind(this);
    this.cropperMounted    = this.cropperMounted.bind(this);
    this.cropUpdated       = this.cropUpdated.bind(this);
    this.onSelect          = this.onSelect.bind(this);
    this.onCustomChange    = this.onCustomChange.bind(this);
  }

  componentDidMount(){
    this.setState({
      cropOption: this.state.options[0],
      ratio: this.props.source_ratio
    })
  }

  validateAndSend(e){
    e.preventDefault();
    $.ajax({
      method: 'PATCH',
      url: this.state.crop_image_path,
      data: {
        image: {
          id: this.state.image.id,
          cx: this.state.crop.x,
          cy: this.state.crop.y,
          cw: this.state.crop.width,
          ch: this.state.crop.height,
        },
        preview_width: this.state.preview_width
      }
    }).done(this.completeResponse);
  }

  completeResponse(){
    window.location.href = this.state.image_path
  }

  changeZoom(val){
    this.setState({zoom: val})
    this.state.cropper.zoomTo(parseFloat(val))
  }

  cropperMounted(){
    const canvasData = this.state.cropper.getCanvasData()
    const zoomRatio = canvasData.width / canvasData.naturalWidth

    this.setState({
      initialZoom: zoomRatio,
      zoom: zoomRatio,
      preview_width: this.state.cropper.getImageData().naturalWidth
    })
  }

  cropUpdated(){
    let data = this.state.cropper.getData()
    this.setState({crop: data})
  }

  onSelect(e){
    let r = this.state.options[e.target.value].ratio

    this.setState({
      cropOption: this.state.options[e.target.value],
      ratio: r
    })
    this.state.cropper.setAspectRatio(r)
  }

  onCustomChange(e){
    this.setState({
      cropOption: null,
      ratio: e.target.value
    })
  }

  render() {

    let classes = classNames('btn', 'btn-primary', 'btn-lg', 'my-1');

    return (
      <div className='row'>
        <div className='col-sm-12'>
          <Cropper
                  ref='cropper'
                  src={this.state.preview_url}
                  style={{height: 500, width: '100%'}}
                  // Cropper.js options
                  zoomTo={parseFloat(this.state.zoom)}
                  dragMode="move"
                  aspectRatio={this.state.ratio}
                  viewMode={1}
                  autoCrop={true}
                  autoCropArea={1}
                  cropBoxMovable={false}
                  cropBoxResizable={false}
                  zoomOnWheel={false}
                  movable={true}
                  guides={true}
                  onInitialized={ (instance) => this.setState({ cropper: instance}) }
                  ready={this.cropperMounted}
                  crop={this.cropUpdated} />

          <div className="m-4 text-center">
            <Slider min={this.state.initialZoom} max={2} step={0.025} value={this.state.zoom} onChange={this.changeZoom} />
          </div>
        </div>
        <div className='col-sm-12'>
          <h3>Crop this image <small className="text-muted">(optional)</small></h3>
          <div className="my-4">
            {this.state.options.map(function(option, i){
              return (
                <div className='categories-radio-button radio-text print-size' key={i} >
                  <input type="radio"
                         id={'preset_option_size'+i}
                         key={i}
                         name={'preset'}
                         value={i}
                         checked={this.state.cropOption == option}
                         onChange={this.onSelect} />
                  <label htmlFor={'preset_option_size'+i}>
                    <h6 className="mb-0 font-weight-bold">
                      {option.title}
                    </h6>
                  </label>
                </div>
              )
            }.bind(this))}
            <div className='form-group'>
              <label>Ratio</label>
              <input  className='form-control' onChange={this.onCustomChange} value={this.state.ratio} />
            </div>
          </div>

          <a href='#' onClick={this.validateAndSend} className={classes}>Done</a>
        </div>
      </div>
    )
  }
}


export default AdminCrop;