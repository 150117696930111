import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import Modal from "react-responsive-modal";

class VideoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: this.props.image,
      modalIsOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    return (
      <div className="">
        <a href="#" className="video-demo" onClick={this.openModal}>
          <div className="media">
            <div className="mr-3">
              <div className="video-thumbnail">
                <img src={this.state.image} />
              </div>
            </div>
            <div className="media-body">
              <div className="video-cta">
                <i className="fa-solid fa-circle-play"></i> Watch the video
              </div>
            </div>
          </div>
        </a>
        <Modal
          open={this.state.modalIsOpen}
          onOpen={this.afterOpenModal}
          onClose={this.closeModal}
          classNames={{
            overlay: "custom-overlay",
            modal: "custom-modal video-modal",
          }}
        >
          <div
            style={{
              position: "relative",
              paddingBottom: "56.25%",
              paddingTop: 25,
              height: 0,
            }}
          >
            <iframe
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              src="https://player.vimeo.com/video/386793253?autoplay=1&loop=1&title=0&byline=0&portrait=0"
              frameBorder="0"
              allow="autoplay"
            />
          </div>
        </Modal>
      </div>
    );
  }
}

export default VideoModal;
