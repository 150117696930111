import React from "react";
import ReactDOM from "react-dom";
import { fabric } from "fabric";
import { useRef, useEffect } from "react";
import classNames from "classnames";

function render(
  canvas,
  {
    mat_style,
    width_mm,
    height_mm,
    frame_style,
    windows,
    imageLoaded,
    images,
    maxWidth,
  }
) {
  console.log("rendering canvas");
  let frameWidth = 20;
  let canvasWidth =
    parseInt(width_mm) + frameWidth * 2 + mat_style.left + mat_style.right;
  let canvasHeight =
    parseInt(height_mm) + frameWidth * 2 + mat_style.top + mat_style.bottom;

  const longestEdge = canvasWidth > canvasHeight ? canvasWidth : canvasHeight;
  const ratioOuter = canvasWidth / canvasHeight;
  const ratio = width_mm / height_mm;

  // const maxWidth = maxWidth;
  // const maxHeight = 600;

  const maxW = ratioOuter > 1 ? maxWidth : maxWidth * ratioOuter;
  const maxH = ratioOuter > 1 ? maxWidth / ratioOuter : maxWidth;

  // const roomScale = this.state.scale ? (longestEdge / 1000) * this.state.scale : 1;
  const roomScale = 1;

  if (ratioOuter >= 1) {
    var scaleF = (roomScale * parseInt(maxW * 2)) / canvasWidth;
  } else {
    var scaleF = (roomScale * parseInt(maxH * 2)) / canvasHeight;
  }

  canvasWidth = canvasWidth * scaleF;
  canvasHeight = canvasHeight * scaleF;
  frameWidth = Math.ceil(frameWidth * scaleF);

  let mountAndFrameLeft = mat_style.left * scaleF + frameWidth - 1;
  let mountAndFrameRight = mat_style.right * scaleF + frameWidth - 1;
  let mountAndFrameTop = mat_style.top * scaleF + frameWidth - 1;
  let mountAndFrameBottom = mat_style.bottom * scaleF + frameWidth - 1;

  canvas.enableRetinaScaling = false;

  const base = new fabric.Rect({
    left: 0,
    top: 0,
    width: canvasWidth,
    height: canvasHeight,
    fill: "#fff",
    selectable: false,
  });
  canvas.add(base);

  canvas.setDimensions(
    {
      width: canvasWidth,
      height: canvasHeight,
    },
    { backstoreOnly: true }
  );

  canvas.setDimensions(
    {
      width: canvasWidth / 2 + "px",
      height: canvasHeight / 2 + "px",
    },
    { cssOnly: true }
  );

  const topFramePoints = [
    { x: -1, y: -1 },
    { x: canvasWidth + 1, y: -1 },
    { x: canvasWidth + 1 - frameWidth, y: frameWidth },
    { x: frameWidth, y: frameWidth },
  ];

  const sideFramePoints = [
    { x: -1, y: -1 },
    { x: canvasHeight + 1, y: -1 },
    { x: canvasHeight + 1 - frameWidth, y: frameWidth },
    { x: frameWidth, y: frameWidth },
  ];

  const rShadowStyle = {
    color: "rgba(0,0,0,0.3)",
    blur: 6 * scaleF,
    offsetX: -2,
    offsetY: 0,
  };
  const lShadowStyle = {
    color: "rgba(0,0,0,0.3)",
    blur: 8 * scaleF,
    offsetX: 0,
    offsetY: 0,
  };
  const tShadowStyle = {
    color: "rgba(0,0,0,0.4)",
    blur: 6 * scaleF,
    offsetX: 0,
    offsetY: 2,
  };
  const bShadowStyle = {
    color: "rgba(0,0,0,0.3)",
    blur: 8 * scaleF,
    offsetX: 0,
    offsetY: 0,
  };

  const top = new fabric.Polygon(topFramePoints, {
    fill: "white",
    selectable: false,
  });

  const right = new fabric.Polygon(sideFramePoints, {
    left: canvasWidth + 1,
    fill: "white",
    angle: 90,
    selectable: false,
  });

  const bottom = fabric.util.object.clone(top);
  bottom.set("angle", 180);
  bottom.set("top", canvasHeight + 1);
  bottom.set("left", canvasWidth + 1);

  const left = fabric.util.object.clone(right);
  left.set("angle", 270);
  left.set("left", -1);
  left.set("top", canvasHeight + 1);

  const tShadow = fabric.util.object.clone(top);
  const bShadow = fabric.util.object.clone(bottom);
  const lShadow = fabric.util.object.clone(left);
  const rShadow = fabric.util.object.clone(right);

  tShadow.set("top", -2);
  lShadow.set("left", -2);
  rShadow.set("left", canvasWidth + 2);
  bShadow.set("top", canvasHeight + 2);

  canvas.add(tShadow);
  canvas.add(bShadow);
  canvas.add(lShadow);
  canvas.add(rShadow);

  tShadow.setShadow(tShadowStyle);
  bShadow.setShadow(bShadowStyle);
  lShadow.setShadow(lShadowStyle);
  rShadow.setShadow(rShadowStyle);

  tShadow.set({ fill: "#fff" });
  bShadow.set({ fill: "#fff" });
  lShadow.set({ fill: "#fff" });
  rShadow.set({ fill: "#fff" });

  const patternURL = frame_style.pattern_url;
  const fw = frameWidth;

  fabric.Image.fromURL(
    patternURL,
    function (img) {
      const borderScale = window.devicePixelRatio > 1.5 ? 2 : 1;
      img.scaleToHeight(fw / borderScale);

      const patternSourceCanvas = new fabric.StaticCanvas();
      patternSourceCanvas.add(img);
      patternSourceCanvas.renderAll();
      const pattern = new fabric.Pattern({
        source: function () {
          patternSourceCanvas.setDimensions({
            width: img.getScaledWidth(),
            height: img.getScaledHeight(),
          });
          patternSourceCanvas.renderAll();
          return patternSourceCanvas.getElement();
        },
        repeat: "repeat-x",
      });

      top.set({ fill: pattern });
      bottom.set({ fill: pattern });
      left.set({ fill: pattern });
      right.set({ fill: pattern });

      canvas.add(bottom);
      canvas.add(left);
      canvas.add(right);
      canvas.add(top);

      windows.map((win, index) => {
        let imageURL = images[win.position - 1]
          ? images[win.position - 1].url
          : win.placeholder;

        fabric.Image.fromURL(
          imageURL,
          function (userImage) {
            if (images[win.position - 1]) {
              imageLoaded(images[win.position - 1].id);
            }
            let artworkWidth = win.width_mm * scaleF;
            let artworkHeight = win.height_mm * scaleF;

            let artworkX = win.px * scaleF;
            let artworkY = win.py * scaleF;

            const imageRatio = userImage.width / userImage.height;

            if (imageRatio <= ratio) {
              userImage.scaleToWidth(artworkWidth);
            } else {
              userImage.scaleToHeight(artworkHeight);
            }

            userImage.selectable = false;

            userImage.set("originX", "center");
            userImage.set("originY", "center");
            userImage.set("strokeWidth", 10);
            userImage.set("stroke", "#ddd");

            userImage.set("top", canvasHeight / 2 + artworkY);
            userImage.set("left", canvasWidth / 2 + artworkX);

            canvas.add(userImage);
          }.bind(this),
          {
            crossOrigin: "Anonymous",
          }
        );
      });
    }.bind(this),
    {
      crossOrigin: "Anonymous",
    }
  );
}

const MultiPhotoCanvas = (props) => {
  const canvasEl = useRef(null);

  useEffect(() => {
    const canvas = new fabric.StaticCanvas(canvasEl.current);

    render(canvas, { ...props });
  });

  const displayClasses =
    props.width_mm > props.height_mm ? "!w-full !h-auto" : "!w-auto !h-full";

  return (
    <canvas
      id="canvasFrame"
      className={classNames("big-frame-shadow", displayClasses)}
      ref={canvasEl}
    />
  );
};

export default MultiPhotoCanvas;
