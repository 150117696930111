import React from "react";
import { Fragment, useState } from "react";
import { Dialog, Popover, Tab, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
  ShoppingBagIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Nav(props) {
  const [open, setOpen] = useState(false);
  const [isShowingHover, setIsShowingHover] = useState(false);

  return (
    <div className="bg-white main-nav">
      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40  lg:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed  inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition  ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="flex px-4 pb-2 pt-5">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-stone-400 focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Links */}
                <Tab.Group as="div" className="mt-2">
                  <div className="border-b border-stone-200">
                    <Tab.List className="-mb-px flex space-x-8 px-4">
                      {props.navigation.categories.map((category) => (
                        <Tab
                          key={category.name}
                          className={({ selected }) =>
                            classNames(
                              selected
                                ? "border-brand-600 text-brand-600"
                                : "border-transparent text-stone-900",
                              "flex-1 whitespace-nowrap border-b-2 px-1 py-4 text-base font-bold"
                            )
                          }
                        >
                          {category.name}
                        </Tab>
                      ))}
                    </Tab.List>
                  </div>
                  <Tab.Panels as={Fragment}>
                    {props.navigation.categories.map((category) => (
                      <Tab.Panel
                        key={category.name}
                        className="space-y-12 px-4 py-6"
                      >
                        <div>
                          {category.featured
                            .concat(category.mini_featured)
                            .map((item) => (
                              <div
                                key={item.name}
                                className={classNames(
                                  item.mobileGrid,
                                  "relative grid-cols-5 gap-x-4 mb-3"
                                )}
                              >
                                <div
                                  className={classNames(
                                    item.mobileAspectImage,
                                    "col-span-2 overflow-hidden rounded-md group-hover:opacity-75"
                                  )}
                                >
                                  <img
                                    src={item.imageSrc}
                                    alt={item.imageAlt}
                                    className="object-cover object-center"
                                  />
                                </div>
                                <a
                                  href={item.href}
                                  className="col-span-3 mt-3 block text-sm font-bold text-stone-900"
                                >
                                  <span
                                    className="absolute inset-0 z-10"
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                  <br />
                                  <span className="text-muted font-normal">
                                    {item.description}
                                  </span>
                                </a>
                              </div>
                            ))}
                        </div>
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>

                <div className="space-y-6 border-t border-stone-200 px-4 py-6">
                  {props.navigation.account_urls.map((page) => (
                    <div key={page.name} className="flow-root">
                      <a
                        href={page.href}
                        className="-m-2 block p-2 font-bold text-stone-900"
                      >
                        {page.name}
                      </a>
                    </div>
                  ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <header className="relative z-30">
        <nav aria-label="Top">
          {/* Secondary navigation */}
          <div className="bg-white">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="">
                <div className="flex h-16 items-center justify-between">
                  <div className="hidden h-full lg:flex flex-1">
                    {/* Flyout menus */}
                    <Popover.Group className="inset-x-0 bottom-0 px-4">
                      <div className="flex h-full justify-center space-x-8">
                        {props.navigation.categories.map((category) => (
                          <Popover key={category.name} className="flex">
                            {({ open }) => (
                              <>
                                <div className="relative flex">
                                  <Popover.Button
                                    onMouseEnter={() =>
                                      setIsShowingHover(category.name)
                                    }
                                    onMouseLeave={() =>
                                      setIsShowingHover(false)
                                    }
                                    className={classNames(
                                      isShowingHover == category.name
                                        ? "border-brand-600 text-brand-600"
                                        : "border-transparent text-stone-700 hover:text-stone-800",
                                      "relative z-10 -mb-px flex items-center border-b-2 pt-px text-sm font-bold transition-colors duration-200 ease-out focus:outline-none"
                                    )}
                                  >
                                    {category.name}
                                  </Popover.Button>
                                </div>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-200"
                                  enterFrom="opacity-0"
                                  enterTo="opacity-100"
                                  leave="transition ease-in duration-150"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                  show={isShowingHover == category.name}
                                  onMouseEnter={() =>
                                    setIsShowingHover(category.name)
                                  }
                                  onMouseLeave={() => setIsShowingHover(false)}
                                >
                                  <Popover.Panel className="absolute inset-x-0 top-full text-sm text-stone-500">
                                    {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                                    <div
                                      className="absolute inset-0 top-1/2 bg-white shadow"
                                      aria-hidden="true"
                                    />

                                    <div className="relative bg-white">
                                      <div className="mx-auto max-w-7xl px-8">
                                        <div className="grid grid-cols-5 gap-x-8 py-8">
                                          {category.featured.map((item) => (
                                            <div
                                              key={item.name}
                                              className={classNames(
                                                item.colSpanClass,
                                                item.textClass,
                                                "group relative"
                                              )}
                                            >
                                              <div className="  group-hover:opacity-75">
                                                <img
                                                  src={item.imageSrc}
                                                  alt={item.imageAlt}
                                                  className="object-cover object-center"
                                                />
                                              </div>
                                              <a
                                                href={item.href}
                                                className="mt-4 block text-lg font-bold text-stone-900"
                                              >
                                                <span
                                                  className="absolute inset-0 z-10"
                                                  aria-hidden="true"
                                                />
                                                {item.name}
                                              </a>
                                              <p
                                                aria-hidden="true"
                                                className="mt-1 text-stone-400"
                                              >
                                                {item.description}
                                              </p>
                                            </div>
                                          ))}
                                          <div className="col-span-2">
                                            {category.mini_featured.map((i) => (
                                              <div
                                                className="relative grid grid-cols-5 gap-x-4 group"
                                                key={i.name}
                                              >
                                                <div className="col-span-2 overflow-hidden rounded-md  group-hover:opacity-75 pl-10">
                                                  <img
                                                    src={i.imageSrc}
                                                    className="w-18"
                                                  />
                                                </div>
                                                <a
                                                  href={i.href}
                                                  className="col-span-3 mt-3 block text-sm text-stone-900 text-left"
                                                >
                                                  <span
                                                    className="absolute inset-0 z-10"
                                                    aria-hidden="true"
                                                  />
                                                  <span className="font-bold text-lg">
                                                    {i.name}
                                                  </span>
                                                  <div className="text-muted help-text">
                                                    {i.description}
                                                  </div>
                                                </a>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Popover.Panel>
                                </Transition>
                              </>
                            )}
                          </Popover>
                        ))}

                        {props.navigation.pages.map((page) => (
                          <a
                            key={page.name}
                            href={page.href}
                            className="flex items-center text-sm font-bold text-stone-700 hover:text-stone-800"
                          >
                            {page.name}
                          </a>
                        ))}
                      </div>
                    </Popover.Group>
                  </div>

                  {/* Mobile menu and search (lg-) */}
                  <div className="flex flex-1 items-center lg:hidden">
                    <button
                      type="button"
                      className="-ml-2 rounded-md bg-white p-2 text-stone-400 focus:outline-none"
                      onClick={() => setOpen(true)}
                    >
                      <span className="sr-only">Open menu</span>
                      <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Logo (lg-) */}
                  <a href={props.navigation.logo.href} className="flex">
                    <span className="sr-only">Framefox</span>
                    <img
                      src={props.navigation.logo.imageSrc}
                      alt=""
                      className="h-6 w-auto"
                    />
                  </a>

                  <div className="flex flex-1 items-center justify-end">
                    <div className="flex items-center lg:ml-8">
                      <a
                        href="/support"
                        className="hidden text-sm font-bold text-stone-700 hover:text-stone-800 lg:block"
                      >
                        Support
                      </a>

                      {/* Cart */}
                      <div className="ml-4 flow-root lg:ml-8">
                        <a
                          href={props.navigation.cart.href}
                          className="group -m-2 flex items-center p-2 text-brand-600"
                        >
                          <i className="fa-solid fa-bag-shopping text-xl flex-shrink-0  group-hover:text-stone-500"></i>
                          <span className="ml-2 text-sm font-bold text-stone-700 group-hover:text-stone-800">
                            {props.navigation.cart.items}
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}
