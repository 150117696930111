import React from "react";
import ReactDOM from "react-dom";
import { CirclePicker } from "react-color";
import { closestColor, defaultColourOptions } from "components/art/constants";

class ColourPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pickerShowing: false,
    };
    this.handleChangeComplete = this.handleChangeComplete.bind(this);
    this.togglePicker = this.togglePicker.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  handleChangeComplete(color, event) {
    const colObj = defaultColourOptions.find(
      (item) => item.color == color.hex.toUpperCase()
    );
    this.props.onColourChange(colObj);
  }

  togglePicker(e) {
    e.preventDefault();
    if (!this.state.pickerShowing) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    this.setState({
      pickerShowing: !this.state.pickerShowing,
    });
  }

  handleOutsideClick(e) {
    if (this.node.contains(e.target)) {
      return;
    }
    this.togglePicker(e);
  }

  render() {
    return (
      <div className="mt-2">
        <CirclePicker
          width={400}
          circleSize={20}
          circleSpacing={6}
          color={this.props.colour.color}
          colors={defaultColourOptions.map((p) => p.color)}
          onClose={this.togglePicker}
          onChangeComplete={this.handleChangeComplete}
        />
      </div>
    );
  }
}

export default ColourPicker;
