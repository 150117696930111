import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { Tooltip } from "react-tippy";
import EffectAndFilters from "components/frame-designer/effects/EffectAndFilters";

class EffectAndFiltersContainer extends React.Component {
  constructor(props) {
    super();
    this.cropEditComplete = this.cropEditComplete.bind(this);
  }

  cropEditComplete(data) {
    window.location.href = this.props.next_path;
  }

  render() {
    return (
      <div>
        <EffectAndFilters
          saveComplete={this.cropEditComplete}
          button_text="Next: Add frame"
          image={this.props.image}
          preloader={this.props.preloader}
          crop_image_path={this.props.crop_image_path}
          effects={this.props.image.effects}
        />
      </div>
    );
  }
}

export default EffectAndFiltersContainer;
