import React from "react";
import ReactDOM from "react-dom";

class MissiveSidebarApp extends React.Component {
  constructor(props) {
    super();
    this.state = {
      loading: true,
      users: [],
    };

    this.reloadUI = this.reloadUI.bind(this);
    this.fetchUsers = this.fetchUsers.bind(this);
  }

  componentDidMount() {
    Missive.on("change:conversations", (ids) => {
      Missive.fetchConversations(ids, ["email_addresses"]).then(
        (conversations) => {
          if (conversations.length != 1) {
            // Do nothing if multiple conversations are selected.
            return;
          }

          let emailAddresses = Missive.getEmailAddresses(conversations);
          let emailAddressNotFramefox = emailAddresses.filter(
            (address) => address.address.indexOf("@framefox") === -1
          );

          this.setState({ emails: emailAddressNotFramefox });

          let emails = emailAddressNotFramefox.map(
            (address) => address.address
          );

          this.fetchUsers(emails);
        }
      );
    });

    // this.fetchUsers(["jemmasdasasbell@gmail.com"]);
  }

  fetchUsers(emails) {
    console.log(emails);
    fetch(`${this.props.user_missive_app_path}?emails=${emails}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.users) {
          this.setState({
            users: data.users,
            loading: false,
          });
        } else {
          this.setState({ users: [], loading: false });
        }
      });
  }

  reloadUI() {
    Missive.reload();
  }

  render() {
    return (
      <div>
        {this.state.loading && (
          <div className="h-full w-full absolute">
            <i className="fa-solid fa-spinner-third fa-spin text-4xl text-brand-600 relative top-1/2 left-1/2 -ml-6"></i>
          </div>
        )}
        {this.state.users.length == 0 && !this.state.loading && (
          <div className="rounded-lg bg-white shadow mx-4 my-5 p-6">
            No users found
          </div>
        )}
        <ul role="list" className="grid grid-cols-1">
          {this.state.users.map((user, i) => (
            <li
              key={user.email}
              className="col-span-1 rounded-lg bg-white shadow mx-4 my-5"
            >
              <div className="flex w-full justify-between space-x-6 p-6">
                <div className="flex-1 truncate">
                  <div className="flex items-center space-x-3">
                    <strong className="truncate text-sm font-medium text-gray-900">
                      <a
                        href={user.url}
                        target="_blank"
                        className="font-bold text-brand-600"
                      >
                        {user.name}
                      </a>
                    </strong>

                    {user.is_pro && (
                      <div className="ml-3 badge badge-brand">PRO</div>
                    )}
                  </div>
                  <div className="mt-1 truncate text-sm text-stone-500">
                    <a
                      href={`mailto:${user.email}`}
                      target="_blank"
                      className="text-brand-600"
                    >
                      {user.email}
                    </a>
                  </div>
                  <div className="mt-1 truncate text-sm text-stone-500">
                    <p className="mini-heading mb-1 mt-3">Signed Up</p>
                    {user.created_at}
                  </div>
                </div>
                <img
                  className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                  src={`https://avatars.Sidebar.com/${user.email}?size=40`}
                  alt=""
                />
              </div>
              <div className="divide-y divide-slate-200">
                {user.orders &&
                  user.orders.map((order) => {
                    return (
                      <div className="bg-pale p-5 " key={order.id}>
                        <div className="truncate text-sm text-stone-500">
                          <a
                            href={order.url}
                            target="_blank"
                            className="font-bold text-brand-600"
                          >
                            {order.key}
                          </a>
                          {order.region && ` - ${order.region}`}
                          <div
                            className={`ml-3 badge badge-brand badge-${order.status.toLowerCase()}`}
                          >
                            {" "}
                            {order.status}
                          </div>
                        </div>
                        {order.shipped_at ? (
                          <div className="truncate text-sm text-stone-500 mt-3">
                            <p className="mini-heading mb-1">Shipped</p>
                            {order.shipped_at}
                          </div>
                        ) : (
                          <div className="truncate text-sm text-stone-500 mt-3">
                            <p className="mini-heading mb-1">
                              Ordered / Created on
                            </p>
                            {order.ordered_at}
                          </div>
                        )}
                        <div className="truncate text-sm text-stone-500 mt-3">
                          <p className="mini-heading mb-1">Subtotal</p>$
                          {order.sub_total}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default MissiveSidebarApp;
