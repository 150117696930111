import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

class CustomFramePickupType extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      focussed: false,
      isLoading: false,
      url: props.url,
      id: props.id,
      options: [
        { title: 'Poster Tube', description: "Please let us know if your tube is not roughly the width of your artwork.", id: 0, value: 'tube'},
        { title: 'Flat Pack', description: "We suggest placing artwork between two pieces of card for protection.", id: 1, value: 'flat'}
      ],
      parcel_type: props.parcel_type
    }
    this.setActive         = this.setActive.bind(this);
    this.completeResponse  = this.completeResponse.bind(this);
  }

  setActive(i){
    $.ajax({
      method: 'PATCH',
      url: this.state.url,
      data: {  parcel_type: this.state.options[i].value, custom_frame_id: this.state.id }
    }).done(this.completeResponse);

    this.setState({
      parcel_type: this.state.options[i].value
    })
  }

  completeResponse(data){

  }

  render () {
    return (
      <div className="categories-radio-buttons block-radio mt-2">
        {this.state.options.map(function(radio, i){
          return (
            <div className='categories-radio-button radio-text' key={radio.id} >
              <input type="radio"
                     id={'parcel_type_' + this.state.id + '_option_'+i}
                     key={radio.id}
                     name={'parcel_type_' + this.state.id }
                     value={radio.id}
                     data-text={radio.title}
                     checked={this.state.parcel_type == radio.value}
                     onChange={this.setActive.bind(null, i)} />
              <label htmlFor={'parcel_type_' + this.state.id + '_option_'+i} >
                <div className='media'>
                  <div className='mr-2'>
                    <i className='icon-radio'></i>
                  </div>
                  <div className='media-body'>
                    {radio.title}
                    <br/><small>{radio.description}</small>
                  </div>

                </div>
              </label>
            </div>
          )
        }.bind(this))}
      </div>
    )
  }
}


export default CustomFramePickupType;