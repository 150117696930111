import React from "react";
import ReactDOM from "react-dom";
import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";

const ArtworkUnit = (props) => {
  return (
    <RadioGroup
      value={props.defaultChecked}
      onChange={props.saveValues}
      className="mt-2"
    >
      <div className="">
        {props.options.map((option) => (
          <RadioGroup.Option
            key={option.id}
            value={option}
            className={({ active, checked }) =>
              classNames(
                active ? "ring-2 ring-brand-600 ring-offset-2" : "",
                checked
                  ? "bg-brand-600 text-white hover:bg-brand-500"
                  : "ring-1 ring-inset ring-white bg-stone-100 text-gray-900 hover:bg-gray-50",
                "items-center justify-center rounded-md mr-2 mb-2 py-2.5 px-2.5 text-sm font-semibold  inline-block cursor-pointer"
              )
            }
          >
            <RadioGroup.Label as="span">{option.title}</RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};
export default ArtworkUnit;
