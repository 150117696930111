import { useState } from 'react';

function useImageWindows(initialState) {
  const [state, setState] = useState(initialState);

  function setImageWindows(imageWindows) {
    setState(imageWindows);
  }

  return [state, setImageWindows];
}

export default useImageWindows;