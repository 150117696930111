import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { fabric } from "fabric";

class FrameCanvas extends React.Component {
  constructor(props) {
    super();
    this.state = {
      imageLoaded: false,
      frameready: false,
    };

    this.loadArtWork = this.loadArtWork.bind(this);
    this.updateImage = this.updateImage.bind(this);
    this.updateFrame = this.updateFrame.bind(this);
    this.updateLayout = this.updateLayout.bind(this);
    this.saveBlob = this.saveBlob.bind(this);
    this.imageStyle = this.imageStyle.bind(this);
    this.containerStyle = this.containerStyle.bind(this);
    this.handleImageLoaded = this.handleImageLoaded.bind(this);
  }

  componentDidMount() {
    let maxWidth = Math.round(this.props.maxContainerWidth);
    let maxHeight = Math.round(this.props.maxContainerHeight);

    let mat_style = this.props.mat_style;
    let frameWidth = parseInt(this.props.frame_style.width);
    let canvasWidth =
      parseInt(this.props.width_mm) +
      frameWidth * 2 +
      mat_style.left +
      mat_style.right;
    let canvasHeight =
      parseInt(this.props.height_mm) +
      frameWidth * 2 +
      mat_style.top +
      mat_style.bottom;

    const longestEdge = canvasWidth > canvasHeight ? canvasWidth : canvasHeight;
    const ratioOuter = canvasWidth / canvasHeight;
    const ratio = this.props.width_mm / this.props.height_mm;

    const roomScale = this.props.scale
      ? (longestEdge / 1000) * this.props.scale
      : 1;

    if (ratioOuter >= 1 || this.props.isGalleryWall) {
      var scaleF =
        (roomScale * parseInt(this.props.maxContainerWidth * 2)) / canvasWidth;
    } else {
      var scaleF =
        (roomScale * parseInt(this.props.maxContainerHeight * 2)) /
        canvasHeight;
    }

    canvasWidth = canvasWidth * scaleF;
    canvasHeight = canvasHeight * scaleF;
    let fw = frameWidth * scaleF;
    frameWidth = Math.floor(fw - (fw % 2));

    let mountColor = this.props.mat.colour;
    let mountBevelColor = this.props.mat.core_colour;

    let mountAndFrameLeft = mat_style.left * scaleF + frameWidth - 1;
    let mountAndFrameRight = mat_style.right * scaleF + frameWidth - 1;
    let mountAndFrameTop = mat_style.top * scaleF + frameWidth - 1;
    let mountAndFrameBottom = mat_style.bottom * scaleF + frameWidth - 1;

    let artworkWidth = canvasWidth - (mountAndFrameLeft + mountAndFrameRight);
    let artworkHeight = canvasHeight - (mountAndFrameTop + mountAndFrameBottom);

    const canvas = new fabric.StaticCanvas(this.refs.canvas);

    this.refs.canvas.style.display = "none";
    canvas.enableRetinaScaling = false;

    const base = new fabric.Rect({
      left: 0,
      top: 0,
      width: canvasWidth,
      height: canvasHeight,
      fill: "#fff",
    });
    canvas.add(base);

    this.setState(
      {
        canvasWidth: canvasWidth,
        canvasHeight: canvasHeight,
        frameWidth: frameWidth,
        mountAndFrameLeft: mountAndFrameLeft,
        mountAndFrameRight: mountAndFrameRight,
        mountAndFrameTop: mountAndFrameTop,
        mountAndFrameBottom: mountAndFrameBottom,
        artworkHeight: artworkHeight,
        artworkWidth: artworkWidth,
        mountColor: this.props.mat.colour,
        mountBevelColor: this.props.mat.core_colour,
        ratio: ratio,
        ratioOuter: ratioOuter,
        canvas: canvas,
        scaleF: scaleF,
      },
      () => {
        this.props.useNonCanvasImage ? this.updateFrame() : this.loadArtWork();
      }
    );
  }

  loadArtWork() {
    fabric.Image.fromURL(
      this.props.preview_url,
      function (userImage) {
        this.updateImage(userImage);
      }.bind(this),
      {
        crossOrigin: "Anonymous",
      }
    );
  }

  updateImage(userImage) {
    this.state.canvas.add(userImage);
    userImage.moveTo(0);
    this.state.canvas.bringToFront(userImage);

    this.setState({ userImage: userImage });

    const imageRatio = userImage.width / userImage.height;
    if (imageRatio <= this.state.ratio) {
      userImage.scaleToWidth(this.state.artworkWidth);
    } else {
      userImage.scaleToHeight(this.state.artworkHeight);
    }

    userImage.set("originX", "center");
    userImage.set("originY", "center");
    userImage.set(
      "top",
      this.state.mountAndFrameTop + this.state.artworkHeight / 2
    );
    userImage.set("left", this.state.canvasWidth / 2);

    this.updateFrame();
  }

  updateFrame() {
    const canvas = this.state.canvas;

    const topFramePoints = [
      { x: -1, y: -1 },
      { x: this.state.canvasWidth + 1, y: -1 },
      {
        x: this.state.canvasWidth + 1 - this.state.frameWidth,
        y: this.state.frameWidth,
      },
      { x: this.state.frameWidth, y: this.state.frameWidth },
    ];

    const sideFramePoints = [
      { x: -1, y: -1 },
      { x: this.state.canvasHeight + 1, y: -1 },
      {
        x: this.state.canvasHeight + 1 - this.state.frameWidth,
        y: this.state.frameWidth,
      },
      { x: this.state.frameWidth, y: this.state.frameWidth },
    ];

    // MAT

    const mountClipPoints = [
      { x: this.state.mountAndFrameLeft, y: this.state.mountAndFrameTop },
      {
        x: this.state.canvasWidth - this.state.mountAndFrameRight,
        y: this.state.mountAndFrameTop,
      },
      {
        x: this.state.canvasWidth - this.state.mountAndFrameRight,
        y: this.state.canvasHeight - this.state.mountAndFrameBottom,
      },
      {
        x: this.state.mountAndFrameLeft,
        y: this.state.canvasHeight - this.state.mountAndFrameBottom,
      },
    ];

    let rShadowStyle;
    let lShadowStyle;
    let tShadowStyle;
    let bShadowStyle;

    if (this.props.frame_style.frame_type == "canvas") {
      rShadowStyle = {
        color: "#37302c",
        blur: 2,
        offsetX: -4 * this.state.scaleF,
        offsetY: 0,
      };
      lShadowStyle = {
        color: "#37302c",
        blur: 2,
        offsetX: 4 * this.state.scaleF,
        offsetY: 0,
      };
      tShadowStyle = {
        color: "#37302c",
        blur: 2,
        offsetX: 0,
        offsetY: 4 * this.state.scaleF,
      };
      bShadowStyle = {
        color: "#37302c",
        blur: 2,
        offsetX: 0,
        offsetY: -4 * this.state.scaleF,
      };
    } else {
      rShadowStyle = {
        color: "rgba(0,0,0,0.3)",
        blur: 6 * this.state.scaleF,
        offsetX: -2,
        offsetY: 0,
      };
      lShadowStyle = {
        color: "rgba(0,0,0,0.3)",
        blur: 8 * this.state.scaleF,
        offsetX: 0,
        offsetY: 0,
      };
      tShadowStyle = {
        color: "rgba(0,0,0,0.4)",
        blur: 6 * this.state.scaleF,
        offsetX: 0,
        offsetY: 2,
      };
      bShadowStyle = {
        color: "rgba(0,0,0,0.3)",
        blur: 8 * this.state.scaleF,
        offsetX: 0,
        offsetY: 0,
      };
    }

    const mountFront = new fabric.Rect({
      left: 0,
      top: 0,
      width: this.state.canvasWidth,
      height: this.state.canvasHeight,
      fill: this.state.mountColor,
    });
    canvas.add(mountFront);

    const mountClipper = new fabric.Polygon(mountClipPoints, {
      fill: "#aa0000",
      absolutePositioned: true,
    });
    mountClipper.inverted = true;
    mountFront.clipPath = mountClipper;

    if (this.props.mat_style.border == "mat_border") {
      const mountBevel = new fabric.Polygon(mountClipPoints, {
        stroke: this.state.mountBevelColor,
        strokeWidth: 2 * this.state.scaleF,
        strokeLineJoin: "bevil",
        fill: "rgba(0,0,0,0)",
      });
      canvas.add(mountBevel);
    }

    const top = new fabric.Polygon(topFramePoints, { fill: "white" });

    const right = new fabric.Polygon(sideFramePoints, {
      left: this.state.canvasWidth + 1,
      fill: "white",
      angle: 90,
    });

    const bottom = fabric.util.object.clone(top);
    bottom.set("angle", 180);
    bottom.set("top", this.state.canvasHeight + 1);
    bottom.set("left", this.state.canvasWidth + 1);

    const left = fabric.util.object.clone(right);
    left.set("angle", 270);
    left.set("left", -1);
    left.set("top", this.state.canvasHeight + 1);

    const tShadow = fabric.util.object.clone(top);
    const bShadow = fabric.util.object.clone(bottom);
    const lShadow = fabric.util.object.clone(left);
    const rShadow = fabric.util.object.clone(right);

    tShadow.set("top", -2);
    lShadow.set("left", -2);
    rShadow.set("left", this.state.canvasWidth + 2);
    bShadow.set("top", this.state.canvasHeight + 2);

    canvas.add(tShadow);
    canvas.add(bShadow);
    canvas.add(lShadow);
    canvas.add(rShadow);

    tShadow.setShadow(tShadowStyle);
    bShadow.setShadow(bShadowStyle);
    lShadow.setShadow(lShadowStyle);
    rShadow.setShadow(rShadowStyle);

    tShadow.set({ fill: this.state.mountColor });
    bShadow.set({ fill: this.state.mountColor });
    lShadow.set({ fill: this.state.mountColor });
    rShadow.set({ fill: this.state.mountColor });

    const patternURL = this.props.frame_style.pattern_url;
    const fw = this.state.frameWidth;

    fabric.Image.fromURL(
      patternURL,
      function (img) {
        const borderScale = window.devicePixelRatio > 1.5 ? 2 : 1;
        img.scaleToHeight(fw / borderScale);

        const patternSourceCanvas = new fabric.StaticCanvas();
        patternSourceCanvas.add(img);
        patternSourceCanvas.renderAll();
        const pattern = new fabric.Pattern({
          source: function () {
            patternSourceCanvas.setDimensions({
              width: img.getScaledWidth(),
              height: img.getScaledHeight(),
            });
            patternSourceCanvas.renderAll();
            return patternSourceCanvas.getElement();
          },
          repeat: "repeat-x",
        });

        top.set({ fill: pattern });
        bottom.set({ fill: pattern });
        left.set({ fill: pattern });
        right.set({ fill: pattern });

        canvas.add(bottom);
        canvas.add(left);
        canvas.add(right);
        canvas.add(top);

        this.updateLayout();
      }.bind(this),
      {
        crossOrigin: "Anonymous",
      }
    );
  }

  updateLayout() {
    this.state.canvas.setDimensions(
      {
        width: this.state.canvasWidth,
        height: this.state.canvasHeight,
      },
      { backstoreOnly: true }
    );

    this.state.canvas.setDimensions(
      {
        width: this.state.canvasWidth / 2 + "px",
        height: this.state.canvasHeight / 2 + "px",
      },
      { cssOnly: true }
    );

    this.setState({ frameready: true });

    this.refs.canvas && this.saveBlob();
  }

  saveBlob() {
    const dataURL = this.state.canvas.toDataURL({
      format: "jpg",
      multiplier: 0.5,
      quality: 0.6,
    });

    this.props.previewCallback(dataURL);

    // We repeat this since toDataURL() fucks things up

    this.state.canvas.setDimensions(
      {
        width: this.state.canvasWidth,
        height: this.state.canvasHeight,
      },
      { backstoreOnly: true }
    );

    this.state.canvas.setDimensions(
      {
        width: this.state.canvasWidth / 2 + "px",
        height: this.state.canvasHeight / 2 + "px",
      },
      { cssOnly: true }
    );

    this.state.canvas.renderAll();
    this.refs.canvas.style.display = "block";
  }

  imageStyle() {
    const imageRatio = this.props.image.width / this.props.image.height;
    if (imageRatio <= this.state.ratio) {
      return {
        width: this.state.artworkWidth / 2,
      };
    } else {
      return {
        height: this.state.artworkHeight / 2,
      };
    }
  }

  containerStyle() {
    return {
      top: this.state.mountAndFrameTop / 2 + this.state.artworkHeight / 4,
      width: this.state.artworkWidth / 2,
      height: this.state.artworkHeight / 2,
    };
  }

  handleImageLoaded() {
    this.props.artworkImageOnload();
  }

  render() {
    return (
      <>
        <canvas
          id="canvasFrame"
          ref="canvas"
          className="mx-auto shadow-lg !w-full !h-auto"
        />
        {this.props.useNonCanvasImage && (
          <CanvasArtwork
            handleImageLoaded={this.handleImageLoaded}
            artworkLoading={this.props.artworkLoading}
            containerStyle={this.state.artworkHeight && this.containerStyle()}
            imageStyle={this.imageStyle()}
            url={this.props.preview_url}
            frameready={this.state.frameready}
            preloader={this.props.preloader}
          />
        )}
      </>
    );
  }
}

const CanvasArtwork = ({
  frameready,
  containerStyle,
  url,
  imageStyle,
  handleImageLoaded,
  artworkLoading,
  preloader,
}) => (
  <div>
    {frameready && (
      <div className="canvas-artwork" style={containerStyle}>
        <img
          src={url}
          className="canvas-artwork-img"
          style={imageStyle}
          onLoad={handleImageLoaded}
        />
      </div>
    )}
    {(!frameready || artworkLoading) && (
      <div className="frame-preloader" style={containerStyle}>
        <img src={preloader} />
      </div>
    )}
  </div>
);

export default FrameCanvas;
