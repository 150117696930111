import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import Modal from "react-responsive-modal";
import { RadioGroup } from "@headlessui/react";

class OrderGiftOptions extends React.Component {
  constructor(props) {
    super();
    this.state = {
      modalIsOpen: false,
      gift_options: props.gift_options,
      gift_option: props.gift_options.find(
        (obj) => obj.id === props.gift_option_id
      ),
      gift_option_note: props.gift_option_note || "",
      focussed: false,
      isLoading: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.completeResponse = this.completeResponse.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.saveState = this.saveState.bind(this);
  }
  openModal(e) {
    e.preventDefault();
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleSubmit(e) {
    e.preventDefault();

    $.ajax({
      method: "PATCH",
      url: this.props.url,
      data: {
        order: {
          gift_option_note: this.state.gift_option_note,
          gift_option_id: this.state.gift_option.id,
        },
      },
    }).done(this.completeResponse);
  }

  completeResponse(data) {
    if (data.success) {
      this.setState({ modalIsOpen: false });
      location.reload();
    } else {
      alert("Something went wrong");
    }
  }

  changeHandler(e) {
    console.log(e.target.value);
    this.setState({
      gift_option_note: e.target.value,
      remaining_char: this.state.gift_option.max_chars - e.target.value.length,
    });
  }

  saveState(active) {
    this.setState({
      gift_option: active,
      remaining_char: active.max_chars,
    });
  }

  render() {
    return (
      <div>
        <button
          onClick={this.openModal}
          className="text-center w-full block px-0 mb-4 btn bg-white  rounded hover:bg-brand-200"
        >
          <i className="fa-solid fa-plus "></i>
          {this.state.gift_option.title != "No gift note"
            ? " " +
              this.state.gift_option.title +
              " - " +
              this.props.currency +
              this.state.gift_option.price_cents / 100
            : " Add a gift note"}
        </button>

        <Modal
          open={this.state.modalIsOpen}
          onClose={this.closeModal}
          classNames={{
            overlay: "custom-overlay",
            modal: "custom-modal !max-w-md",
          }}
        >
          <div className="modal-scroll">
            <div className="text-center mb-3">
              <h2 className="mb-1">Make it special</h2>
              <p className="text-muted mb-1">
                Add a personal touch for someone special.
              </p>
            </div>
            <img src={this.props.img} className="img-fluid" />

            <RadioGroup
              value={this.state.gift_option}
              onChange={this.saveState}
            >
              <div className="space-y-4 my-6">
                {this.state.gift_options.map((radio, i) => (
                  <RadioGroup.Option
                    value={radio}
                    key={radio.id}
                    className={({ checked, active }) =>
                      classNames(
                        checked ? "border-transparent" : "border-white",
                        active ? "border-brand-600 ring-2 ring-brand-600" : "",
                        "flex relative cursor-pointer rounded-lg border bg-pale px-4 py-4 focus:outline-none"
                      )
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <span
                          className={classNames(
                            checked
                              ? "bg-brand-600 border-transparent"
                              : "bg-pale border-gray-300",
                            active ? "ring-2 ring-offset-2 ring-brand-600" : "",
                            "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                          )}
                          aria-hidden="true"
                        >
                          <span className="rounded-full bg-pale w-1.5 h-1.5" />
                        </span>
                        <span className="flex items-center ml-3 grow">
                          <span className="flex flex-col text-sm grow">
                            <RadioGroup.Label
                              as="span"
                              className="font-bold text-gray-900"
                            >
                              {radio.title}
                            </RadioGroup.Label>
                            <span>{radio.description}</span>
                          </span>
                        </span>
                        <RadioGroup.Description
                          as="span"
                          className="mt-2 flex text-sm sm:ml-4 sm:mt-0 sm:flex-col sm:text-right"
                        >
                          <span className="font-bold text-gray-900">
                            {radio.price_cents > 0 && (
                              <div className="">
                                {this.props.currency}
                                {radio.price_cents / 100}
                              </div>
                            )}
                          </span>
                        </RadioGroup.Description>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>

            {this.state.gift_option.require_message && (
              <div className="mb-5">
                <textarea
                  autoFocus
                  maxLength={this.state.gift_option.max_chars}
                  className="mt-3 focus:ring-brand-600 focus:border-brand-600 
                 block w-full border-gray-300 rounded-sm  w-full"
                  rows="3"
                  value={this.state.gift_option_note}
                  placeholder={"Enter your message here..."}
                  onChange={this.changeHandler}
                />
                <div className="mt-2 text-xs">
                  {this.state.remaining_char} remaining · No emoji please 🙅‍♀️
                </div>
              </div>
            )}

            <button
              className="block btn btn-primary btn-lg mt-2 mx-auto"
              onClick={this.handleSubmit}
              disabled={
                this.state.gift_option_note.length == 0 &&
                this.state.gift_option.require_message
              }
            >
              Save
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default OrderGiftOptions;
