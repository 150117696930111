import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import CurrencyDisplay from "components/helpers/CurrencyDisplay";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

const sizeBrackets = [
  { id: 1, title: "Small" },
  { id: 2, title: "Medium" },
  { id: 3, title: "Large" },
];

class MultiPhotoIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: props.templates,
      size_filter: sizeBrackets[1],
    };
    this.onFilterSize = this.onFilterSize.bind(this);
    this.filteredSet = this.filteredSet.bind(this);
  }

  componentDidMount() {
    this.onFilterSize(this.state.size_filter);
  }

  priceRange(size) {
    const set = this.filteredSet(size).sort(
      (a, b) => parseInt(a.price) - parseInt(b.price)
    );

    return `${global.App.currencySymbol}${set[0].price} - ${
      global.App.currencySymbol
    }${set[set.length - 1].price}`;
  }

  onFilterSize(size) {
    this.setState({
      templates: this.filteredSet(size.title),
      size_filter: size,
    });
  }

  filteredSet(size) {
    return this.props.templates
      .filter((item) => item.size.includes(size))
      .sort((a, b) => a["id"] - b["id"]);
  }

  render() {
    const { sizes, is_pro, tax_rate } = this.props;
    const { templates, size_filter } = this.state;

    return (
      <div>
        <div className="max-w-3xl mx-auto mb-5 md:mb-10">
          <RadioGroup value={size_filter} onChange={this.onFilterSize}>
            <div className="mt-4 grid gap-y-6 grid-cols-3  gap-x-2 md:gap-x-4">
              {sizeBrackets.map((size) => (
                <RadioGroup.Option
                  key={size.id}
                  value={size}
                  className={({ active }) =>
                    classNames(
                      active
                        ? "border-brand-600 ring-2 ring-brand-600"
                        : "border-gray-300",
                      "relative flex cursor-pointer rounded-lg border bg-white p-2 md:p-4 shadow-sm focus:outline-none"
                    )
                  }
                >
                  {({ checked, active }) => (
                    <>
                      <span className="flex flex-1">
                        <span className="flex flex-col">
                          <RadioGroup.Label
                            as="span"
                            className="block text-base font-bold text-stone-900"
                          >
                            {size.title}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className="mt-1 flex items-center text-sm md:text-base text-stone-500"
                          >
                            {this.priceRange(size.title)}
                          </RadioGroup.Description>
                        </span>
                      </span>
                      <CheckCircleIcon
                        className={classNames(
                          !checked ? "invisible" : "",
                          "h-5 w-5 text-brand-600"
                        )}
                        aria-hidden="true"
                      />
                      <span
                        className={classNames(
                          active ? "border" : "border-2",
                          checked ? "border-brand-600" : "border-transparent",
                          "pointer-events-none absolute -inset-px rounded-lg"
                        )}
                        aria-hidden="true"
                      />
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>
        <div>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-x-2 md:gap-x-6 items-center">
            {templates.map(
              function (f, i) {
                return (
                  <div className="" key={f.id}>
                    <TemplateItem
                      template={f}
                      tax_rate={tax_rate}
                      is_pro={is_pro}
                    />
                  </div>
                );
              }.bind(this)
            )}
          </div>
        </div>
      </div>
    );
  }
}

const TemplateItem = ({ template, is_pro, tax_rate }) => {
  return (
    <a href={template.path} className="block text-center mb-5 group">
      <img
        src={template.image}
        className="img-fluid lead my-3 group-hover:opacity-70"
      />
      <span className="text-stone-900 font-bold">{template.description}</span>

      <span className="text-xs font-bold text-brand-600 block">
        {template.size_desc && <span>{template.size_desc} frame · </span>}

        <span className="hidden md:inline">
          {template.styles_count} frame styles
        </span>
      </span>
      <h4 className="font-serif tracking-tight text-2xl md:text-3xl text-stone-900 md:mt-2">
        <span className="font-bold text-sm font-sans relative -top-1.5 md:-top-3 mr-0.5">
          {global.App.currencySymbol}
        </span>
        <CurrencyDisplay show_prefix={false} value={template.price} />
      </h4>
      {is_pro && (
        <small className="ml-2 font-weight-bold text-stone-500">
          <CurrencyDisplay value={template.price / (1 + tax_rate)} /> excl{" "}
          {global.App.taxName}{" "}
          <span className="badge badge-pale">Pro Price</span>
        </small>
      )}
    </a>
  );
};

export default MultiPhotoIndex;
