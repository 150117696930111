import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { RadioGroup } from "@headlessui/react";

class FrameIndex extends React.Component {
  constructor(props) {
    super();
    this.state = {
      maxContainerWidth: 260,
      maxContainerHeight: 260,
      frame_colour: null,
      frame_colours: props.frame_colours,
      frame_styles: props.frame_styles,
    };
    this.updateframeColour = this.updateframeColour.bind(this);
    this.reset = this.reset.bind(this);
    this.filterFramesInCollection = this.filterFramesInCollection.bind(this);
  }
  updateframeColour(item) {
    console.log(item);
    this.setState({
      frame_styles: this.props.frame_styles.filter((e) =>
        item.style_ids.includes(e.id)
      ),
      frame_colour: item,
    });
  }

  reset(e) {
    e.preventDefault();
    this.setState({
      frame_styles: this.props.frame_styles,
      frame_colour: null,
    });
  }

  filterFramesInCollection(collection_id) {
    return this.state.frame_styles.filter(
      (item) => item.collection_id == collection_id
    );
  }

  render() {
    return (
      <div className="mb-3 mx-4 md:mx-0">
        <div>
          <div className="text-center">
            {this.state.frame_colour ? (
              <div className="mini-heading mb-1">
                <a href="#" onClick={this.reset}>
                  Show all
                </a>
              </div>
            ) : (
              <div className="mini-heading">Frame Colour</div>
            )}
            <FrameColourSelector
              saveValues={this.updateframeColour}
              options={this.state.frame_colours}
              defaultChecked={this.state.frame_colour}
              name="Frame colour"
              groupName="frame_colour"
            />
          </div>
        </div>
        <div>
          {this.props.frame_style_collections.map((collection, i) => (
            <div key={collection.id}>
              <div className="grid grid-cols-12 gap-x-4 mt-8">
                {!this.state.frame_colour && (
                  <div className="col-span-12 md:col-span-6">
                    <div
                      className="bg-cover pt-[60%] relative mb-3 overflow-hidden"
                      style={{ backgroundImage: `url(${collection.image})` }}
                    >
                      <div className="absolute bottom-0 left-0 w-full p-5 pt-20 bg-gradient-to-t from-[#00000070]">
                        <p className="mini-heading mb-0 text-white">
                          Collection
                        </p>
                        <h2 className="text-4xl text-white">
                          {collection.title}
                        </h2>
                      </div>
                    </div>
                  </div>
                )}
                {this.filterFramesInCollection(collection.id).length > 0 ? (
                  this.filterFramesInCollection(collection.id).map((f, i) => (
                    <div className="col-span-6 md:col-span-3" key={f.id}>
                      <FrameBoxContainer
                        frame_style={f}
                        preloader={this.props.preloader}
                      />
                    </div>
                  ))
                ) : (
                  <div className="col-span-12 text-muted">
                    No {this.state.frame_colour && this.state.frame_colour.name}{" "}
                    frames in this collection
                  </div>
                )}
              </div>
              <hr />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

class FrameBoxContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      imageLoaded: false,
    };
    this.handleImageLoaded = this.handleImageLoaded.bind(this);
  }

  handleImageLoaded() {
    this.setState({ imageLoaded: true });
  }

  render() {
    let classes = classNames(
      "absolute left-1/2 top-1/2",
      this.state.imageLoaded && "hidden"
    );

    return (
      <a
        href={this.props.frame_style.set_frame_url}
        className="mb-10 block mx-auto text-center"
      >
        <div className="relative w-full pt-[100%] group">
          <LazyLoadImage
            className="absolute h-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 group-hover:opacity-0 transition-opacity duration-300"
            beforeLoad={(e) => this.setState({ imageLoaded: false })}
            afterLoad={(e) => this.setState({ imageLoaded: true })}
            src={this.props.frame_style.front}
          />
          <LazyLoadImage
            className="absolute w-full h-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            src={this.props.frame_style.profile}
          />
          <div className={classes}>
            <img
              src={this.props.preloader}
              className="w-8 h-8 mx-auto -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        </div>
        <h5 className="mb-0 mt-3 font-serif tracking-tight text-stone-800 md:text-2xl">
          {this.props.frame_style.title}
        </h5>
        <p className="text-xs text-muted mt-1">
          {this.props.frame_style.excerpt}
        </p>
      </a>
    );
  }
}

const FrameColourSelector = (props) => (
  <RadioGroup
    value={props.defaultChecked}
    onChange={(e) => props.saveValues(e)}
    className="mt-2"
  >
    <div className="">
      {props.options.map((option, i) => (
        <RadioGroup.Option
          key={option.id}
          value={option}
          className={({ active, checked }) =>
            classNames(
              active ? "ring-2 ring-brand-600 ring-offset-2" : "",
              checked
                ? "bg-brand-600 text-white hover:bg-brand-500"
                : "ring-1 ring-inset ring-white bg-white text-gray-900 hover:bg-gray-50",
              "items-center justify-center rounded-md mr-2 mb-2 py-2.5 px-2.5 text-sm font-semibold  inline-block cursor-pointer"
            )
          }
        >
          <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
        </RadioGroup.Option>
      ))}
    </div>
  </RadioGroup>
);

export default FrameIndex;
