import React from "react";
import ReactDOM from "react-dom";
import cloudinary from "cloudinary-core";
import ColourPickers from "components/art/ColourPickers";
import { roomConfigs } from "components/art/constants";

class RoomPreviewUI extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <div className="py-3 px-3 bg-white wall-controls">
          <div className="mb-1">
            <strong>Wall Colour:</strong> {this.props.currRoomColour.label}
          </div>

          <ColourPickers
            colour={this.props.currRoomColour}
            onColourChange={this.props.onRoomColourChange}
          />
          <p className="text-really-muted small mt-3">
            Colours shown here are a representation only. Visit a Resene store
            for physical samples.
          </p>
        </div>
      </div>
    );
  }
}

export default RoomPreviewUI;
