import React from "react";
import ReactDOM from "react-dom";

class ArticleSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currSearch: "",
      results: [],
    };
    this.updateQuery = this.updateQuery.bind(this);
    this.updateResults = this.updateResults.bind(this);
    this.completeResponse = this.completeResponse.bind(this);
  }

  updateQuery(e) {
    e.preventDefault();
    this.setState({ currSearch: e.target.value });
    this.updateResults(e.target.value);
  }

  updateResults(term) {
    this.setState({ loading: true });
    fetch(`${this.props.search_url}?q=${term}`).then(this.completeResponse);
  }

  completeResponse(response) {
    response.json().then((json) => {
      const { results } = json;

      this.setState({
        results: results,
      });
    });
  }

  render() {
    return (
      <div className="container mt-3">
        <div className="row justify-content-center">
          <div className="col-sm-20">
            <div className="search-results-wrap">
              <div className="form-group has-search flex-fill">
                <i className="fa-solid fa-magnifying-glass absolute mt-4 ml-3"></i>
                <input
                  type="text"
                  autoFocus
                  className="form-control w-full pl-10"
                  placeholder="Search by keyword or phrase..."
                  value={this.state.currSearch}
                  onChange={this.updateQuery}
                />
              </div>
              {this.state.results.length > 0 && (
                <div className="bg-white p-4 absolute border-1 border-stone-100 w-full">
                  {this.state.results.map((item) => {
                    return (
                      <a
                        className="dropdown-item"
                        key={item.id}
                        href={item.url}
                      >
                        {item.title}
                      </a>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ArticleSearch;
