import React from "react";
import ReactDOM from "react-dom";
import Select from "react-select";

import SelectTheme from "../../select_theme";

const SizeSelect = ({ style, styles, size, sizes, setSize }) => {
  function callback(e) {
    setSize(e.value);
  }

  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
    control: (base) => ({
      ...base,
      height: 45,
      minHeight: 45,
    }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
  };

  const selectOptions = sizes.map((size) => ({
    value: size,
    label: <DropDownItemLabel size={size} styles={styles} style={style} />,
  }));

  return (
    <Select
      className="style-select"
      classNamePrefix="style-select"
      value={selectOptions.find((item) => item.value == size)}
      styles={selectStyles}
      onChange={callback}
      options={selectOptions}
      isSearchable={false}
      theme={SelectTheme}
    />
  );
};

const DropDownItemLabel = ({ size, styles, style }) => {
  const itemPrice = parseInt(styles.find((style) => style.size == size).price);
  const diff = itemPrice - parseInt(style.price);
  const diffDisplay = diff > 0 ? `+$${diff}` : `-$${diff * -1}`;

  return (
    <div className="flex">
      <span className="grow">
        {size} <span className="text-muted">{diff != 0 && diffDisplay}</span>
      </span>
      <span className="mr-2 font-bold">
        {styles.find((style) => style.size == size).final_size}
      </span>
    </div>
  );
};

export default SizeSelect;
