import React from "react";
import ReactDOM from "react-dom";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

class FrameCarousel extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <Swiper
        spaceBetween={50}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        navigation
        autoplay={{
          delay: 2500,
        }}
        loop={true}
      >
        {this.props.frame_styles.map((frame, i) => (
          <SwiperSlide>
            <a
              href={frame.url}
              className="frame-container d-block text-center "
            >
              <div className="slide-show">
                <div className="corner-image">
                  <img className="img-fluid" src={frame.profile} />
                </div>
                <div className="lead-image">
                  <img className="img-fluid" src={frame.front} />
                </div>
              </div>
              <h3 className="mb-0 mt-3">{frame.title}</h3>
              <p className="small text-muted mb-10">{frame.excerpt}</p>
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    );
  }
}

export default FrameCarousel;
