import React from "react";
import ReactDOM from "react-dom";
import { Widget } from "@uploadcare/react-widget";
import { Tooltip } from "react-tippy";

class FilepickerPrintImage extends React.Component {
  constructor(props) {
    super();
    this.state = {
      fromSources: props.fromSources || "file facebook instagram dropbox url",
      resolution_acceptable: props.resolution_acceptable,
      shrink: props.shrink,
      show_crop: props.show_crop,
    };
    this.saveToDatabase = this.saveToDatabase.bind(this);
    this.saveSuccess = this.saveSuccess.bind(this);
    this.renderBlankState = this.renderBlankState.bind(this);
    this.saveFail = this.saveFail.bind(this);
    this.onWidgetChange = this.onWidgetChange.bind(this);
    this.widgetApi = React.createRef(null);
  }

  onWidgetChange(file) {
    this.setState({ resolution_acceptable: true });
    this.saveToDatabase(file);
  }

  saveToDatabase(data) {
    let newImage = {
      width: data.crop ? data.crop.width : data.originalImageInfo.width,
      height: data.crop ? data.crop.height : data.originalImageInfo.height,
      filename: data.name,
      external_id: data.uuid,
      host: "uploadcare",
      path: data.uuid + "/file_" + data.uuid,
      source: data.sourceInfo.source,
      url: data.cdnUrl,
      filesize: data.size,
      format: data.originalImageInfo.format,
      art_type_id: this.props.art_type_id,
    };

    $.ajax({
      method: "POST",
      url: this.props.post_image_url,
      data: {
        image: newImage,
      },
    })
      .done(this.saveSuccess)
      .fail(this.saveFail);
  }

  saveSuccess(data) {
    if (data.resolution_acceptable) {
      this.setState({ resolution_acceptable: true });
      window.location.href = data.url;
    } else {
      // const dialog = widgetApi.current.openDialog();
      console.log(this.widgetApi.current);
      this.setState({ resolution_acceptable: false });
    }
  }

  renderBlankState() {
    return (
      <div className="alert alert-warning mt-2 py-3">
        <h4>Whoops 😕</h4>
        <span>
          This image isn't a high enough resolution for us to print. Please try
          another file and avoid cropping too tightly.
        </span>
        <p className="text-muted small mt-1">
          Please feel free to <a href="/contact">contact us</a> if you're having
          problems.
        </p>
      </div>
    );
  }

  saveFail() {
    console.log("Failed to save");
  }

  render() {
    return (
      <div className="text-center">
        {this.state.resolution_acceptable == false && this.renderBlankState()}

        <Widget
          ref={this.widgetApi}
          validators={[maxResolution(this.props.max_pixels), maxFileSize]}
          onChange={(info) => this.onWidgetChange(info)}
          tabs={this.state.fromSources}
          imagesOnly="true"
          previewStep="true"
          clearable="true"
          store="auto"
          crop={this.props.show_crop == true ? "free" : "disabled"}
          shrink={this.state.shrink}
          localeTranslations={UPLOADCARE_LOCALE_TRANSLATIONS}
          id="file"
        />
      </div>
    );
  }
}

function maxResolution(max_pixels) {
  return function (fileInfo) {
    var imageInfo = fileInfo.originalImageInfo;
    if (imageInfo !== null) {
      if (imageInfo.width * imageInfo.height > max_pixels) {
        throw new Error("maxResolutionError");
      }
    }
  };
}

const maxFileSize = (fileInfo) => {
  if (fileInfo.size !== null && fileInfo.size > 100 * 1024 * 1024) {
    throw new Error("maxFileSizeError");
  }
};

export default FilepickerPrintImage;
