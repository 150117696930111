import React from "react";
import ReactDOM from "react-dom";
import { Beforeunload } from "react-beforeunload";
import classNames from "classnames";
import Modal from "react-responsive-modal";
import { Tooltip } from "react-tippy";
import Select from "react-select";
import { isMobile } from "react-device-detect";
import cloudinary from "cloudinary-core";
import FrameCanvas from "components/frame-designer/designers/FrameCanvas";
import MultiImagePicker from "components/gallery-walls/MultiImagePicker";
import EffectAndFiltersInline from "components/gallery-walls/EffectAndFiltersInline";
import StyleColourSelect from "components/gallery-walls/StyleColourSelect";
import SizeSelect from "components/gallery-walls/SizeSelect";
import CropImage from "components/gallery-walls/CropImage";

class GalleryDesignerUI extends React.Component {
  constructor(props) {
    super();
    this.state = {
      styles: props.gallery_wall_styles,
      style: props.gallery_wall_styles.find((i) => i.id == props.gws_id),
      items: props.gallery_wall_styles.find((i) => i.id == props.gws_id)
        .gallery_wall_items,
      modalOpen: false,
      trayIsOpen: false,
      redrawHack: true,
      current_item: null,
      current_image: null,
      images: props.images,
      loadProjectOpen: props.gallery_wall_project != null,
      sizes: props.style_sizes,
      size: props.style_sizes[0],
      unsavedChanges: false,
      deleteImageMode: false,
      isAddingToCart: false,
    };
    this.previewCallback = this.previewCallback.bind(this);
    this.setupScale = this.setupScale.bind(this);
    this.loadProject = this.loadProject.bind(this);
    this.artworkImageOnload = this.artworkImageOnload.bind(this);
    this.selectFrame = this.selectFrame.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeTray = this.closeTray.bind(this);
    this.onImageSelect = this.onImageSelect.bind(this);
    this.updateImages = this.updateImages.bind(this);
    this.onImageCropSave = this.onImageCropSave.bind(this);
    this.onEffectsUpdate = this.onEffectsUpdate.bind(this);
    this.onEffectsSave = this.onEffectsSave.bind(this);
    this.updateTrayStatuses = this.updateTrayStatuses.bind(this);
    this.pushImageIntoItem = this.pushImageIntoItem.bind(this);
    this.revertItemToDefault = this.revertItemToDefault.bind(this);
    this.redraw = this.redraw.bind(this);
    this.imagesUploaded = this.imagesUploaded.bind(this);
    this.switchStyle = this.switchStyle.bind(this);
    this.setSize = this.setSize.bind(this);
    this.toggleEffects = this.toggleEffects.bind(this);
    this.setUnsavedChanges = this.setUnsavedChanges.bind(this);
    this.containerStyle = this.containerStyle.bind(this);
    this.frameTooltipOff = this.frameTooltipOff.bind(this);
    this.frameTooltipMessage = this.frameTooltipMessage.bind(this);
    this.removeImageFromFrame = this.removeImageFromFrame.bind(this);
    this.onDragThumb = this.onDragThumb.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.handleUnload = this.handleUnload.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.savePurchase = this.savePurchase.bind(this);
    this.saveSuccess = this.saveSuccess.bind(this);
    this.saveFail = this.saveFail.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.setupScale);
    this.setupScale();

    this.setState({ size: this.state.style.size }, () =>
      this.setSize(this.state.size)
    );
  }

  previewCallback() {}

  setupScale() {
    let bufferW = isMobile ? 25 : 50;
    let bufferH = 300;
    let maxContainerWidth = this.refs.container.offsetWidth - bufferW;
    let maxContainerHeight = this.refs.container.offsetHeight - bufferH;
    let viewportRatio = maxContainerWidth / maxContainerHeight;
    let galleryRatio =
      this.state.style.total_width / this.state.style.total_height;
    let scale;

    if (viewportRatio < galleryRatio) {
      scale = maxContainerWidth / this.state.style.total_width;
    } else {
      scale = maxContainerHeight / this.state.style.total_height;
    }
    this.setState({
      scale: scale,
      scaleOG: scale,
      maxContainerHeight: maxContainerHeight,
    });
    this.redraw();
  }

  loadProject() {
    let style = this.props.gallery_wall_styles.find(
      (i) => i.id == this.props.gallery_wall_project.style_id
    );

    this.setState(
      { loadProjectOpen: false, style: style, size: style.size },
      () => this.setSize(style.size)
    );

    setTimeout(() => {
      this.props.gallery_wall_project.state.map((item) => {
        let image = this.state.images.find((i) => i.id == item.image_id);
        if (image) {
          this.pushImageIntoItem(
            image,
            item.gallery_wall_item_id,
            item.crop,
            image.effects
          );
          this.updateTrayStatuses(image);
        }
      });
    }, 500);
  }

  artworkImageOnload() {
    this.setState({ artworkLoadingID: null, effectsLoading: false });
  }

  selectFrame(item) {
    let newScale =
      (this.state.maxContainerHeight - 50) / item.height_and_mat_mm;
    this.setState(
      {
        trayIsOpen: true,
        current_image: item.image,
        current_item: item,
        scale: newScale,
      },
      () => this.redraw()
    );
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  closeTray() {
    this.setState(
      {
        trayIsOpen: false,
        current_item: null,
        current_image: null,
        scale: this.state.scaleOG,
      },
      () => this.redraw()
    );
  }

  onImageSelect(image) {
    this.setState({
      current_image: image,
      modalOpen: true,
    });
  }

  updateImages(images) {
    this.setState({ images: images });
  }

  onImageCropSave(image, crop) {
    this.updateTrayStatuses(image);
    this.pushImageIntoItem(
      image,
      this.state.current_item.id,
      crop,
      image.effects
    );

    this.setState({
      modalOpen: false,
      current_image: image,
      artworkLoadingID: this.state.current_item.id,
      unsavedChanges: true,
    });
  }

  onEffectsUpdate(image, effects) {
    this.pushImageIntoItem(
      image,
      this.state.current_item.id,
      this.state.current_item.crop,
      effects
    );
    this.setState({ effectsLoading: true });
  }

  onEffectsSave(image) {
    this.updateTrayStatuses(image);
    this.pushImageIntoItem(
      image,
      this.state.current_item.id,
      this.state.current_item.crop,
      image.effects
    );

    this.setState({
      effectsMode: false,
      current_image: image,
    });
  }

  updateTrayStatuses(image) {
    let newImages = this.state.images.map((i) => {
      if (i.id == image.id) {
        i.used = true;
      }
      return i;
    });

    this.setState({
      images: newImages,
    });
  }

  pushImageIntoItem(image, item_id, crop, effects) {
    let cl = new cloudinary.Cloudinary({
      cloud_name: "framefox",
      secure: true,
    });

    let filter = effects.filter ? "art:" + effects.filter : null;
    let viesus = effects.viesus_correct ? "viesus_correct" : null;

    let url = cl.url(image.original_url, {
      type: "fetch",
      transformation: [
        { width: 1000, crop: "fit" },
        { angle: crop.rotate },
        {
          x: crop.cx,
          y: crop.cy,
          width: crop.cw,
          height: crop.ch,
          crop: "crop",
        },
        { effect: filter },
        { effect: viesus },
      ],
    });

    let newItems = this.state.items.map((item) => {
      if (item.id == item_id) {
        item.image_id = image.id;
        item.image = image;
        item.crop = crop;
        item.preview_url = url;
      }
      return item;
    });

    this.setState({ items: newItems });
  }

  revertItemToDefault(item_id) {
    let cl = new cloudinary.Cloudinary({
      cloud_name: "framefox",
      secure: true,
    });

    let url = cl.url(this.props.blank_image.original_url, {
      type: "fetch",
      transformation: [{ width: 1000, crop: "fit" }],
    });

    let newItems = this.state.items.map((item) => {
      if (item.id == item_id) {
        item.image_id = null;
        item.image = this.props.blank_image;
        item.crop = null;
        item.preview_url = url;
      }
      return item;
    });

    this.setState({ items: newItems });
  }

  redraw() {
    this.setState({ redrawHack: false }, () =>
      this.setState({ redrawHack: true })
    );
  }

  imagesUploaded(images) {
    let usedIds = this.state.items.map((i) => {
      return i.image_id;
    });
    let newImages = images.map((i) => {
      if (usedIds.includes(i.id)) {
        i.used = true;
      }
      return i;
    });
    this.setState({ images: newImages });
  }

  switchStyle(style) {
    let newItems = style.gallery_wall_items.map((item) => {
      let it = this.state.items.find((i) => i.position === item.position);
      item.image_id = it.image.id;
      item.image = it.image;
      item.crop = it.crop;
      item.preview_url = it.preview_url;
      return item;
    });

    this.setState({ style: style, items: newItems }, () => this.setupScale());
  }

  setSize(size) {
    let filteredStyles = this.props.gallery_wall_styles.filter(
      (style) => style.size == size
    );
    let newStyle =
      this.state.style.size == size ? this.state.style : filteredStyles[0];

    this.setState({ size: size, styles: filteredStyles }, () =>
      this.switchStyle(newStyle)
    );
  }

  toggleEffects() {
    this.setState({
      effectsMode: !this.state.effectsMode,
      effectsSeen: true,
    });
  }

  setUnsavedChanges() {
    this.setState({
      unsavedChanges: true,
    });
  }

  containerStyle() {
    return {
      top: -this.state.current_item.py * this.state.scale - 80,
      left: -this.state.current_item.px * this.state.scale,
    };
  }

  frameTooltipOff() {
    return this.state.modalOpen || this.state.current_item.image_id != null;
  }

  frameTooltipMessage() {
    if (this.state.images && this.state.images.length) {
      return "Now select an image for this frame.";
    } else {
      return "To start, upload your images into the tray 👇";
    }
  }

  removeImageFromFrame() {
    let newImages = this.state.images.map((i) => {
      if (i.id == this.state.current_item.image_id) {
        i.used = false;
      }
      return i;
    });
    this.updateImages(newImages);
    this.revertItemToDefault(this.state.current_item.id);
  }

  onDragThumb(thumb) {
    this.setState({ draggedThumb: thumb });
  }

  onDrop(event, item) {
    this.selectFrame(item);
    this.onImageSelect(this.state.draggedThumb);
    this.setState({ draggedThumb: null, draggedOverFrame: null });
  }

  onDragOver(event, item) {
    event.preventDefault();
    if (this.state.draggedThumb) {
      this.setState({ draggedOverFrame: item });
    }
  }

  onDragLeave(event) {
    this.setState({ draggedOverFrame: null });
  }

  handleUnload(event) {
    if (
      event.target.activeElement.className.indexOf("depart-no-save") == -1 &&
      this.state.unsavedChanges
    ) {
      return "You'll lose your data!";
    }
  }

  addToCart(e) {
    this.setState({ isAddingToCart: true });
    let imageObjs = this.state.items.map((item) => {
      return {
        id: item.image.id,
        cx: parseInt((item.crop.cx * item.image.width) / 1000),
        cy: parseInt((item.crop.cy * item.image.width) / 1000),
        cw: parseInt((item.crop.cw * item.image.width) / 1000),
        ch: parseInt((item.crop.ch * item.image.width) / 1000),
        rotate: item.crop.rotate,
      };
    });

    $.ajax({
      method: "PUT",
      url: this.props.bulk_update_crop_image_path,
      data: {
        selection: {
          id: this.props.selection_id,
          images_attributes: imageObjs,
        },
      },
    }).done(this.savePurchase);
  }

  savePurchase() {
    let newItems = this.state.style.gallery_wall_items.map((item) => {
      return { image_id: item.image_id, gallery_wall_item_id: item.id };
    });

    $.ajax({
      method: this.props.is_edit_mode ? "PUT" : "POST",
      url: this.props.create_path,
      data: {
        gwp_id: this.props.gwp_id,
        gallery_wall_purchase: {
          gallery_wall_purchase_items_attributes: newItems,
          gallery_wall_style_id: this.state.style.id,
        },
      },
    })
      .done(this.saveSuccess)
      .fail(this.saveFail);
  }

  saveSuccess(data) {
    this.setState({ unsavedChanges: false });
    window.location.href = data.redirect_to;
  }

  saveFail(data) {
    if (data.status == 401) {
      window.location.href = this.props.sign_up_url;
    }
  }

  render() {
    let containerClasses = classNames(
      "gallery-wall-container",
      this.state.trayIsOpen && "tray-open",
      this.state.images && this.state.images.length && "images-present",
      this.state.draggedThumb && "thumb-dragging"
    );
    return (
      <div ref="container" className={containerClasses}>
        <Beforeunload onBeforeunload={this.handleUnload} />
        <div className="" style={{ height: "100%" }}>
          <div
            className="gallery-panel"
            style={this.state.trayIsOpen ? this.containerStyle() : null}
          >
            {this.state.items.map(
              function (item, i) {
                return (
                  <div
                    className={classNames(
                      "frame",
                      item == this.state.current_item && "active",
                      item.px > 0 && "left"
                    )}
                    key={item.id}
                  >
                    {this.state.scale && this.state.redrawHack && (
                      <div>
                        <div
                          className="frame-position"
                          href="#"
                          onDrop={(event) => this.onDrop(event, item)}
                          onDragOver={(event) => this.onDragOver(event, item)}
                          onDragLeave={(event) => this.onDragLeave(event)}
                          style={{
                            top: item.py * this.state.scale,
                            left: item.px * this.state.scale,
                            width: Math.ceil(
                              item.width_and_mat_mm * this.state.scale
                            ),
                          }}
                        >
                          <div
                            className={classNames(
                              "overlay",
                              this.state.draggedOverFrame == item &&
                                "draggedOver"
                            )}
                            onClick={this.selectFrame.bind(null, item)}
                          >
                            <i
                              className={classNames(
                                this.state.draggedOverFrame == item ||
                                  item.image.id == null
                                  ? "fa-plus text-2xl"
                                  : "fa-pen",
                                "fa-solid"
                              )}
                            ></i>
                          </div>

                          <FrameCanvas
                            useNonCanvasImage={true}
                            previewCallback={this.previewCallback}
                            artworkImageOnload={this.artworkImageOnload}
                            artworkLoading={
                              this.state.artworkLoadingID == item.id
                            }
                            width_mm={item.width_mm}
                            height_mm={item.height_mm}
                            maxContainerWidth={
                              item.width_and_mat_mm * this.state.scale
                            }
                            maxContainerHeight={
                              item.height_and_mat_mm * this.state.scale
                            }
                            image={item.image}
                            preview_url={item.preview_url}
                            mat_style={item.mat_style}
                            mat={item.mat}
                            frame_style={item.frame_style}
                            preloader={this.props.preloader}
                            isGalleryWall={true}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                );
              }.bind(this)
            )}
          </div>
          {this.state.trayIsOpen && (
            <div className="done-button">
              <div>
                {this.state.current_item && this.state.current_item.image_id ? (
                  <div>
                    <button
                      className="btn btn-primary mr-2 md:btn-lg md:!py-5"
                      onClick={this.removeImageFromFrame}
                    >
                      <i className="fa-solid fa-trash text-lg" />
                    </button>
                    <button
                      className="btn btn-primary md:btn-lg md:!py-5 mr-2"
                      onClick={this.onImageSelect.bind(
                        null,
                        this.state.current_item.image
                      )}
                    >
                      <i className="fa-solid fa-crop-simple text-lg" />
                    </button>
                    <Tooltip
                      title="✨ Apply effects for the finishing touch!"
                      open={true}
                      arrow={true}
                      position="top"
                      distance={20}
                      open={
                        !this.state.effectsMode &&
                        !this.state.effectsSeen &&
                        !this.state.modalOpen
                      }
                    >
                      <button
                        className="btn btn-primary mr-2 md:btn-lg"
                        onClick={this.toggleEffects}
                      >
                        <i className="fa-solid fa-wand-magic-sparkles mr-2" />{" "}
                        Effects
                      </button>
                    </Tooltip>
                    <button
                      className="btn btn-secondary md:btn-lg"
                      onClick={this.closeTray}
                    >
                      <i className="fa-solid fa-check mr-2" /> Done
                    </button>
                  </div>
                ) : (
                  <div>
                    <Tooltip
                      title={this.frameTooltipMessage()}
                      open={true}
                      distance={20}
                      position="top"
                      disabled={this.frameTooltipOff()}
                    >
                      <button
                        className="btn btn-secondary md:btn-lg"
                        onClick={this.closeTray}
                      >
                        <i className="fa-solid fa-check mr-2" /> Done
                      </button>
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className={classNames("right-sidebar")}>
            <div className="flex flex-row">
              <div
                className={classNames(
                  "grow",
                  this.state.images &&
                    this.state.images.length > 0 &&
                    "basis-2/12 max-w-[200px]"
                )}
              >
                <MultiImagePicker
                  big_frame_height={this.props.big_frame_height}
                  big_frame_width={this.props.big_frame_width}
                  post_image_url={this.props.post_image_url}
                  art_type_id={this.props.art_type_id}
                  onImagesUploaded={this.imagesUploaded}
                  deleteImageMode={this.state.deleteImageMode}
                  trayIsOpen={this.state.trayIsOpen}
                  images={this.state.images}
                  toggleDeleteImageMode={(e) =>
                    this.setState({
                      deleteImageMode: !this.state.deleteImageMode,
                    })
                  }
                  showTooltip={
                    (!this.state.images || this.state.images.length == 0) &&
                    !this.state.trayIsOpen
                  }
                />
              </div>
              {this.state.images && this.state.images.length > 0 && (
                <div className="basis-10/12 overflow-x-auto overflow-y-hidden">
                  <ImageSelection
                    big_frame_height={this.props.big_frame_height}
                    big_frame_width={this.props.big_frame_width}
                    onDragThumb={this.onDragThumb}
                    draggedThumb={this.state.draggedThumb}
                    images={this.state.images}
                    onImageSelect={this.onImageSelect}
                    updateImages={this.updateImages}
                    preloader={this.props.preloader}
                    deleteImageMode={this.state.deleteImageMode}
                    imagesSelectable={
                      this.state.current_item && this.state.trayIsOpen
                    }
                  />
                </div>
              )}
            </div>
          </div>
          {this.state.trayIsOpen && (
            <div className="text-center pt-3">
              <h3 className="mb-1">
                {this.state.current_item.frame_style.title} Frame
              </h3>
              <p className="font-bold text-muted">
                {this.state.current_item.size_desc} Print ·{" "}
                {this.state.current_item.total_dimensions_cm} Frame
              </p>
            </div>
          )}
          {!this.state.trayIsOpen && (
            <NavBarDefault
              is_edit_mode={this.props.is_edit_mode}
              addToCart={this.addToCart}
              setUnsavedChanges={this.setUnsavedChanges}
              onSaveProject={(e) => this.setState({ unsavedChanges: false })}
              saveFail={this.saveFail}
              switchStyle={this.switchStyle}
              save_project_url={this.props.save_project_url}
              sign_up_url={this.props.sign_up_url}
              signed_in={this.props.signed_in}
              back_path={this.props.back_path}
              items={this.state.items}
              images={this.state.images}
              gwp_id={this.props.gwp_id}
              setSize={this.setSize}
              sizes={this.state.sizes}
              size={this.state.size}
              stylesUnfiltered={this.props.gallery_wall_styles}
              styles={this.state.styles}
              style={this.state.style}
              isAddingToCart={this.state.isAddingToCart}
            />
          )}
        </div>
        {this.state.current_item && this.state.current_image && (
          <div>
            {this.state.effectsMode && (
              <EffectAndFiltersInline
                saveComplete={this.onEffectsSave}
                imageUpdate={this.onEffectsUpdate}
                effectsLoading={this.state.effectsLoading}
                image={this.state.current_image}
                crop={this.state.current_item.crop}
                preloader={this.props.preloader}
                crop_image_path={this.props.crop_image_path}
                effects={this.state.current_image.effects}
              />
            )}

            <Modal
              open={this.state.modalOpen}
              onClose={this.closeModal}
              classNames={{
                overlay: "custom-overlay",
                modal: "custom-modal large no-padding",
              }}
            >
              <div className="modal-scroll">
                <CropImage
                  image={this.state.current_image}
                  preloader={this.props.preloader}
                  source_ratio={this.state.current_image.source_ratio}
                  ratio={this.state.current_item.force_ratio}
                  template_longest_inches={
                    this.state.current_item.template_longest_inches
                  }
                  template_title={this.state.current_item.size_desc}
                  saveComplete={this.onImageCropSave}
                />
              </div>
            </Modal>
          </div>
        )}
        <Modal
          open={this.state.loadProjectOpen}
          onClose={(e) => this.setState({ loadProjectOpen: false })}
          classNames={{ overlay: "custom-overlay", modal: "custom-modal" }}
        >
          <div className="modal-scroll">
            <h3>Load your previous progress?</h3>
            <p>
              You saved your progress a while ago. Would you like to pick up
              where you left off?
            </p>
            <button
              type="button"
              className="btn btn-secondary mt-3"
              onClick={this.loadProject}
            >
              Load previous progress
            </button>
            <button
              type="button"
              className="btn btn-primary mt-3 ml-2"
              onClick={(e) => this.setState({ loadProjectOpen: false })}
            >
              Start from scratch
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

class ImageSelection extends React.Component {
  constructor(props) {
    super();
    this.state = {
      images: props.images,
    };
    this.onImageSelect = this.onImageSelect.bind(this);
    this.sizeWarning = this.sizeWarning.bind(this);
    this.unlinkImage = this.unlinkImage.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.onDrag = this.onDrag.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onImageSelect(radio) {
    if (!radio.used && this.props.imagesSelectable) {
      this.props.onImageSelect(radio);
    }
  }

  sizeWarning(radio) {
    let dpih = radio.height / this.props.big_frame_height;
    let dpiw = radio.width / this.props.big_frame_width;
    if (Math.min(dpih, dpiw) < 90) {
      return true;
    }
  }

  unlinkImage(item) {
    $.ajax({
      method: "GET",
      url: item.unlink_path,
    }).done(this.removeImage(item));
  }

  removeImage(item) {
    let array = [...this.props.images];
    let index = array.indexOf(item);
    array.splice(index, 1);
    this.props.updateImages(array);
  }

  onDrag(event, radio) {
    event.preventDefault();
    this.props.onDragThumb(radio);
  }

  onDragEnd(event, radio) {
    this.props.onDragThumb(null);
  }

  render() {
    return (
      <div className="thumbnail-holder">
        <div className="flex gap-x-3 flex-row flex-nowrap tray-thumbnails">
          {this.props.images.map((radio, i) => (
            <div className="" key={radio.id}>
              <a
                className={classNames(
                  "tray-thumbnail",
                  radio.used && "used",
                  this.props.deleteImageMode && "delete-mode",
                  this.props.draggedThumb == radio && "dragging"
                )}
                draggable="false"
                href="#"
                onClick={
                  this.props.deleteImageMode
                    ? this.unlinkImage.bind(null, radio)
                    : this.onImageSelect.bind(null, radio)
                }
              >
                <div className="thumbnail-preloader">
                  <img src={this.props.preloader} />
                </div>
                <img
                  src={radio.thumb}
                  draggable={!radio.used}
                  onDrag={(event) => this.onDrag(event, radio)}
                  onDragEnd={(event) => this.onDragEnd(event, radio)}
                  className="img-fluid rounded tray-thumbnail-image"
                />

                {this.props.deleteImageMode && (
                  <div className="load-image-btn !block">
                    <i className="fa-solid fa-trash text-xl"></i>
                  </div>
                )}

                {this.props.imagesSelectable && !this.props.deleteImageMode && (
                  <div className="load-image-btn">
                    <i className="fa-solid fa-check text-xl"></i>
                  </div>
                )}
                {this.sizeWarning(radio) && (
                  <i className="fa-solid fa-triangle-exclamation"></i>
                )}
                {!this.props.deleteImageMode && (
                  <div className="load-image-btn used">
                    <i className="fa-solid fa-check text-xl"></i>
                  </div>
                )}
              </a>
            </div>
          ))}
        </div>
        <div className="tray-overlay"></div>
      </div>
    );
  }
}

class NavBarDefault extends React.Component {
  constructor(props) {
    super();
    this.state = {
      accountModalOpen: false,
      saving: false,
      recentlySaved: false,
    };
    this.attemptSave = this.attemptSave.bind(this);
    this.saveProject = this.saveProject.bind(this);
    this.saveProjectSuccess = this.saveProjectSuccess.bind(this);
    this.switchStyle = this.switchStyle.bind(this);
    this.setSize = this.setSize.bind(this);
  }
  attemptSave() {
    if (this.props.signed_in) {
      this.saveProject();
    } else {
      this.setState({ accountModalOpen: true });
    }
  }

  saveProject(e) {
    let newItems = this.props.style.gallery_wall_items.map((item) => {
      return {
        image_id: item.image_id,
        gallery_wall_item_id: item.id,
        crop: item.crop,
      };
    });

    this.setState({ saving: true });

    $.ajax({
      method: "POST",
      url: this.props.save_project_url,
      data: {
        gallery_wall_project: {
          gallery_wall_style_id: this.props.style.id,
          state: newItems,
        },
      },
    })
      .done(this.saveProjectSuccess)
      .fail(this.props.saveFail);
  }

  saveProjectSuccess(data) {
    this.setState({ saving: false, recentlySaved: true });
    this.props.onSaveProject();

    setTimeout(() => {
      this.setState({ recentlySaved: false });
    }, 2500);
  }

  switchStyle(e) {
    this.props.setUnsavedChanges();
    this.props.switchStyle(e);
  }

  setSize(e) {
    this.props.setUnsavedChanges();
    this.props.setSize(e);
  }

  render() {
    let result = this.props.items.filter((item) => item.image_id);
    let remaining = this.props.items.length - result.length;

    return (
      <div>
        <Modal
          open={this.state.accountModalOpen}
          onClose={(e) => this.setState({ accountModalOpen: false })}
          classNames={{ overlay: "custom-overlay", modal: "custom-modal" }}
        >
          <div className="modal-scroll">
            <h3>Save your progress</h3>
            <p>Create an account to save and continue later.</p>
            <button
              type="button"
              className="btn btn-primary mt-3 depart-no-save"
              onClick={this.saveProject}
            >
              OK, create an account
            </button>
          </div>
        </Modal>
        <div className="flex relative items-center bg-pale p-2">
          <a className="btn btn-primary" href={this.props.back_path}>
            <i className="fa-solid fa-caret-left"></i>{" "}
            <span className="d-none d-sm-inline">Back</span>
          </a>
          {this.props.styles.length > 1 && (
            <StyleColourSelect
              style={this.props.style}
              styles={this.props.styles}
              setStyle={this.switchStyle}
            />
          )}
          {this.props.sizes.length > 1 && (
            <SizeSelect
              style={this.props.style}
              styles={this.props.stylesUnfiltered}
              size={this.props.size}
              sizes={this.props.sizes}
              setSize={this.setSize}
            />
          )}
          <div className="mx-3 font-bold hidden md:block">
            <div className="mini-heading mr-2 d-none d-md-block">
              Final Display Size
            </div>
            {this.props.style.final_size}
          </div>
          <div className="mx-3 font-bold  hidden md:block">
            <div className="mini-heading mr-2 d-none d-md-block">Total</div>
            {window.App.currencySymbol}
            {this.props.style.price}
          </div>

          <form className="flex ml-3">
            {!this.props.is_edit_mode && (
              <div>
                {this.state.saving ? (
                  <a
                    className="btn btn-primary btn-lg mr-2 disabled"
                    href="#"
                    onClick={this.attemptSave}
                  >
                    Saving...
                  </a>
                ) : (
                  <div>
                    {this.state.recentlySaved ? (
                      <a
                        className="btn btn-primary btn-success disabled btn-lg px-4 mr-2 depart-no-save"
                        href="#"
                        onClick={this.attemptSave}
                      >
                        <i className="fa-solid fa-check  mr-2"></i>{" "}
                        <span className="hidden md:inline">Saved</span>
                      </a>
                    ) : (
                      <a
                        className="btn btn-primary btn-lg px-4 mr-2 depart-no-save"
                        href="#"
                        onClick={this.attemptSave}
                      >
                        <i className="fa-solid fa-floppy-disk md:mr-2"></i>
                        <span className="hidden md:inline">Save</span>
                      </a>
                    )}
                  </div>
                )}
              </div>
            )}

            <Tooltip
              title={"All frames complete 👏"}
              position="bottom"
              arrow={true}
              distance={10}
              size="big"
              open={remaining == 0}
            >
              <button
                disabled={remaining > 0 || this.props.isAddingToCart}
                className={classNames(
                  "btn btn-secondary btn-lg px-4 depart-no-save disabled:bg-brand-400 disabled:cursor-not-allowed"
                )}
                onClick={this.props.addToCart}
              >
                <i className="fa-solid fa-cart-plus md:mr-2"></i>
                <span className="hidden md:inline">
                  {this.props.is_edit_mode ? "Update" : "Buy"}
                </span>
                {this.props.isAddingToCart && (
                  <i className="fa-solid fa-spinner fa-spin ml-2"></i>
                )}
              </button>
            </Tooltip>
          </form>
        </div>
      </div>
    );
  }
}

export default GalleryDesignerUI;
