import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { Tooltip } from "react-tippy";
import { isMobile } from "react-device-detect";
import cloudinary from "cloudinary-core";
import { install } from "resize-observer";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import EffectsControls from "components/frame-designer/effects/EffectsControls";

class EffectAndFilters extends React.Component {
  constructor(props) {
    super();
    this.state = {
      effects: props.effects,
      imageLoaded: false,
      loading: false,
      filters: ["ukulele", "audrey", "peacock", "primavera", "sonnet"],
    };
    this.validateAndSend = this.validateAndSend.bind(this);
    this.completeResponse = this.completeResponse.bind(this);
    this.renderURL = this.renderURL.bind(this);
    this.handleImageLoaded = this.handleImageLoaded.bind(this);
    this.effectsUpdate = this.effectsUpdate.bind(this);
    this.skipStep = this.skipStep.bind(this);
  }

  validateAndSend() {
    this.setState({ loading: true });
    $.ajax({
      method: "PATCH",
      url: this.props.crop_image_path,
      data: {
        image: {
          id: this.props.image.id,
          viesus_correct: this.state.effects.viesus_correct,
          filter: this.state.effects.filter,
        },
      },
    }).done(this.completeResponse);
  }

  completeResponse(data) {
    this.props.saveComplete(data);
  }

  renderURL() {
    const { image } = this.props;
    const { effects } = this.state;

    let cl = new cloudinary.Cloudinary({
      cloud_name: "framefox",
      secure: true,
    });
    let crop = image.crop;

    let filter = effects.filter ? "art:" + effects.filter : null;
    let viesus = effects.viesus_correct ? "viesus_correct" : null;

    let preview_crop = {
      width: parseInt((crop.cw * 1000) / image.width),
      height: parseInt((crop.ch * 1000) / image.width),
      x: parseInt((crop.cx * 1000) / image.width),
      y: parseInt((crop.cy * 1000) / image.width),
      crop: "crop",
    };

    let url = cl.url(image.original_url, {
      type: "fetch",
      transformation: [
        { width: image.preview_size, crop: "fit" },
        preview_crop,
        { effect: filter },
        { effect: viesus },
      ],
    });
    return url;
  }

  handleImageLoaded() {
    this.setState({ imageLoaded: true });
  }

  effectsUpdate(effects) {
    this.setState({
      effects: effects,
      imageLoaded: false,
    });
  }

  skipStep(e) {
    e.preventDefault();
    this.completeResponse();
  }

  overlayFilterText() {
    const { effects } = this.state;
    if (effects.filter && effects.viesus_correct) {
      return "Smart Enhance + Filter";
    } else if (effects.filter) {
      return "Filter";
    } else {
      return "Smart Enhance";
    }
  }

  render() {
    const { effects } = this.state;

    return (
      <div className="md:grid md:grid-cols-5 gap-x-12">
        <div className="col-span-3">
          <div className="my-3 relative px-4">
            {effects.filter || effects.viesus_correct ? (
              <ReactCompareSlider
                itemOne={
                  <CompareImage
                    labelText="Original"
                    src={this.props.image.original_cropped}
                    classAdd="left"
                    onLoad={this.handleImageLoaded}
                  />
                }
                itemTwo={
                  <CompareImage
                    labelText={this.overlayFilterText()}
                    src={this.renderURL()}
                    classAdd="right"
                    onLoad={this.handleImageLoaded}
                  />
                }
              />
            ) : (
              <img
                src={this.props.image.original_cropped}
                className="compare-image"
                onLoad={this.handleImageLoaded}
              />
            )}

            {!this.state.imageLoaded && (
              <div className="p-2 bg-white rounded-full absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                <img src={this.props.preloader} className="w-8" />
              </div>
            )}
          </div>
        </div>
        <div className="col-span-2 x-4 text-center md:text-left">
          <h1>Enhance & Filter</h1>

          <div className="mt-6">
            <EffectsControls
              effectsUpdate={this.effectsUpdate}
              url={this.props.image.original_url}
              effects={this.props.image.effects}
              filters={this.state.filters}
              imageLoaded={this.state.imageLoaded}
            />
          </div>
          <button
            disabled={this.state.loading}
            onClick={this.validateAndSend}
            className={classNames(
              "btn btn-primary btn-lg text-center disabled:bg-brand-400 disabled:cursor-not-allowed"
            )}
          >
            Save & Continue
            {this.state.loading ? (
              <i className="fa-solid fa-spinner fa-spin ml-1" />
            ) : (
              <i className="fa-solid fa-arrow-right text-base ml-2" />
            )}
          </button>
          <a
            href="#"
            onClick={this.skipStep}
            className="btn-link btn btn-lg btn-pale ml-3 mt-3 font-weight-bold"
          >
            Skip
          </a>
        </div>
      </div>
    );
  }
}

const CompareImage = ({ src, classAdd, onLoad, labelText }) => {
  return (
    <div>
      <div className={`compare-label ${classAdd}`}>{labelText}</div>
      <img src={src} className="compare-image" onLoad={onLoad} />
    </div>
  );
};

if (typeof window !== "undefined") {
  install();
}

export default EffectAndFilters;
