import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { Tooltip } from 'react-tippy';
import {isMobile} from 'react-device-detect';

class OrderItemNote extends React.Component{
  constructor(props) {
    super();
    this.state = {
      noteBody: props.noteBody ? props.noteBody : '',
      noteBodyPersisted: props.noteBody ? props.noteBody : '',
      focussed: false,
      isLoading: false,
      url: props.url,
      id: props.id
    }
    this.handleSubmit       = this.handleSubmit.bind(this);
    this.completeResponse   = this.completeResponse.bind(this);
    this.changeHandler      = this.changeHandler.bind(this);
    this.textFocus          = this.textFocus.bind(this);
    this.cancelEdit         = this.cancelEdit.bind(this);
  }
  handleSubmit ( e ) {
    e.preventDefault();
    $.ajax({
      method: 'PATCH',
      url: this.state.url,
      data: {  note: this.state.noteBody, order_item_id: this.state.id }
    }).done(this.completeResponse);
  }

  completeResponse(data){
    if (data.success){
      this.setState({
        focussed: false,
        noteBodyPersisted: this.state.noteBody
      })
    } else {
      this.setState({
        focussed: true
      })
    }
  }

  changeHandler(e){
    this.setState({
      noteBody: e.target.value,
    });
  }

  textFocus(e){
    e.preventDefault();
    this.setState({ focussed: true });
  }

  cancelEdit(e){
    e.preventDefault();
    this.setState({ focussed: false, noteBody: this.state.noteBodyPersisted  })
  }

  render() {
    let rows = this.state.focussed ? 3 : 1;
    let hidden = this.state.focussed ? null : 'd-none';
    let disabled = this.state.isLoading ? 'disabled' : null;
    let classes = classNames('btn', 'btn-secondary', 'my-1', disabled, hidden);

    return (
      <form ref="form" className="comment-form" action={ this.state.url } acceptCharset="UTF-8" method="post" onSubmit={ this.handleSubmit }>

          { !this.state.focussed ?
            <div>
              { this.state.noteBody == '' ?
                <Tooltip
                  title={this.props.tooltip}
                  open={true}
                  distance={20}
                  position={isMobile ? "bottom" : "right"}
                  className="warning"
                  arrow={true}
                  disabled={this.state.focussed || this.props.tooltip == null}
                >
                  <a href='#' className='font-weight-bold' onClick={this.textFocus}>
                    <i className='icon-plus small'/> Add special instructions
                  </a>
                </Tooltip>
              : <div onClick={this.textFocus}>
                  <i className='icon-pencil text-brand'></i>
                  {this.state.noteBody}
                </div>
              }
            </div>
            : <textarea className = 'form-control' value={this.state.noteBody} rows={rows} placeholder={"Notes for our production team..."} onChange={this.changeHandler} onFocus={this.textFocus} />
          }
        <button className = {classes} type="submit">Save</button>
         { this.state.focussed ? <button onClick={this.cancelEdit} className = 'btn btn-primary ml-2'>Cancel</button> : null }
      </form>
    )
  }

}




export default OrderItemNote;