import React from "react";
import ReactDOM from "react-dom";
import { fabric } from "fabric";
import classNames from "classnames";
import { useRef, useEffect } from "react";

function render(
  canvas,
  {
    mat_style,
    width_mm,
    height_mm,
    frame_style,
    preview_url,
    maxWidth,
    mat,
    setCanvasAssetsLoaded,
  }
) {
  let frameWidth = frame_style.width;
  let canvasWidth =
    parseInt(width_mm) + frameWidth * 2 + mat_style.left + mat_style.right;
  let canvasHeight =
    parseInt(height_mm) + frameWidth * 2 + mat_style.top + mat_style.bottom;

  const longestEdge = canvasWidth > canvasHeight ? canvasWidth : canvasHeight;
  const ratioOuter = canvasWidth / canvasHeight;
  const ratio = width_mm / height_mm;

  // const maxWidth = maxWidth;
  // const maxHeight = 600;

  const maxW = ratioOuter > 1 ? maxWidth : maxWidth * ratioOuter;
  const maxH = ratioOuter > 1 ? maxWidth / ratioOuter : maxWidth;

  // const roomScale = this.state.scale ? (longestEdge / 1000) * this.state.scale : 1;
  const roomScale = 1;

  if (ratioOuter >= 1) {
    var scaleF = (roomScale * parseInt(maxW * 2)) / canvasWidth;
  } else {
    var scaleF = (roomScale * parseInt(maxH * 2)) / canvasHeight;
  }

  canvasWidth = canvasWidth * scaleF;
  canvasHeight = canvasHeight * scaleF;
  frameWidth = Math.ceil(frameWidth * scaleF);

  let mountAndFrameLeft = mat_style.left * scaleF + frameWidth - 1;
  let mountAndFrameRight = mat_style.right * scaleF + frameWidth - 1;
  let mountAndFrameTop = mat_style.top * scaleF + frameWidth - 1;
  let mountAndFrameBottom = mat_style.bottom * scaleF + frameWidth - 1;

  canvas.enableRetinaScaling = false;

  const base = new fabric.Rect({
    left: 0,
    top: 0,
    width: canvasWidth,
    height: canvasHeight,
    fill: "#fff",
    selectable: false,
  });
  canvas.add(base);

  canvas.setDimensions(
    {
      width: canvasWidth,
      height: canvasHeight,
    },
    { backstoreOnly: true }
  );

  canvas.setDimensions(
    {
      width: canvasWidth / 2 + "px",
      height: canvasHeight / 2 + "px",
    },
    { cssOnly: true }
  );

  const topFramePoints = [
    { x: -1, y: -1 },
    { x: canvasWidth + 1, y: -1 },
    { x: canvasWidth + 1 - frameWidth, y: frameWidth },
    { x: frameWidth, y: frameWidth },
  ];

  const sideFramePoints = [
    { x: -1, y: -1 },
    { x: canvasHeight + 1, y: -1 },
    { x: canvasHeight + 1 - frameWidth, y: frameWidth },
    { x: frameWidth, y: frameWidth },
  ];

  const mountClipPoints = [
    { x: mountAndFrameLeft, y: mountAndFrameTop },
    {
      x: canvasWidth - mountAndFrameRight,
      y: mountAndFrameTop,
    },
    {
      x: canvasWidth - mountAndFrameRight,
      y: canvasHeight - mountAndFrameBottom,
    },
    {
      x: mountAndFrameLeft,
      y: canvasHeight - mountAndFrameBottom,
    },
  ];

  let rShadowStyle;
  let lShadowStyle;
  let tShadowStyle;
  let bShadowStyle;

  if (frame_style.frame_type == "canvas") {
    rShadowStyle = {
      color: "#37302c",
      blur: 2,
      offsetX: -4 * scaleF,
      offsetY: 0,
    };
    lShadowStyle = {
      color: "#37302c",
      blur: 2,
      offsetX: 4 * scaleF,
      offsetY: 0,
    };
    tShadowStyle = {
      color: "#37302c",
      blur: 2,
      offsetX: 0,
      offsetY: 4 * scaleF,
    };
    bShadowStyle = {
      color: "#37302c",
      blur: 2,
      offsetX: 0,
      offsetY: -4 * scaleF,
    };
  } else {
    rShadowStyle = {
      color: "rgba(0,0,0,0.3)",
      blur: 6 * scaleF,
      offsetX: -2,
      offsetY: 0,
    };
    lShadowStyle = {
      color: "rgba(0,0,0,0.3)",
      blur: 8 * scaleF,
      offsetX: 0,
      offsetY: 0,
    };
    tShadowStyle = {
      color: "rgba(0,0,0,0.4)",
      blur: 6 * scaleF,
      offsetX: 0,
      offsetY: 2,
    };
    bShadowStyle = {
      color: "rgba(0,0,0,0.3)",
      blur: 8 * scaleF,
      offsetX: 0,
      offsetY: 0,
    };
  }

  const mountFront = new fabric.Rect({
    left: 0,
    top: 0,
    width: canvasWidth,
    height: canvasHeight,
    fill: mat.colour,
  });

  const mountClipper = new fabric.Polygon(mountClipPoints, {
    fill: mat.colour,
    absolutePositioned: true,
  });
  mountClipper.inverted = true;
  mountFront.clipPath = mountClipper;

  const topFrame = new fabric.Polygon(topFramePoints, {
    fill: "white",
    selectable: false,
  });

  const rightFrame = new fabric.Polygon(sideFramePoints, {
    left: canvasWidth + 1,
    fill: "white",
    angle: 90,
    selectable: false,
  });

  const bottomFrame = fabric.util.object.clone(topFrame);
  bottomFrame.set("angle", 180);
  bottomFrame.set("top", canvasHeight + 1);
  bottomFrame.set("left", canvasWidth + 1);

  const leftFrame = fabric.util.object.clone(rightFrame);
  leftFrame.set("angle", 270);
  leftFrame.set("left", -1);
  leftFrame.set("top", canvasHeight + 1);

  const tShadow = fabric.util.object.clone(topFrame);
  const bShadow = fabric.util.object.clone(bottomFrame);
  const lShadow = fabric.util.object.clone(leftFrame);
  const rShadow = fabric.util.object.clone(rightFrame);

  tShadow.set("top", -2);
  lShadow.set("left", -2);
  rShadow.set("left", canvasWidth + 2);
  bShadow.set("top", canvasHeight + 2);

  // tShadow.set({ fill: "#fff" });
  // bShadow.set({ fill: "#fff" });
  // lShadow.set({ fill: "#fff" });
  // rShadow.set({ fill: "#fff" });

  const patternURL = frame_style.pattern_url;
  const fw = frameWidth;

  fabric.Image.fromURL(
    preview_url,
    function (userImage) {
      let artworkWidth = width_mm * scaleF;
      let artworkHeight = height_mm * scaleF;

      const imageRatio = userImage.width / userImage.height;

      if (imageRatio <= ratio) {
        userImage.scaleToWidth(artworkWidth);
      } else {
        userImage.scaleToHeight(artworkHeight);
      }

      userImage.selectable = false;

      userImage.set("originX", "center");
      userImage.set("originY", "center");

      userImage.set("top", canvasHeight / 2);
      userImage.set("left", canvasWidth / 2);

      canvas.add(userImage);
      canvas.add(mountFront);

      if (mat_style.border == "mat_border") {
        const mountBevel = new fabric.Polygon(mountClipPoints, {
          stroke: mat.core_colour,
          strokeWidth: 2 * scaleF,
          strokeLineJoin: "bevil",
          fill: "rgba(0,0,0,0)",
        });
        canvas.add(mountBevel);
      }

      canvas.add(tShadow);
      canvas.add(bShadow);
      canvas.add(lShadow);
      canvas.add(rShadow);

      fabric.Image.fromURL(
        patternURL,
        function (img) {
          const borderScale = window.devicePixelRatio > 1.5 ? 2 : 1;
          img.scaleToHeight(fw / borderScale);

          const patternSourceCanvas = new fabric.StaticCanvas();
          patternSourceCanvas.add(img);
          patternSourceCanvas.renderAll();
          const pattern = new fabric.Pattern({
            source: function () {
              patternSourceCanvas.setDimensions({
                width: img.getScaledWidth(),
                height: img.getScaledHeight(),
              });
              patternSourceCanvas.renderAll();
              return patternSourceCanvas.getElement();
            },
            repeat: "repeat-x",
          });

          topFrame.set({ fill: pattern });
          bottomFrame.set({ fill: pattern });
          leftFrame.set({ fill: pattern });
          rightFrame.set({ fill: pattern });

          canvas.add(bottomFrame);
          canvas.add(leftFrame);
          canvas.add(rightFrame);
          canvas.add(topFrame);

          tShadow.setShadow(tShadowStyle);
          bShadow.setShadow(bShadowStyle);
          lShadow.setShadow(lShadowStyle);
          rShadow.setShadow(rShadowStyle);

          setCanvasAssetsLoaded();
        }.bind(this),
        {
          crossOrigin: "Anonymous",
        }
      );
    }.bind(this),
    {
      crossOrigin: "Anonymous",
    }
  );
}

const FrameCanvasNew = (props) => {
  const canvasEl = useRef(null);

  useEffect(() => {
    const canvas = new fabric.StaticCanvas(canvasEl.current);

    render(canvas, { ...props });
  });

  const displayClasses =
    props.width_mm > props.height_mm ? "!w-full !h-auto" : "!w-auto !h-full";

  return (
    <canvas
      id="canvasFrame"
      className={classNames(
        "big-frame-shadow absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2",
        displayClasses
      )}
      ref={canvasEl}
    />
  );
};

export default FrameCanvasNew;
