import { useState } from "react";
import { Tab } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class FramePriceEstimates extends React.Component {
  constructor(props) {
    super();
    this.state = {
      selectedTab: 0,
    };
  }

  handleImageLoaded() {}

  render() {
    const { selectedTab } = this.state;
    const { print_skus, frame_skus } = this.props;
    length;
    return (
      <div className="w-full max-w-md px-2 py-16 sm:px-0">
        <Tab.Group>
          <Tab.List className="flex space-x-1 rounded-xl bg-brand-900/20 p-1">
            <Tab
              className={classNames(
                "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-brand-700",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-brand-400 focus:outline-none focus:ring-2",
                selectedTab == 0
                  ? "bg-white shadow"
                  : "text-brand-100 hover:bg-white/[0.12] hover:text-white"
              )}
            >
              Print & Frame
            </Tab>
            <Tab
              className={classNames(
                "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-brand-700",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-brand-400 focus:outline-none focus:ring-2",
                selectedTab == 1
                  ? "bg-white shadow"
                  : "text-brand-100 hover:bg-white/[0.12] hover:text-white"
              )}
            >
              Frame Only
            </Tab>
          </Tab.List>
          <Tab.Panels className="mt-2">
            <Tab.Panel
              className={classNames(
                "rounded-xl bg-white p-3",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-brand-400 focus:outline-none focus:ring-2"
              )}
            >
              <ul>
                {print_skus.map((sku) => (
                  <div key={sku.id} className="table-row">
                    <div className="table-cell">{sku.description}</div>
                    <div className="table-cell">{sku.price}</div>
                  </div>
                ))}
              </ul>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    );
  }
}

export default FramePriceEstimates;
