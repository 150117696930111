import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import ArtworkUnit from "components/frame-designer/measure/ArtworkUnit";

const MeasureContainer = (props) => {
  return (
    <MeasureUI
      standalone={true}
      image_id={props.image_id}
      paper_type_id={props.paper_type_id}
      presets={props.presets}
      next_path={props.next_path}
      max_artwork_long_mm={props.max_artwork_long_mm}
      max_artwork_short_mm={props.max_artwork_short_mm}
    />
  );
};

const unit_options = [
  { title: "cm", id: 1 },
  { title: "mm", id: 0 },
  { title: "in", id: 2 },
];

class MeasureUI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: "",
      height: "",
      width_mm: "",
      height_mm: "",
      unit: unit_options[0],
      unit_options: unit_options,
      max_w_or_h: 1020,
      min_w_or_h: 100,
      valid: true,
      presetsOpen: false,
      selectedPreset: null,
    };
    this.setWidth = this.setWidth.bind(this);
    this.setHeight = this.setHeight.bind(this);
    this.changeUnit = this.changeUnit.bind(this);
    this.saveValues = this.saveValues.bind(this);
    this.savePreset = this.savePreset.bind(this);
    this.validateAndSend = this.validateAndSend.bind(this);
    this.validate = this.validate.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.flipDimensions = this.flipDimensions.bind(this);
    this.togglePresets = this.togglePresets.bind(this);
    this.renderSizeButton = this.renderSizeButton.bind(this);
  }

  setWidth(e) {
    this.setState(
      { width: e.target.value },
      function () {
        this.updateDimensions();
      }.bind(this)
    );
  }

  setHeight(e) {
    this.setState(
      { height: e.target.value },
      function () {
        this.updateDimensions();
      }.bind(this)
    );
  }

  changeUnit(value) {
    this.setState(
      { unit: value },
      function () {
        this.updateDimensions();
      }.bind(this)
    );
  }

  saveValues(params) {
    this.setState(
      params,
      function () {
        this.updateDimensions();
        $(document).trigger("form:focusall", this.refs.form);
      }.bind(this)
    );
  }

  savePreset(preset) {
    console.log(preset);
    this.setState(
      {
        selectedPreset: preset,
        width: preset.width,
        height: preset.height,
        unit: this.state.unit_options.find((obj) => obj.title === preset.unit),
      },
      function () {
        this.updateDimensions();
        $(document).trigger("form:focusall", this.refs.form);
      }.bind(this)
    );
  }

  validateAndSend(e) {
    e.preventDefault();
    if (this.validate()) {
      var params = {
        width: this.state.width,
        height: this.state.height,
        unit: this.state.unit.title,
        image_id: this.props.image_id,
        paper_type_id: this.props.paper_type_id,
      };
      var url = this.props.next_path + "?" + jQuery.param(params);
      window.location.href = url;
    }
  }

  validate() {
    const { width_mm, height_mm } = this.state;
    const { max_artwork_long_mm, max_artwork_short_mm } = this.props;

    if (!this.state.width_mm || !this.state.height_mm) {
      this.setState({
        valid: false,
        message: "Have you entered a number for width and height?",
      });
      return false;
    } else if (
      Math.max(width_mm, height_mm) > max_artwork_long_mm ||
      Math.min(width_mm, height_mm) > max_artwork_short_mm
    ) {
      this.setState({
        valid: false,
        message: `Total size must be no greater than ${max_artwork_long_mm}x${max_artwork_short_mm}mm.`,
      });
      return false;
    } else if (
      width_mm < this.state.min_w_or_h ||
      height_mm < this.state.min_w_or_h
    ) {
      this.setState({
        valid: false,
        message: 'Each length must be no less than 10cm, 100mm or 4"',
      });
      return false;
    } else {
      this.setState({
        valid: true,
      });
      return true;
    }
  }

  updateDimensions() {
    var width = this.state.width;
    var height = this.state.height;
    var wmm = width;
    var hmm = height;
    if (this.state.unit.title == "cm") {
      wmm = width * 10;
      hmm = height * 10;
    } else if (this.state.unit.title == "in") {
      wmm = width * 25.4;
      hmm = height * 25.4;
    }
    this.setState(
      {
        width: width,
        height: height,
        width_mm: parseInt(wmm),
        height_mm: parseInt(hmm),
      },
      function () {
        if (this.state.valid == false) {
          this.validate();
        }
      }.bind(this)
    );
  }

  flipDimensions(e) {
    e.preventDefault();
    this.setState({
      width: this.state.height,
      height: this.state.width,
      width_mm: this.state.height_mm,
      height_mm: this.state.width_mm,
    });
  }

  togglePresets(e) {
    e.preventDefault();
    var active = !this.state.presetsOpen;
    this.setState({ presetsOpen: active });
  }

  renderSizeButton() {
    return (
      <a
        href="#"
        onClick={this.togglePresets}
        className="p-1 font-weight-bold text-brand-600"
      >
        Show Popular sizes <i className="fa-solid fa-caret-down"></i>
      </a>
    );
  }

  render() {
    var disabled = this.state.valid ? null : "disabled";
    var classes = classNames(
      "btn btn-primary btn-lg block md:inline-block",
      disabled
    );

    var validation = this.state.valid ? (
      <a href="#" onClick={this.validateAndSend} className={classes}>
        Next <i className="fa-solid fa-caret-right text-xs ml-2" />
      </a>
    ) : (
      <div className="text-red-800 bg-red-100 mb-2 p-5 rounded">
        {this.state.message}
      </div>
    );

    var reversable =
      this.state.width_mm && this.state.height_mm ? null : "disabled";

    var presets = this.state.presetsOpen ? (
      <SizePresets
        presets={this.props.presets}
        savePreset={this.savePreset}
        selectedPreset={this.state.selectedPreset}
      />
    ) : null;
    return (
      <div className="mx-3 floating-fields large-floating" ref="form">
        <p className="text-xxs md:text-xs mini-heading mb-6">
          {this.props.standalone && "Enter your measurements or"}
          {this.renderSizeButton()}
        </p>
        <div className="mb-3">{presets}</div>
        <div className="grid grid-cols-12">
          <div className="col-span-7">
            <div className="grid grid-cols-12 px-3 gap-x-2">
              <div className="col-span-5">
                <div className="form-group text-left m-0">
                  <label className="control-label" htmlFor="width">
                    Width
                  </label>
                  <input
                    className="form-control border-solid border border-stone-200"
                    onChange={this.setWidth}
                    value={this.state.width}
                    ref="width"
                    id="width"
                  />
                </div>
              </div>
              <div className="col-span-2 text-center">
                <a
                  href="#"
                  className={classNames(
                    reversable,
                    "p-2",
                    "btn",
                    "bg-stone-100",
                    "mt-3"
                  )}
                  onClick={this.flipDimensions}
                >
                  <i className="fa-solid fa-arrow-up-arrow-down fa-rotate-90"></i>
                </a>
              </div>
              <div className="col-span-5">
                <div className="form-group text-left m-0">
                  <label className="control-label" htmlFor="height">
                    Height
                  </label>
                  <input
                    className="form-control border-solid border border-stone-200"
                    onChange={this.setHeight}
                    value={this.state.height}
                    ref="height"
                    id="height"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-5">
            <div className="form-group">
              <ArtworkUnit
                saveValues={this.changeUnit}
                options={this.state.unit_options}
                defaultChecked={this.state.unit}
              />
            </div>
          </div>
        </div>

        <div className="text-center mt-3">{validation}</div>
      </div>
    );
  }
}

class SizePresets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      a_sizes: props.presets.a_sizes,
      metric_sizes: props.presets.metric_sizes,
      inches: props.presets.inches,
    };
    this.onSelect = this.onSelect.bind(this);
  }
  onSelect(radio) {
    this.props.savePreset(radio);
  }
  render() {
    return (
      <div className="my-3">
        <ArtworkUnit
          saveValues={this.onSelect}
          options={this.state.a_sizes}
          defaultChecked={this.props.selectedPreset}
        />
        <ArtworkUnit
          saveValues={this.onSelect}
          options={this.state.inches}
          defaultChecked={this.props.selectedPreset}
        />
      </div>
    );
  }
}

export default MeasureContainer;
