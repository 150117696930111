import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import FrameCanvas from "components/frame-designer/designers/FrameCanvas";

class FrameGallery extends React.Component {
  constructor(props) {
    super();
    this.state = {
      room_scale: props.preview_mode.room_scale,
      show_frame: true,
      ratio: props.ratio,
      maxContainerWidth: 800,
      maxContainerHeight: 800,
    };
    this.updateFrame = this.updateFrame.bind(this);
  }

  componentDidMount() {
    let maxContainerWidth = this.refs.container.offsetWidth;
    let maxContainerHeight = this.refs.container.offsetWidth;

    this.setState(
      {
        maxContainerWidth: maxContainerWidth,
        maxContainerHeight: maxContainerHeight,
        show_frame: true,
      },
      () => this.updateFrame()
    );
  }

  updateFrame() {
    if (this.props.preview_mode.top && this.props.preview_mode.left) {
      let leftMargin =
        this.state.maxContainerWidth * this.props.preview_mode.left;
      let topMargin =
        this.state.maxContainerHeight * this.props.preview_mode.top * -1;

      this.setState({
        canvasStyle: {
          marginLeft: leftMargin,
          marginTop: topMargin,
        },
      });
    }
  }

  render() {
    if (this.props.preview_mode.background) {
      var containerStyle = {
        backgroundImage: `url(${this.props.preview_mode.background})`,
        backgroundPosition: "0,0",
      };
    } else {
      if (this.state.ratio > 1) {
        var nh = this.state.maxContainerWidth / this.state.ratio;
      } else {
        var nh = this.state.maxContainerHeight;
      }

      var h =
        nh < this.state.maxContainerHeight ? nh : this.state.maxContainerHeight;

      var containerStyle = {
        backgroundImage: "none",
        height: h + "px",
      };
    }

    return (
      <div className={this.props.preview_mode.background && "aspect-1"}>
        <div
          className={classNames(
            this.props.preview_mode.background &&
              "bg-cover absolute top-0 left-0 w-full h-full pointer-events-none"
          )}
          style={containerStyle}
          ref="container"
        >
          <div
            id="frameGallery"
            ref="frameGallery"
            style={this.state.canvasStyle}
          >
            <FrameCanvas
              useNonCanvasImage={false}
              previewCallback={this.props.previewCallback}
              artworkImageOnload={this.artworkImageOnload}
              scale={this.state.room_scale}
              width_mm={this.props.width_mm}
              height_mm={this.props.height_mm}
              maxContainerWidth={this.state.maxContainerWidth}
              maxContainerHeight={this.state.maxContainerHeight}
              image={this.props.image}
              preview_url={this.props.image.url}
              mat_style={this.props.mat_style}
              mat={this.props.mat}
              frame_style={this.props.frame_style}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default FrameGallery;
