import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import ArtworkUnit from "components/frame-designer/measure/ArtworkUnit";

const unit_options = [
  { title: "cm", id: 1 },
  { title: "mm", id: 0 },
  { title: "in", id: 2 },
];

class CustomSize extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: "",
      height: "",
      width_mm: "",
      height_mm: "",
      unit: unit_options[0],
      unit_options: unit_options,
      max_w_or_h: 1500,
      min_w_or_h: 100,
      valid: true,
      presetsOpen: false,
    };
    this.setWidth = this.setWidth.bind(this);
    this.setHeight = this.setHeight.bind(this);
    this.changeUnit = this.changeUnit.bind(this);
    this.saveValues = this.saveValues.bind(this);
    this.validateAndSend = this.validateAndSend.bind(this);
    this.validate = this.validate.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.flipDimensions = this.flipDimensions.bind(this);
    this.togglePresets = this.togglePresets.bind(this);
  }

  setWidth(e) {
    this.setState(
      { width: e.target.value },
      function () {
        this.updateDimensions();
      }.bind(this)
    );
  }

  setHeight(e) {
    this.setState(
      { height: e.target.value },
      function () {
        this.updateDimensions();
      }.bind(this)
    );
  }

  changeUnit(value) {
    this.setState(
      { unit: value },
      function () {
        this.updateDimensions();
      }.bind(this)
    );
  }

  saveValues(params) {
    this.setState(
      params,
      function () {
        this.updateDimensions();
        $(document).trigger("form:focusall", this.refs.form);
      }.bind(this)
    );
  }

  validateAndSend(e) {
    e.preventDefault();
    if (this.validate()) {
      var params = {
        width: this.state.width,
        height: this.state.height,
        unit: this.state.unit.title,
        image_id: this.props.image_id,
        paper_type_id: this.props.paper_type_id,
      };
      var url = this.props.next_path + "?" + jQuery.param(params);
      window.location.href = url;
    }
  }

  validate() {
    if (!this.state.width_mm || !this.state.height_mm) {
      this.setState({
        valid: false,
        message: "Have you entered a number for width and height?",
      });
      return false;
    } else if (
      this.state.width_mm > this.state.max_w_or_h ||
      this.state.height_mm > this.state.max_w_or_h
    ) {
      this.setState({
        valid: false,
        message:
          "Each length must be no greater than 150cm. Please contact us for quotes at larger sizes.",
      });
      return false;
    } else if (
      this.state.width_mm < this.state.min_w_or_h ||
      this.state.height_mm < this.state.min_w_or_h
    ) {
      this.setState({
        valid: false,
        message: 'Each length must be no less than 10cm, 100mm or 4"',
      });
      return false;
    } else {
      this.setState({
        valid: true,
      });
      return true;
    }
  }

  updateDimensions() {
    var width = this.state.width;
    var height = this.state.height;
    var wmm = width;
    var hmm = height;
    if (this.state.unit.title == "cm") {
      wmm = width * 10;
      hmm = height * 10;
    } else if (this.state.unit.title == "in") {
      wmm = width * 25.4;
      hmm = height * 25.4;
    }
    this.setState(
      {
        width: width,
        height: height,
        width_mm: parseInt(wmm),
        height_mm: parseInt(hmm),
      },
      function () {
        if (this.state.valid == false) {
          this.validate();
        }
      }.bind(this)
    );
  }

  flipDimensions(e) {
    e.preventDefault();
    this.setState({
      width: this.state.height,
      height: this.state.width,
      width_mm: this.state.height_mm,
      height_mm: this.state.width_mm,
    });
  }

  togglePresets(e) {
    e.preventDefault();
    var active = !this.state.presetsOpen;
    this.setState({ presetsOpen: active });
  }

  render() {
    var disabled = this.state.valid ? null : "disabled";
    var classes = classNames(
      "btn",
      "btn-primary",
      "btn-lg",
      "btn-block",
      disabled
    );

    var validation = this.state.valid ? (
      <a href="#" onClick={this.validateAndSend} className={classes}>
        Next <i className="fa-solid fa-caret-right text-xs ml-2"></i>
      </a>
    ) : (
      <div className="shaka shadow-box text-danger mb-2">
        {this.state.message}
      </div>
    );

    var reversable =
      this.state.width_mm && this.state.height_mm ? null : "disabled";

    return (
      <div className="mx-3 floating-fields large-floating" ref="form">
        <p className="mini-heading">Enter your measurements</p>

        <div className="grid grid-cols-12">
          <div className="col-span-7">
            <div className="grid grid-cols-12 px-3 gap-x-2">
              <div className="col-span-5">
                <div className="form-group text-left m-0">
                  <label className="control-label" htmlFor="width">
                    Width
                  </label>
                  <input
                    className="form-control border-solid border border-stone-200"
                    onChange={this.setWidth}
                    value={this.state.width}
                    ref="width"
                    id="width"
                  />
                </div>
              </div>
              <div className="col-span-2 text-center">
                <a
                  href="#"
                  className={classNames(
                    reversable,
                    "p-2",
                    "btn",
                    "bg-stone-100",
                    "mt-3"
                  )}
                  onClick={this.flipDimensions}
                >
                  <i className="fa-solid fa-arrow-up-arrow-down fa-rotate-90"></i>
                </a>
              </div>
              <div className="col-span-5">
                <div className="form-group text-left m-0">
                  <label className="control-label" htmlFor="height">
                    Height
                  </label>
                  <input
                    className="form-control border-solid border border-stone-200"
                    onChange={this.setHeight}
                    value={this.state.height}
                    ref="height"
                    id="height"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-5">
            <div className="form-group">
              <ArtworkUnit
                saveValues={this.changeUnit}
                options={this.state.unit_options}
                defaultChecked={this.state.unit}
              />
            </div>
          </div>
        </div>

        <div className="text-center mt-3">{validation}</div>
      </div>
    );
  }
}

export default CustomSize;
