const DISABLE_WITH = "Processing...";

const ERRORS = {
  fatal: `Something went wrong`,
  stripe_key_missing: `Expected to find \`<meta name="stripe-key"/>\` containing Stripe publishable key.`,
  return_url_missing: `Missing data attribute \`[data-return-url]\`.`,
};

const STYLE = {
  variables: {
    fontSizeBase: "14px",
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,\n          Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
    fontLineHeight: "1.5",
  },
  rules: {
    ".Tab": {
      border: "none",
      borderRadius: "6px",
      padding: "10px 12px 8px 12px",
      color: "#727F96",
      boxShadow: "0 0 0 1px #e0e0e0",
      backgroundColor: "#ffffff",
      transition: "box-shadow 0.08s ease-in, border 0.08s ease-in",
    },
    ".Tab:focus": {
      outline: "none",
      boxShadow:
        "0 0 0 1px rgba(50, 151, 211, .3), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 4px rgba(50, 151, 211, 0.3)",
    },
    ".Tab:hover": {
      border: "none",
      color: "#727F96",
      boxShadow:
        "0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07), 0 1px 1.5px 0 rgba(0, 0, 0, 0.05)",
    },
    ".Tab--selected, .Tab--selected:hover": {
      border: "none",
      boxShadow:
        "0 0 0 1.5px #0570DE, 0 2px 4px 0 rgba(0, 0, 0, 0.07), 0 1px 1.5px 0 rgba(0, 0, 0, 0.05)",
    },
    ".Tab--selected:focus": {
      boxShadow:
        "0 0 0 1.5px #0570DE, 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 4px rgba(50, 151, 211, 0.3)",
    },
    ".TabIcon--selected": {
      fill: "#0570DE",
    },
    ".TabLabel--selected": {
      color: "#0570DE",
    },
    ".Input": {
      border: "none",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "1.5",
      padding: "12px",
      borderRadius: "6px",
      boxShadow:
        "0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07), 0 1px 1.5px 0 rgba(0, 0, 0, 0.05)",
    },
    ".Input:focus": {
      outline: "none",
      boxShadow:
        "0 0 0 1px rgba(50, 151, 211, .3), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 4px rgba(50, 151, 211, 0.3)",
    },
    ".Input::placeholder": {
      color: "#A3ACB9",
    },
    ".Label": {
      color: "#4F5B76",
      fontWeight: "500",
      fontSize: "14px",
    },
    ".Text": {
      color: "#4F5B76",
      fontWeight: "500",
    },
    ".Text--redirect": {
      paddingTop: "24px",
      color: "#0570DE",
    },
  },
};

const PROCESSING_OVERLAY = `
<div class="fixed top-0 left-0 w-full h-full z-50 bg-stone-900 opacity-70 hidden">
  <div class="top-1/2 left-1/2 absolute -translate-x-1/2 -translate-y-1/2" >
    <i class="fa-solid fa-spinner-third fa-spin ml-2 text-5xl text-white"></i>
  </div>
</div>`;

export { DISABLE_WITH, ERRORS, STYLE, PROCESSING_OVERLAY };
