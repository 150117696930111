import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import moment from 'moment'

class GSSEvents extends React.Component{ 
  constructor(props){
    super(props);
    this.state = {
      isLoading: true,
      url:        'https://api.gosweetspot.com/api/shipments',
      order_key:  props.order_key,
      consignments: []
    }
    this.completeResponse  = this.completeResponse.bind(this);
  }

  componentDidMount(i){

    $.ajax({
      method: 'GET',
      url: this.state.url,
      headers: {
          access_key: this.props.gss_key,
          "content-type": "application/json",
          "site_id": '2352600'
      },
      data: { ordernumbers: this.state.order_key }
    }).done(this.completeResponse);

  }

  completeResponse(data){
    this.setState({
        consignments: data.Results,
        isLoading: false
      })
  }

  render () {
    return (
      <div className="mt-2">
        {this.state.isLoading ? 'Loading' :
          <div>
            {this.state.consignments.length == 0 ? 'No events yet' :
              <div>
                {this.state.consignments.map(function(consignment, i){
                  return <GSSEvent c={consignment} key={i}/>
                }.bind(this))}
              </div>
            }
          </div>
        }
      </div>
    )
  }
}

const GSSEvent = (props) => {
  return (
    <li className="list-group-item d-block">
      <div><a href={props.c.Tracking} target='blank'>{props.c.ConsignmentNo}</a> - {props.c.Status}</div>
      <div className="small text-muted">
        ${props.c.TotalCost} · Created {moment(props.c.CreatedUtc).format('MMM Do, h:mm a')}
      </div>
      <div className='row'>
        { props.c.Picked ?
          <div className='col-sm-12 mt-3'>
            <div className='mini-heading'>Picked up</div> {moment(props.c.Picked).format('MMM Do, h:mm a')}
          </div>
        : null }
        { props.c.Delivered ?
          <div className='col-sm-12 mt-3'>
            <div className='mini-heading'>Delivered</div> {moment(props.c.Delivered).format('MMM Do, h:mm a')}
          </div>
        : null }

      </div>
    </li>
  )
}


export default GSSEvents;