import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import QRCode from "react-qr-code";
import Modal from "react-responsive-modal";
import { isMobile } from "react-device-detect";

class ViewInSpace extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    return (
      <>
        {!isMobile && (
          <>
            <button
              className="btn btn-sm btn-white"
              onClick={(e) => this.setState({ modalIsOpen: true })}
            >
              <i className="fa-solid fa-cube mr-2"></i> View dimensions in your
              space
            </button>
            <Modal
              open={this.state.modalIsOpen}
              onClose={(e) => this.setState({ modalIsOpen: false })}
              classNames={{
                overlay: "custom-overlay",
                modal: "custom-modal product-type-popup",
              }}
            >
              <div className="p-8 text-center max-w-sm ">
                <h2>Scan to view in AR</h2>
                <span>(Scale reference only)</span>

                <div className="mt-8 w-48 mx-auto">
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={`https://size.link?l=${this.props.width_cm}&w=3&h=${this.props.height_cm}&units=cm`}
                    viewBox={`0 0 256 256`}
                  />
                </div>
                <div className="text-lg font-bold mt-5">
                  {this.props.width_cm}cm x {this.props.height_cm}cm
                </div>
                <p className="mt-5">
                  Point your mobile device camera at the QR code to preview
                  these dimensions on your own wall in augmented reality.
                </p>
              </div>
            </Modal>
          </>
        )}
      </>
    );
  }
}

export default ViewInSpace;
