import React from "react";
import classNames from "classnames";

const AddToCartButton = (props) => (
  <button
    disabled={props.disabled}
    onClick={props.onClickEvent}
    className={classNames(
      "btn btn-primary block w-full text-lg btn-lg pt-2 pb-3 text-center disabled:bg-brand-400 disabled:cursor-not-allowed"
    )}
  >
    <span className="font-bold text-xs font-sans relative -top-1 mr-0.5">
      {global.App.currencySymbol}
    </span>
    {props.price} – {props.buttonText}
    {props.isLoading && <i className="fa-solid fa-spinner fa-spin ml-2"></i>}
    {props.wholesale ? (
      <div className="text-xs text-brand-300 mt-0.5">
        {global.App.currencySymbol}
        {Math.round((props.price / props.tax_rate) * 100) / 100} excl{" "}
        {global.App.taxName}
        <span className="badge badge-pale ml-2">Pro Price</span>
      </div>
    ) : (
      <div className="text-xs text-brand-300  mt-0.5">
        Or 4 payments of{" "}
        {window.App.currencySymbol + (props.price / 4).toFixed(2)} with
        <img src={props.afterpayLogo} className="inline w-14 ml-1 opacity-50" />
      </div>
    )}
  </button>
);

export default AddToCartButton;
