import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { Widget } from "@uploadcare/react-widget";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import Toggle from "react-toggle";
import { Tooltip } from "react-tippy";
import { arrayMoveImmutable as arrayMove } from "array-move";
import { fabric } from "fabric";
import AfterpayBreakdown from "components/afterpay/AfterpayBreakdown";
import ShippingEstimate from "components/cart/ShippingEstimate";
import CurrencyDisplay from "components/helpers/CurrencyDisplay";
import MultiPhotoCanvas from "components/multi-photo/MultiPhotoCanvas";
import FrameStyleSelector from "components/frame-designer/designers/FrameStyleSelector";
import AddToCartButton from "components/frame-designer/designers/AddToCartButton";

class MultiPhotoDesigner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image_windows: props.image_windows,
      images: new Array(props.image_windows.length),
      frame_style: props.frame_style,
      viesus_correct: true,
      addingToCart: false,
      frame_style: props.frame_styles.find(
        (obj) => obj.id === props.frame_style_id
      ),
    };
    this.onSortEnd = this.onSortEnd.bind(this);
    this.newPhoto = this.newPhoto.bind(this);
    this.onClear = this.onClear.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.saveImageToDatabase = this.saveImageToDatabase.bind(this);
    this.imageLoaded = this.imageLoaded.bind(this);
    this.updateFrameStyle = this.updateFrameStyle.bind(this);
    this.toggleViesus = this.toggleViesus.bind(this);
    this.newEffects = this.newEffects.bind(this);
  }

  componentDidMount() {
    this.setState({ maxWidth: this.refs.container.offsetWidth });
  }

  toggleViesus() {
    this.setState({ viesus_correct: !this.state.viesus_correct }, () =>
      this.newEffects()
    );
  }

  newEffects() {
    const { images, viesus_correct } = this.state;

    if (images.filter((x) => x).length > 0) {
      this.setState({ imageLoading: true });

      $.ajax({
        method: "PUT",
        url: this.props.toggle_viesus_url,
        data: {
          image_ids: images.filter((x) => x).map((x) => x.id),
          viesus_correct: viesus_correct,
        },
      }).done(
        function (data, textStatus, jqXHR) {
          data.images.map((image, index) =>
            this.imageSaveSuccess(image, index)
          );
        }.bind(this)
      );
    }
  }

  newPhoto(index) {
    return (info) => this.saveImageToDatabase(info, index);
  }

  onClear(index) {
    return () => {
      const { images } = this.state;
      let newImages = [...images];

      newImages[index] = null;
      this.setState({ images: newImages });
    };
  }

  saveImageToDatabase(fileInfo, index) {
    let newImage = {
      width: fileInfo.originalImageInfo.width,
      height: fileInfo.originalImageInfo.height,
      filename: fileInfo.name,
      external_id: fileInfo.uuid,
      host: "uploadcare",
      path: fileInfo.uuid + "/file_" + fileInfo.uuid,
      source: fileInfo.sourceInfo.source,
      url: fileInfo.originalUrl,
      filesize: fileInfo.size,
      format: fileInfo.originalImageInfo.format,
      art_type_id: this.props.art_type_id,
      cx: fileInfo.crop.left,
      cy: fileInfo.crop.top,
      cw: fileInfo.crop.width,
      ch: fileInfo.crop.height,
      viesus_correct: this.state.viesus_correct,
    };

    $.ajax({
      method: "PUT",
      url: this.props.post_image_url,
      data: {
        image: newImage,
      },
    }).done(
      function (data, textStatus, jqXHR) {
        this.imageSaveSuccess(data, index);
      }.bind(this)
    );
  }

  imageSaveSuccess({ image }, index) {
    const { images, image_windows } = this.state;
    let newImages = [...images];

    newImages[image_windows[index].position - 1] = image;
    this.setState({ images: newImages, imageLoading: image.id });
  }

  onSortEnd({ oldIndex, newIndex }) {
    const { image_windows, images } = this.state;

    const newWins = arrayMove(image_windows, oldIndex, newIndex);
    const newImages = arrayMove(images, oldIndex, newIndex);

    this.setState({ image_windows: newWins, images: newImages });
  }

  addToCart() {
    const { image_windows, images } = this.state;
    const { post_to_cart_url } = this.props;

    this.setState({ addingToCart: true });

    let image_window_purchases_attributes = images.map((item, index) => {
      return {
        image_id: item.id,
        image_window_id: image_windows.find(
          (item) => item.position - 1 == index
        ).id,
      };
    });

    $.ajax({
      method: "POST",
      url: post_to_cart_url,
      data: {
        template_purchase: {
          template_variant_id: this.props.template_variants.find(
            (item) => item.frame_style_colour_id == this.state.frame_style.id
          ).id,
          glass_type_id: this.props.glass_type.id,
          template_id: this.props.id,
          image_window_purchases_attributes: image_window_purchases_attributes,
        },
      },
    }).done(
      function (data) {
        window.location.href = data.redirect_to;
      }.bind(this)
    );
  }

  imageLoaded(id) {
    if (this.state.imageLoading == id) {
      console.log("finished loading: " + id);
      this.setState({ imageLoading: null });
    }
  }

  updateFrameStyle(style) {
    this.setState({ frame_style: style.frame_style });
  }

  render() {
    const {
      image_windows,
      images,
      imageLoading,
      frame_style,
      maxWidth,
      viesus_correct,
    } = this.state;
    const { prices } = this.props;

    let actualImages = images.filter((item) => item);
    let remaining = image_windows.length - actualImages.length;
    let disabled = remaining > 0 ? "disabled" : null;
    let buttonClasses = classNames(
      "btn",
      "btn-primary",
      "btn-lg",
      "btn-block",
      disabled
    );
    let numberWords = ["Two", "Three", "Four"];
    let the_price = prices.find((item) => item.id == frame_style.id).price;

    return (
      <div className="md:mx-10">
        <div className="md:mt-10">
          <div className="md:grid md:grid-cols-12 md:gap-x-8 ">
            <div className="md:order-2 md:col-span-7 ">
              <div className="relative aspect-1 bg-stone-100" ref="container">
                <div className="absolute w-full h-full flex justify-center items-center">
                  <div
                    className={classNames(
                      "absolute flex justify-center items-center",
                      this.props.width_mm > this.props.height_mm
                        ? "!w-[85%]"
                        : "!h-[85%] w-full",
                      imageLoading && "blur-sm"
                    )}
                  >
                    <MultiPhotoCanvas
                      maxWidth={maxWidth}
                      windows={image_windows}
                      images={images}
                      viesus={viesus_correct}
                      {...this.props}
                      imageLoading={this.imageLoading}
                      imageLoaded={this.imageLoaded}
                      frame_style={frame_style}
                    />
                  </div>
                  {imageLoading && (
                    <div className="absolute left-1/2 top-1/2 -ml-[25px] -mt-[25px] w-12 bg-white rounded-lg p-2">
                      <img src={this.props.preloader} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="md:order-3 md:col-span-5">
              <div className="mx-3">
                <h1 className="mt-4 md:mt-0 mb-2">Collage Photo Frame</h1>
                <p className="text-muted">
                  {numberWords[image_windows.length - 2]}{" "}
                  {image_windows[0].size_desc} photos in a{" "}
                  {this.props.frame_size} frame.
                </p>

                <div className="mini-heading mb-3 mt-7">
                  Frame style:{" "}
                  <span className="text-muted">
                    {this.state.frame_style.title}
                  </span>
                </div>
                <FrameStyleSelector
                  saveValues={this.updateFrameStyle}
                  options={this.props.frame_styles}
                  defaultChecked={this.state.frame_style}
                  name="Frame style"
                  groupName="frame_style"
                />
                <div className="mini-heading mb-2 mt-7">
                  Your photos
                  {remaining == 0 ? (
                    <span className="text-success float-right">
                      👍 All photos uploaded. Nice work!
                    </span>
                  ) : (
                    <span className="text-muted float-right mr-3">
                      <i className="fa-solid fa-circle-exclamation"></i>{" "}
                      {remaining} {remaining == 1 ? "photo" : "photos"}{" "}
                      remaining to upload
                    </span>
                  )}
                </div>
                <SortableContainer
                  lockAxis="y"
                  onSortEnd={this.onSortEnd}
                  useDragHandle
                >
                  {image_windows.map((value, index) => (
                    <SortableItem
                      public_key={this.props.public_key}
                      key={`item-${value.id}`}
                      index={index}
                      value={value}
                      onChange={this.newPhoto(index)}
                      onClear={this.onClear(index)}
                      minPixelsL={this.props.min_pixels_l}
                      minPixelsS={this.props.min_pixels_s}
                    />
                  ))}
                </SortableContainer>
                <div className="mini-heading mt-2 ml-2 text-xxs text-stone-400">
                  <i className="fa-solid fa-bars mr-2"></i> Drag to adjust the
                  order
                </div>
                <div className="my-3"></div>

                <div className="effects mt-4">
                  <div className="my-3">
                    <Toggle
                      id="orientation"
                      icons={false}
                      defaultChecked={viesus_correct}
                      onChange={this.toggleViesus}
                    />
                    <label
                      style={{ top: "-8px", position: "relative" }}
                      className="ml-2 mini-heading"
                      htmlFor="orientation"
                    >
                      <span className="text-dark">
                        <i className="fa-solid fa-glasses-round mr-1"></i>{" "}
                        {viesus_correct ? "Smart Enhance On" : "Enhance Off"}
                        <Tooltip
                          title="Automatically adjusts brightness & color, restores sharpness, removes noise and corrects for overexposure or underexposure"
                          distance={10}
                          position="top"
                          arrow={true}
                        >
                          <i className="fa-solid fa-circle-question"></i>
                        </Tooltip>
                      </span>
                    </label>
                  </div>
                </div>

                <React.Fragment>
                  <div className="pb-3 mt-6">
                    <div className="my-3">
                      <AddToCartButton
                        price={the_price}
                        tax_rate={this.props.tax_rate}
                        onClickEvent={this.addToCart}
                        buttonText="Add to cart"
                        disabled={disabled || this.state.addingToCart}
                        afterpayLogo={this.props.afterpayUI.logo_white}
                        isLoading={this.state.addingToCart}
                      />
                    </div>
                    <ShippingEstimate
                      shipping_icon={this.props.shipping_icon}
                      shipping_estimates={this.props.shipping_estimates}
                      print_and_frame={true}
                      country={this.props.country_name}
                      location="multi-photo"
                    />
                  </div>
                </React.Fragment>

                <>
                  <div className="mt-12 font-bold">
                    Museum-Quality in Every Frame
                  </div>
                  <div className="border-t border-gray-200 pt-5 mt-5">
                    <dt className="mini-heading text-gray-900">
                      Printing & Paper
                    </dt>
                    <dd className="mt-2 text-sm text-gray-500">
                      Your image will be printed on gallery-quality 255gsm
                      Luster Paper paper using 12 colour archival pigment inks
                      on the latest Epson printers.
                    </dd>
                  </div>
                  <div className="border-t border-gray-200 pt-5 mt-5">
                    <dt className="mini-heading text-gray-900">
                      The {this.state.frame_style.title} Frame
                    </dt>
                    <dd className="mt-2 text-sm text-gray-500">
                      Sustainably sourced, solid wood frame handmade in{" "}
                      {this.props.country_name}.
                    </dd>
                  </div>
                  <div className="border-t border-gray-200 pt-5 mt-5">
                    <dt className="mini-heading text-gray-900">Glazing</dt>
                    <dd className="mt-2 text-sm text-gray-500">
                      {this.props.glass_types_sentence}
                    </dd>
                  </div>
                  <div className="border-t border-gray-200 pt-5 mt-5">
                    <dt className="mini-heading text-gray-900">Borders</dt>
                    <dd className="mt-2 text-sm text-gray-500">
                      Acid-free bevel-cut {window.App.mat} to make your images
                      pop.
                    </dd>
                  </div>
                  <div className="border-t border-gray-200 pt-5 mt-5">
                    <dt className="mini-heading text-gray-900">
                      Hanging Hardware
                    </dt>
                    <dd className="mt-2 text-sm text-gray-500">
                      Your frame arrives with D-Rings and cord attached, ready
                      to hang.
                    </dd>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const DragHandle = sortableHandle(({ text }) => (
  <span className="drag-handle">
    <i className="fa-solid fa-bars mr-2"></i>
    Photo {text}
  </span>
));

const minResolution = (width, height) => {
  return function (fileInfo) {
    const imageInfo = fileInfo.originalImageInfo;
    const resolution = width * height;
    if (imageInfo !== null) {
      if (imageInfo.width * imageInfo.height < resolution) {
        throw new Error("minResolutionError");
      }
    }
  };
};

const SortableItem = sortableElement(
  ({ public_key, value, index, onChange, onClear, minPixelsL, minPixelsS }) => (
    <div className="bg-pale d-block mt-0 text-left p-2 multi-photo-items">
      <DragHandle text={value.position} />
      <div className="float-right">
        <Widget
          validators={[minResolution(minPixelsL, minPixelsS)]}
          onChange={(info) => onChange(info)}
          onFileSelect={(file) => !file && onClear()}
          publicKey={public_key}
          tabs="file facebook instagram dropbox url"
          imagesOnly="true"
          previewStep="true"
          clearable="true"
          store="auto"
          crop={value.crop_ratio}
          id="file"
          localeTranslations={UPLOADCARE_LOCALE_TRANSLATIONS}
        />
      </div>
    </div>
  )
);

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

export default MultiPhotoDesigner;
