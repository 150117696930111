import React from 'react';
import ReactDOM from 'react-dom';
import { RadioGroup } from '@headlessui/react'
import classNames from 'classnames';


const FrameStyleSelector = (props) => (
  <RadioGroup value={props.defaultChecked} onChange={(e) => props.saveValues({frame_style: e})}  className="mt-3">
    {props.options.map((radio) => (
      <RadioGroup.Option
        key={radio.id}
        value={radio}
        className={({ active, checked }) =>
          classNames(
            active ? 'ring-2 ring-brand-600 ring-offset-2' : '',
            checked
              ? 'ring-2 ring-brand-600 ring-offset-2'
              : 'bg-pale ring-1 ring-inset ring-white bg-white text-gray-900 hover:bg-gray-50',
            'items-center justify-center rounded-md mr-2 mb-2 text-sm font-semibold  inline-block cursor-pointer'
          )
        }
      >
        <RadioGroup.Label as="span">
          <img src={radio.profile_thumb} className="rounded-md w-12 h-12 block"/>
        </RadioGroup.Label>
      </RadioGroup.Option>
    ))}
  </RadioGroup>
)


export default FrameStyleSelector;