import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import Cropper from "react-cropper";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Slider, { Range } from "rc-slider";
import Toggle from "react-toggle";
import { Tooltip } from "react-tippy";
import EffectAndFilters from "components/frame-designer/effects/EffectAndFilters";
import { isMobile } from "react-device-detect";
import cloudinary from "cloudinary-core";

class CropAndEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCropMode: true,
      success_path: props.success_path,
      cropOnly: props.cropOnly || false,
    };
    this.cropComplete = this.cropComplete.bind(this);
    this.cropEditComplete = this.cropEditComplete.bind(this);
  }

  cropComplete(data) {
    if (this.state.cropOnly) {
      this.cropEditComplete(data);
    } else {
      this.setState({ image: data.image, isCropMode: false });
    }
  }

  cropEditComplete(data) {
    if (this.state.success_path) window.location.href = this.state.success_path;
    else {
      this.props.imageSaved(data);
    }
  }

  render() {
    return (
      <div>
        {this.state.isCropMode ? (
          <TemplateCrop saveComplete={this.cropComplete} {...this.props} />
        ) : (
          <EffectAndFilters
            saveComplete={this.cropEditComplete}
            image={this.state.image}
            preloader={this.props.preloader}
            crop_image_path={this.props.crop_image_path}
            effects={this.props.image.effects}
          />
        )}
      </div>
    );
  }
}

class TemplateCrop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cropOrientationPortrait: this.props.source_ratio < 1,
      loading: true,
      zoom: 0,
      initialZoom: 1,
      ratio: this.props.force_ratio,
      lowDPI: false,
      rotate: this.props.image.effects.rotate,
      effects: this.props.image.effects,
      effectsURL: "",
    };

    this.validateAndSend = this.validateAndSend.bind(this);
    this.saveSuccess = this.saveSuccess.bind(this);
    this.changeZoom = this.changeZoom.bind(this);
    this.cropperMounted = this.cropperMounted.bind(this);
    this.cropUpdated = this.cropUpdated.bind(this);
    this.dpiUpdate = this.dpiUpdate.bind(this);
    this.handleOrientationChange = this.handleOrientationChange.bind(this);
    this.renderSwitch = this.renderSwitch.bind(this);
    this.renderURL = this.renderURL.bind(this);
    this.rotateUpdate = this.rotateUpdate.bind(this);
  }

  componentDidMount() {
    if (this.state.cropOrientationPortrait && this.props.allow_orientation) {
      this.setState({ ratio: 1 / this.props.force_ratio });
    }
  }

  validateAndSend(e) {
    e.preventDefault();
    this.setState({ loading: true });
    $.ajax({
      method: "PATCH",
      url: this.props.crop_image_path,
      data: {
        image: {
          id: this.props.image.id,
          cx: this.state.crop.x,
          cy: this.state.crop.y,
          cw: this.state.crop.width,
          ch: this.state.crop.height,
        },
        preview_width: this.state.preview_width,
      },
    }).done(this.saveSuccess);
  }

  saveSuccess(data) {
    this.setState({ loading: false });
    this.props.saveComplete(data);
  }

  changeZoom(val) {
    this.setState({ zoom: val });
    this.state.cropper.zoomTo(parseFloat(val));
  }

  cropperMounted() {
    if (this.state.cropper) {
      const canvasData = this.state.cropper.getCanvasData();
      const zoomRatio = canvasData.width / canvasData.naturalWidth;

      this.setState({
        initialZoom: zoomRatio,
        zoom: zoomRatio,
        loading: false,
        preview_width: this.state.cropper.getImageData().naturalWidth,
      });
    }
  }

  cropUpdated() {
    if (this.state.cropper) {
      let data = this.state.cropper.getData();
      this.setState({ crop: data }, () => this.dpiUpdate());
    }
  }

  dpiUpdate() {
    const { print_size, crop, preview_width } = this.state;

    if (crop && this.props.is_for_print && preview_width) {
      let longest = Math.max(crop.width, crop.height);
      let scale = this.props.image.width / preview_width;

      let dpi = Math.round(
        (longest * scale) / this.props.template_longest_inches
      );
      this.setState({ dpi: dpi, lowDPI: dpi <= 90 });
    }
  }

  handleOrientationChange(e) {
    this.setState(
      {
        ratio: 1 / this.props.force_ratio,
        zoom: this.state.initialZoom,
        cropOrientationPortrait: !this.state.cropOrientationPortrait,
      },
      () => this.dpiUpdate()
    );
  }

  renderSwitch() {
    return (
      <div className="my-4">
        <Toggle
          id="orientation"
          icons={false}
          defaultChecked={!this.state.cropOrientationPortrait}
          onChange={this.handleOrientationChange}
        />
        <label
          style={{ top: "-8px", position: "relative" }}
          className="ml-2 mini-heading"
          htmlFor="orientation"
        >
          <span
            className={!this.state.cropOrientationPortrait ? "text-dark" : null}
          >
            Portrait
          </span>{" "}
          ·
          <span
            className={this.state.cropOrientationPortrait ? "text-dark" : null}
          >
            {" "}
            Landscape
          </span>
        </label>
      </div>
    );
  }

  renderURL() {
    let cl = new cloudinary.Cloudinary({
      cloud_name: "framefox",
      secure: true,
    });
    let c = this.props.image.crop;
    let url = cl.url(this.props.image.original_url, {
      type: "fetch",
      angle: this.state.rotate,
    });
    return url;
  }

  rotateUpdate(rotate) {
    this.setState({
      rotate: rotate,
      loading: true,
    });
  }

  render() {
    let disabled = this.state.loading || this.state.lowDPI ? "disabled" : null;
    let classes = classNames(
      "btn btn-primary btn-lg block md:inline-block my-5",
      disabled
    );
    let cropperHeight = isMobile ? window.innerWidth : 400;
    let cropperWidth = isMobile ? window.innerWidth : 500;

    return (
      <div className="md:grid md:grid-cols-2 md:gap-x-5">
        <div className="relative">
          <Cropper
            ref="cropper"
            src={this.renderURL()}
            style={{ height: cropperHeight, maxWidth: cropperWidth }}
            zoomTo={parseFloat(this.state.zoom)}
            dragMode="move"
            aspectRatio={this.props.force_ratio}
            viewMode={1}
            autoCrop={true}
            autoCropArea={1}
            cropBoxMovable={false}
            cropBoxResizable={false}
            zoomOnWheel={false}
            movable={true}
            guides={true}
            onInitialized={(instance) => this.setState({ cropper: instance })}
            ready={this.cropperMounted}
            crop={this.cropUpdated}
          />

          {this.state.loading && (
            <div className="absolute left-1/2 top-1/2 -ml-[25px] -mt-[25px] w-12 bg-white rounded p-2">
              <img src={this.props.preloader} />
            </div>
          )}
          <div className="m-4 text-center">
            <div className="d-none d-md-block">
              <Slider
                min={this.state.initialZoom}
                max={0.7}
                step={0.025}
                value={this.state.zoom}
                onChange={this.changeZoom}
              />
            </div>
            {this.state.crop && this.props.is_for_print && (
              <DPIControl dpi={this.state.dpi} />
            )}
          </div>
        </div>
        <div className="text-center md:text-left px-4">
          <h1>Crop your image</h1>
          <p className="mt-3">
            Adjust the crop region of your image for the{" "}
            {this.props.template_title} photo.
          </p>
          {this.props.force_ratio != 1 &&
            this.props.allow_orientation &&
            this.renderSwitch()}

          {this.state.lowDPI && !this.state.loading && (
            <div className="alert alert-danger">
              <i className="fa-solid fa-triangle-exclamation"></i> Image
              resolution is too low for a high-quality print at this size.
            </div>
          )}
          <div className="mb-3">
            <a href="#" onClick={this.validateAndSend} className={classes}>
              Continue
              <i className="fa-solid fa-arrow-right ml-2 text-base" />
            </a>
            <RotateControls
              rotateUpdate={this.rotateUpdate}
              originalURL={this.props.image.url}
              effects={this.props.image.effects}
            />
          </div>
        </div>
      </div>
    );
  }
}

class RotateControls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rotate: this.props.effects.rotate || 0,
    };
    this.turnRight = this.turnRight.bind(this);
    this.updateRotate = this.updateRotate.bind(this);
  }

  componentDidMount() {
    this.updateRotate();
  }

  turnRight() {
    let newRotate = this.state.rotate == 270 ? 0 : this.state.rotate + 90;
    this.setState({ rotate: newRotate }, () => this.updateRotate());
  }

  updateRotate() {
    this.props.rotateUpdate(this.state.rotate);
  }

  render() {
    return (
      <div className="d-inline-block ml-2">
        <a href="#" onClick={this.turnRight} className="btn">
          <i className="fa-solid fa-rotate-right mr-1"></i> Rotate
        </a>
      </div>
    );
  }
}

const DPIControl = ({ dpi }) => {
  return (
    <div className="mini-heading text-dark mt-3">
      Print resolution {dpi} DPI · <DPIWarning dpi={dpi} />
    </div>
  );
};

const DPIWarning = ({ dpi }) => {
  if (dpi <= 90) {
    return <span className="text-danger">Consider a smaller print size</span>;
  } else if (dpi > 90 && dpi <= 125) {
    return <span className="text-warning">Consider a smaller print size</span>;
  } else {
    return <span className="text-success">Lookin' good</span>;
  }
};

export default CropAndEdit;
