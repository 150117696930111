import React from "react";
import ReactDOM from "react-dom";
import ColourSelector from "components/art/ColourSelector";
import ColourPicker from "components/art/ColourPicker";

class ColourPickers extends React.Component {
  constructor(props) {
    super(props);
    this.state = { searchOpen: false };
    this.toggleSearch = this.toggleSearch.bind(this);
  }

  toggleSearch() {
    this.setState({ searchOpen: !this.state.searchOpen });
  }

  render() {
    return (
      <div>
        {this.state.searchOpen && (
          <div>
            <div className="small text-muted mb-2">
              Type to search full catalogue...
            </div>
            <ColourSelector
              colour={this.props.colour}
              onColourChange={this.props.onColourChange}
            />
          </div>
        )}
        <div className="flex justify-content-center">
          {!this.state.searchOpen && (
            <ColourPicker
              colour={this.props.colour}
              onColourChange={this.props.onColourChange}
            />
          )}
          <div className="">
            <button
              type="button"
              onClick={this.toggleSearch}
              className="btn btn-pale btn-sm font-weight-normal"
            >
              {!this.state.searchOpen ? (
                <span>
                  <i className="fa-solid fa-magnifying-glass" /> Tint Paint
                  Catalogue
                </span>
              ) : (
                <span>
                  <i className="fa-solid fa-chevrons-left fa-xs" /> Back to
                  swatches
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ColourPickers;
