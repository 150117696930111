import React from 'react';
import Select from 'react-select'
import chroma from 'chroma-js';
import {colourOptions, defaultColourOptions} from 'components/art/constants'
import AsyncSelect from 'react-select/async';

const filterColors = (inputValue) => {
  return colourOptions.filter(i =>
    i.label.toLowerCase().includes(inputValue.toLowerCase())
  );
};

const promiseOptions = inputValue =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(filterColors(inputValue));
    }, 1000);
  });

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 3,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 12,
    width: 12,
  },
});

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      ...dot(data.color),
      backgroundColor: isFocused ? color.alpha(0.1).css() : null,
      color: data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        color: 'white',
        backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

class ColourSelector extends React.Component {
  selectRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      inputValue: ''
    }
    this.onChange          = this.onChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  componentDidMount(){
    this.selectRef.focus()
  }

  onChange(col){
    this.props.onColourChange(col)
  }

  handleInputChange(newValue) {
    const inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue });
    return inputValue;
  };

  render() {
    return (
      <AsyncSelect
        ref={ref => {
          this.selectRef = ref;
        }}
        menuPlacement="top"
        placeholder="Search Resene Catalogue..."
        onChange={this.onChange}
        components={{ ClearIndicator:() => null }}
        value={colourOptions.find(o => o.color === this.props.colour.color) || ''}
        isClearable={true}
        defaultOptions={defaultColourOptions}
        styles={colourStyles}
        loadOptions={promiseOptions}
        onInputChange={this.handleInputChange}
      />
    )
  }
}



export default ColourSelector;