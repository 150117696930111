import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { Tooltip } from "react-tippy";
import { isMobile } from "react-device-detect";
import cloudinary from "cloudinary-core";
import Toggle from "react-toggle";
import { RadioGroup } from "@headlessui/react";

class EffectAndFiltersInline extends React.Component {
  constructor(props) {
    super();
    this.state = {
      effects: props.effects,
      loading: false,
      imageSaving: false,
      filters: ["ukulele", "audrey", "peacock", "primavera", "sonnet"],
    };
    this.validateAndSend = this.validateAndSend.bind(this);
    this.completeResponse = this.completeResponse.bind(this);
    this.effectsUpdate = this.effectsUpdate.bind(this);
  }

  validateAndSend() {
    this.setState({ loading: true });
    $.ajax({
      method: "PATCH",
      url: this.props.crop_image_path,
      data: {
        image: {
          id: this.props.image.id,
          viesus_correct: this.state.effects.viesus_correct,
          filter: this.state.effects.filter,
        },
      },
    }).done(this.completeResponse);
  }

  completeResponse(data) {
    this.setState({ imageSaving: false });
    this.props.saveComplete(data.image);
  }

  effectsUpdate(effects) {
    this.setState({ effects: effects }, () =>
      this.props.imageUpdate(this.props.image, effects)
    );
  }

  render() {
    return (
      <div className="filter-container">
        <div>
          <div className="max-w-2xl mx-auto pb-3 px-4">
            <EffectsControls
              effectsUpdate={this.effectsUpdate}
              url={this.props.image.original_url}
              effects={this.props.image.effects}
              filters={this.state.filters}
            />

            <button
              onClick={this.validateAndSend}
              disabled={this.props.effectsLoading || this.state.imageSaving}
              className="md:mt-5 btn btn-primary btn-lg disabled:bg-brand-400 disabled:cursor-not-allowed"
            >
              Done
              {(this.props.effectsLoading || this.state.imageSaving) && (
                <i className="fa-solid fa-spinner fa-spin ml-2"></i>
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

class EffectsControls extends React.Component {
  constructor(props) {
    super();
    this.state = {
      filter: props.effects.filter,
      viesus_correct:
        props.effects.viesus_correct == null
          ? false
          : props.effects.viesus_correct,
    };
    this.handleFilter = this.handleFilter.bind(this);
    this.toggleViesus = this.toggleViesus.bind(this);
    this.newEffects = this.newEffects.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
  }

  handleFilter(f) {
    if (this.state.filter == f) {
      f = null;
    }

    this.setState({ filter: f }, () => this.newEffects());
  }

  newEffects() {
    let effects = {
      filter: this.state.filter,
      viesus_correct: this.state.viesus_correct,
    };
    this.props.effectsUpdate(effects);
  }

  toggleViesus() {
    this.setState({ viesus_correct: !this.state.viesus_correct }, () =>
      this.newEffects()
    );
  }

  renderThumb(f) {
    let cl = new cloudinary.Cloudinary({
      cloud_name: "framefox",
      secure: true,
    });
    let filter = "art:" + f;
    let url = cl.url(this.props.url, {
      type: "fetch",
      transformation: [
        { width: 80, height: 80, crop: "fill" },
        { effect: filter },
      ],
    });
    return url;
  }

  render() {
    const { viesus_correct } = this.state;

    return (
      <div className="edit-tools pt-2 py-6">
        <div className="md:grid md:grid-cols-5">
          <div className="col-span-3">
            <div className="filters text-left">
              <div className="text-sm font-bold mb-4 md:mt-4">
                <i className="fa-solid fa-glasses-round mr-1"></i> Image Filters
              </div>
              {this.state.filter && (
                <button
                  onClick={this.handleFilter.bind(this, null)}
                  type="button"
                  className="btn btn-pale float-right w-12 h-12"
                >
                  <i className="fa-solid fa-xmark text-brand-600 text-lg "></i>
                </button>
              )}

              <RadioGroup
                value={this.state.filter}
                onChange={(e) => this.handleFilter(e)}
              >
                <div className="">
                  {this.props.filters.map((filter) => (
                    <RadioGroup.Option
                      key={filter}
                      value={filter}
                      className={({ active, checked }) =>
                        classNames(
                          active ? "ring-2 ring-brand-600 ring-offset-2" : "",
                          checked
                            ? "ring-2 ring-brand-600 ring-offset-2"
                            : "ring-1 ring-inset ring-white bg-white text-gray-900 hover:bg-gray-50",
                          "items-center justify-center rounded-md mr-2 mb-2 text-sm font-semibold  inline-block cursor-pointer"
                        )
                      }
                    >
                      <RadioGroup.Label as="span">
                        <Tooltip
                          title={
                            filter.charAt(0).toUpperCase() + filter.slice(1)
                          }
                          arrow={true}
                          trigger="mouseenter"
                          key={filter}
                        >
                          <img
                            src={this.renderThumb(filter)}
                            className="rounded-md w-12 h-12 block"
                          />
                        </Tooltip>
                      </RadioGroup.Label>
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>
          </div>
          <div className="col-span-2">
            <div className="effects mt-4 md:mt-14 md:ml-8 text-left">
              <Toggle
                id="orientation"
                icons={false}
                defaultChecked={viesus_correct}
                onChange={this.toggleViesus}
              />
              <label
                style={{ top: "-8px", position: "relative" }}
                className="ml-2 mini-heading"
                htmlFor="orientation"
              >
                <span className="text-dark">
                  <i className="fa-solid fa-wand-magic-sparkles"></i>{" "}
                  {viesus_correct ? "Smart Enhance On" : "Smart Enhance"}
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EffectAndFiltersInline;
