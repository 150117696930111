import React from 'react';
import ReactDOM from 'react-dom';
import {framePreviewURL} from 'components/art/constants'

class CollectionIndex extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render(){
    return(
      <div className="container mt-5">
        <div className="featured-collections-wrap row justify-content-center"> 
          <div className="col-sm-20">
            {this.props.collections.filter(item => item.is_featured == true ).map(collection =>{
              return (
                <FeaturedCollection key={collection.id} frameStyle={this.props.frame_styles.find(item => item.slug == collection.image.default_frame )} collection={collection} setCollection={this.props.setCollection}/>
              )
            })}
          </div>
        </div>
        <h3 className="text-center">More curated collections</h3>
        <div className="promo-wrap">
          {this.props.collections.filter(item => item.is_featured == false ).map(collection =>{
            return (
              <div className="promo-item" key={collection.id} style={{backgroundImage: `url(${collection.image.url})`}}>
                <div onClick={() => this.props.setCollection(collection)} className="promo-link"></div>   
                <div className="promo-overlay">
                  <div className="promo-inner">
                    <h5>{collection.title}</h5>      
                  </div>
                </div>
                <div className="promo-shadow"></div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

const FeaturedCollection = ({collection, setCollection, frameStyle}) => {

  const ratio = (collection.image.width / collection.image.height).toFixed(2)
  const w = ratio > 1 ? 1000 : 1000 * ratio
  const h = ratio > 1 ? 1000 / ratio : 1000
  const mat_width = collection.image.default_preset == 'framed_border' ? 50 : 0

  return(

    <div className="featured-collection">
      <img className="collection-image" src={collection.lead_image}/>
      <div className="card floating">
        <div className="card-body">
          <div className="mini-heading">Collection</div>
          <h2>{collection.title}</h2>
          <p className="text-muted">{collection.description}</p>
          <button type="button" onClick={() => setCollection(collection)} className="btn btn-primary btn-lg">View Collection</button> 
        </div>
      </div>
    </div>
  )
}


export default CollectionIndex;