import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-responsive-modal';
import Front from '@frontapp/plugin-sdk';

class FrontApp extends React.Component{
  constructor(props) {
    super();
    this.state = {
      loading: true
    };
    this.getUser = this.getUser.bind(this);
  }


  componentDidMount() {
    Front.contextUpdates.subscribe(context => {
      switch(context.type) {
        case 'noConversation':
          console.log('No conversation selected');
          break;
        case 'singleConversation':
          console.log('Selected conversation:', context.conversation);
          this.getUser(context.conversation.recipient.handle)
          break;
        case 'multiConversations':
          console.log('Multiple conversations selected', context.conversations);
          break;
        default:
          console.error(`Unsupported context type: ${context.type}`);
          break;
      }
    });
  }

  getUser(email){
    fetch(`${this.props.user_front_app_path}?email=${email}&auth_secret=${this.props.auth_secret}`, { 
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => response.json())
      .then(data => {
        if(data.user){
          this.setState({user: data.user, orders: data.orders, loading: false})
        }else{
          this.setState({user: null, orders: null, loading: false})
        }
        
      })
  }

  render(){
    return(
      <div>
        { this.state.loading ? <h4>Loading...</h4> :
          <div>
            {
              this.state.user ? 
              <div>
                <h4>Customer</h4>
                <div>
                  <p className="mini-heading mb-0">Name</p>
                  
                  <button type="button" onClick={(e) => Front.openUrl( this.state.user.url )} className="link">{ this.state.user.name }</button>
                  {
                    this.state.user.is_pro && <div className="badge Production">PRO</div>
                  }
                </div>
                <div>
                  <p className="mini-heading mb-0">Email</p>
                  { this.state.user.email }
                </div>
                <div>
                  <p className="mini-heading mb-0">Signed Up</p>
                  { this.state.user.created_at }
                </div>
              </div> 
              :
              <h4>User not found</h4> 
            }
            {
              this.state.orders && this.state.user && <h4>Orders</h4>
            }
            {
              this.state.orders && this.state.orders.map(order => { 
                return (
                  <div className="card" key={order.id} >
                    <div>
                      <p className="mini-heading mb-0">Key</p>
                      <button type="button" onClick={(e) => Front.openUrl( order.url )} className="link">{ order.key }</button>
                      { order.region && ` - ${order.region}` }
                      <div className={`badge ${order.status}`}> { order.status }</div>
                    </div>
                    { order.shipped_at ? 
                      <div>
                        <p className="mini-heading mb-0">Shipped</p>
                        { order.shipped_at }
                      </div>
                      :
                      <div>
                        <p className="mini-heading mb-0">Ordered / Created on</p>
                        { order.ordered_at }
                      </div>
                    }
                    <div>
                      <p className="mini-heading mb-0">Subtotal</p>
                      ${ order.sub_total }
                    </div>
                    
                  </div>
                )
              })
            }
          </div>
        }
      </div>
    )
  }
}


document.addEventListener('DOMContentLoaded', () => {
  var targetElement = document.querySelector('[data-react-class="FrontApp"]')
  if(targetElement){
    ReactDOM.render(
      <FrontApp {...JSON.parse(targetElement.getAttribute('data-react-props'))} />,
      targetElement
    )
  }
})

export default FrontApp;

