import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import axios from "axios";
import ShippingMethodShow from "components/cart/ShippingMethodShow";
import ShippingEstimate from "components/cart/ShippingEstimate";
import AfterpayBreakdown from "components/afterpay/AfterpayBreakdown";

class PricingBreakdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      discount: props.discount,
      tax: props.tax,
      total: props.total,
      formOpen: !props.discount,
    };
    this.onDiscountUpdate = this.onDiscountUpdate.bind(this);
    this.onShippingUpdate = this.onShippingUpdate.bind(this);
    this.renderDiscount = this.renderDiscount.bind(this);
    this.removeDiscount = this.removeDiscount.bind(this);
    this.completeRemove = this.completeRemove.bind(this);
  }

  componentDidMount() {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
  }

  onDiscountUpdate(data) {
    this.setState({
      discount: data.discount,
      total: data.total,
      tax: data.tax,
      formOpen: false,
    });
  }

  onShippingUpdate(new_total, new_tax) {
    this.setState({
      total: new_total,
      tax: new_tax,
    });
  }

  removeDiscount(e) {
    e.preventDefault();
    $.ajax({
      method: "GET",
      url: this.props.url_remove,
    }).done(this.completeRemove);
  }

  completeRemove(data) {
    this.onDiscountUpdate(data);
    this.setState({
      discount: null,
      formOpen: true,
    });
  }

  renderDiscount() {
    if (this.state.discount) {
      return (
        <div className="grid grid-cols-2">
          <div className="">
            {this.state.discount.description}
            <span className="badge bg-white font-weight-bold ml-1 mt-2">
              {this.state.discount.code}
              {!this.props.payment_step && (
                <button
                  type="button"
                  onClick={(e) => this.removeDiscount(e)}
                  className="btn btn-xs p-0"
                >
                  <i className="fa-solid fa-circle-xmark text-muted ml-1"></i>
                </button>
              )}
            </span>
          </div>
          <div className="text-success">
            <div className="h5 text-right font-weight-normal shipping-price">
              - {this.state.discount.reduction}
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="">
        <DiscountCode
          open={this.state.formOpen}
          onDiscountUpdate={this.onDiscountUpdate}
          url={this.props.url}
          is_pro={this.props.is_pro}
          payment_step={this.props.payment_step}
        />

        <hr />
        <div className="grid grid-cols-2  my-3">
          <div className="">
            Subtotal
            {this.props.items_count > 1 && (
              <small className="text-muted ml-1">
                ({this.props.items_count} items)
              </small>
            )}
          </div>
          <div className="">
            <div className="h5 text-right font-weight-normal">
              {this.props.items_total}
            </div>
          </div>
        </div>
        {this.renderDiscount()}

        {this.props.studio_framing_addon && (
          <div className="grid grid-cols-3  my-3">
            <div className="col-span-2">
              <p className="mb-2">
                In-Studio Framing{" "}
                <small className="text-muted">($25 for up to 3 frames)</small>
              </p>
            </div>
            <div className="col-span-1">
              <div className="h5 text-right font-weight-normal">
                {this.props.studio_framing_addon}
              </div>
            </div>
          </div>
        )}

        {this.props.shipping_method ? (
          <div className="grid grid-cols-3  my-3">
            <div className="col-span-2">
              <div>
                Shipping:{" "}
                {this.props.shipping_overide ? (
                  <span className="text-gray-400">Custom</span>
                ) : (
                  <>
                    <span className="text-gray-400">
                      {this.props.shipping_method.title}
                    </span>{" "}
                    <span className="badge badge-pale badge-sm uppercase">
                      {this.props.shipping_method.size}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="col-span-1">
              <div className="h5 text-right font-weight-normal shipping-price mb-0">
                {this.props.shipping_overide
                  ? this.props.shipping_overide
                  : this.props.shipping_method.price}
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="grid grid-cols-3 my-3">
              <div className="col-span-1">Shipping</div>
              <div className="col-span-2">
                <div className="text-muted text-sm text-right">
                  Calculated at checkout
                  <ShippingEstimate
                    shipping_icon={this.props.shipping_icon}
                    shipping_estimates={this.props.shipping_estimates}
                    print_and_frame={true}
                    country={this.props.country_name}
                    location="cart"
                  />
                </div>
              </div>
            </div>
            <div className="">
              <hr />
              <div></div>
            </div>
          </>
        )}
        {this.props.credit && (
          <div className="grid grid-cols-3 my-3">
            <div className="col-span-2">Store credit applied</div>
            <div className="col-span-1">
              <div className="h5 text-right font-weight-normal shipping-price">
                -{this.props.credit.amount}
              </div>
            </div>
          </div>
        )}

        <hr />
        <div className="grid grid-cols-3  my-3">
          <div className="col-span-2">
            <span className="mb-0">Total</span>
            <div className="text-muted text-xs">
              Including <span className="gst">{this.state.tax}</span>{" "}
              {window.App.taxName}
            </div>
          </div>
          <div className="col-span-1 text-right">
            <div className="mb-0 text-2xl">
              <span className="font-bold text-xs font-sans relative -top-2 mr-0.5">
                {window.App.currencySymbol}
              </span>
              {this.state.total}
            </div>
          </div>
        </div>
        {this.props.in_cart && (
          <div>
            <a
              href={this.props.start_checkout_url}
              className="btn btn-primary btn-lg block mt-5 text-center"
            >
              Checkout <i className="fa-solid fa-caret-right text-xs ml-2" />
            </a>
          </div>
        )}

        <div className="grid mt-2">
          {this.props.afterpay_available && (
            <AfterpayBreakdown
              total={this.state.total.replace(",", "")}
              afterpayUI={this.props.afterpayUI}
            />
          )}
        </div>
      </div>
    );
  }
}

class DiscountCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      message: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.completeResponse = this.completeResponse.bind(this);
    this.renderMessage = this.renderMessage.bind(this);
    this.renderForm = this.renderForm.bind(this);
  }

  onChange(e) {
    this.setState({ text: e.target.value });
  }

  onFocus(e) {
    this.setState({ focused: true });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ message: "" });

    console.log(this.props.url);

    axios
      .patch(this.props.url, { voucher_code: this.state.text })
      .then((res) => {
        this.completeResponse(res.data);
      });
  }

  completeResponse(data) {
    if (data.success) {
      this.setState({
        success: true,
        message: data.message,
      });
      segment_track("Coupon Applied", data.tracking_detail);
      this.props.onDiscountUpdate(data);
    } else {
      this.setState({
        success: false,
        message: data.message,
      });
    }
  }

  renderMessage() {
    var color = this.state.success ? "text-brand-600" : "text-red-600";
    var classes = classNames(
      "mb-3",
      "animate-wiggle",
      "rounded",
      "bg-white",
      "p-3",
      color
    );

    if (this.state.message) {
      return <div className={classes}>{this.state.message}</div>;
    }
  }

  renderForm() {
    var focused = this.state.focused
      ? "form-group mb-0 focused"
      : "form-group mb-0";

    return (
      <form className="mt-2" onSubmit={this.handleSubmit}>
        <div>
          <div className="mt-2 flex rounded-md">
            <div className="relative flex flex-grow items-stretch focus-within:z-10">
              <input
                className="pl-3 block w-full sm:text-sm focus-visible:shadow-none rounded focus:ring-brand-600 focus:border-brand-600"
                onFocus={this.onFocus}
                onChange={this.onChange}
                value={this.state.text}
                placeholder="Discount code"
              />
            </div>
            <a
              className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-3 text-sm font-semibold bg-brand-500 text-white hover:bg-brand-400"
              href="#"
              onClick={this.handleSubmit}
            >
              Apply
            </a>
          </div>
        </div>

        <div className="grid">
          <div className="text-muted my-2">
            <small>
              Gift card code? <a href="/redeem/new">Redeem here</a>
            </small>
          </div>
        </div>
      </form>
    );
  }

  render() {
    return (
      <div className="">
        <div>
          {this.props.open && !this.props.payment_step && this.renderForm()}
        </div>

        {this.renderMessage()}
      </div>
    );
  }
}

export default PricingBreakdown;
