import React, { useState, FC, ReactElement } from "react";
import AsyncSelect from "react-select/async";
import { SingleValue, StylesConfig } from "react-select";
import DebouncePromise from "debounce-promise";

import {
  AddressAutocompleteOptions,
  AddressAutocompleteOption,
  Address,
} from "./AddressAutocomplete/types";
import SelectTheme from "../select_theme";

const handleSelectLoadOptions = async (
  inputValue: string
): Promise<AddressAutocompleteOption[]> => {
  return new Promise<AddressAutocompleteOption[]>((resolve) => {
    const query: string = encodeURIComponent(inputValue.toLowerCase().trim());
    const path: string = `/address_autocompletes?q=${query}`;

    fetch(path).then((response: Response) =>
      response
        .json()
        .then((options: AddressAutocompleteOptions) => resolve(options.data))
    );
  });
};

type BaseProps = {
  beforeChange: () => void;
  onChange: (address: Address) => void;
};

const buildSelectOnChangeHandler = ({ beforeChange, onChange }: BaseProps) => {
  return (newValue: SingleValue<AddressAutocompleteOption>): void => {
    if (newValue == null) return;

    beforeChange();

    const id: string = encodeURIComponent(newValue.value);
    const path: string = `/address_autocompletes/${id}`;

    fetch(path).then((response: Response) =>
      response.json().then((address: Address) => onChange(address))
    );
  };
};

const AddressAutocomplete: FC<BaseProps> = ({
  beforeChange,
  onChange,
}): ReactElement => {
  const [selectedValue, setSelectedValue] =
    useState<SingleValue<AddressAutocompleteOption> | null>(null);

  const onChangeWithReset = (address: Address) => {
    onChange(address);
    setSelectedValue(null);
  };

  const handleSelectOnChange = buildSelectOnChangeHandler({
    beforeChange,
    onChange: onChangeWithReset,
  });

  return (
    <AsyncSelect
      isClearable
      value={selectedValue}
      loadOptions={DebouncePromise(handleSelectLoadOptions, 400)}
      onChange={handleSelectOnChange}
      theme={SelectTheme}
      placeholder={"Find your address..."}
      noOptionsMessage={({ inputValue }) =>
        inputValue.length > 0 ? "No results" : "Type to search..."
      }
    />
  );
};

export default AddressAutocomplete;
