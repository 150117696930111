import React from 'react';
import ReactDOM from 'react-dom';
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Index,
  Configure,
  Hits,
  Highlight,
  useHits,
} from "react-instantsearch-hooks-web";


const searchClient = algoliasearch('YA6T7C41AN', '1a5742474814691c782a7c47b8d20de0');


class Search extends React.Component{ 
  constructor(props){
    super(props);
    this.state = {
      currSearchQuery: ''
    }
    this.setQuery = this.setQuery.bind(this);
  }

  setQuery(query, search) {
    this.setState({ currSearchQuery: query });
    search(query);
  }

  render () {
    const { currSearchQuery } = this.state;
    return (

      <div>
        <InstantSearch
          searchClient={searchClient}
          indexName={`User_${this.props.env}`}
          initialUiState={{
            indexName: {
              query: currSearchQuery,
              page: 1,
            },
          }}
        >
          <SearchBox
            queryHook={this.setQuery}
            placeholder="Search..."
            autoFocus={true}
            classNames={{
              root: "p-1.5 h-16",
              form: "relative",
              input:
                "h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0",
              submitIcon:
                "pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400",
              resetIcon:
                "absolute right-3 top-3.5 h-3 w-3 text-gray-400",
            }}
          />
          { currSearchQuery.length > 0 &&
            <div className='shadow-lg relative -left-4 pb-3 bg-white'>
              <Index indexName={`User_${this.props.env}`}>
                <Configure hitsPerPage={10} />
                <UserHits />
              </Index>
              
              <div className='bg-white text-xs font-bold pl-8  px-4 py-1.5 w-full'>
                <hr className='mb-3'/>
                ORDERS
              </div>
              <Index indexName={`Order_${this.props.env}`}>
                <Configure hitsPerPage={5} />
                <OrderHits/>
              </Index>
            </div>
          }
        </InstantSearch>
      </div>

    )
  }
}

function UserHits(props) {
  const { hits, results, sendEvent } = useHits(props);

  return (
    <>
      {hits.map((hit) => (
        <div
          key={hit.objectID}
          onClick={() => window.location.href = "/admin/users/" + hit.objectID}
          className="bg-white hover:cursor-pointer truncate select-none  pl-8 px-4 py-1.5 pb-2.5 hover:bg-brand-600 hover:text-white"
        >
          <Highlight 
            classNames={{highlighted: 'bg-yellow-200'}}
            attribute="name" hit={hit} 
            className='font-bold' /> 
          <span className="badge badge-brand ml-2">{hit.country.toUpperCase()}</span>
          { hit.pro && <span className="badge badge-brand ml-2">PRO</span>}
          <div className='text-xs' >
          <Highlight 
            classNames={{highlighted: 'bg-yellow-200'}}
            attribute="email" hit={hit} /> · {hit.completed_orders} orders
            
          </div>
          
         
        </div>
      ))}
    </>
  );
}


function OrderHits(props) {
  const { hits, results, sendEvent } = useHits(props);

  return (
    <>
      {hits.map((hit) => (
        <div
          key={hit.objectID}
          onClick={() => window.location.href = "/admin/orders/" + hit.key}
          className="bg-white hover:cursor-pointer truncate select-none  pl-8 px-4 py-2 pb-2.5 hover:bg-brand-600 hover:text-white"
        >
          <Highlight classNames={{highlighted: 'bg-yellow-200'}} attribute="key" hit={hit} className='font-bold' /> 
          <span className="badge badge-brand ml-2">{hit.country.toUpperCase()}</span>
          <div className='text-xs'>
            {hit.user_name ? <Highlight classNames={{highlighted: 'bg-yellow-200'}} attribute="user_name" hit={hit} /> : "No User "}
            {hit.shipping_name && 
              <> · Shipped to <Highlight classNames={{highlighted: 'bg-yellow-200'}} attribute="shipping_name" hit={hit} /> </>
            }
             · {hit.ordered_or_updated_at}
          </div>
        </div>
      ))}
    </>
  );
}


export default Search;