import React from "react";
import ReactDOM from "react-dom";
import { Controller } from "stimulus";

import AddressAutocomplete from "../components/AddressAutocomplete";
import { Address } from "../components/AddressAutocomplete/types";

const LOADING: Address = {
  line_1: "Loading...",
  line_2: "Loading...",
  city: "Loading...",
  postcode: "Loading...",
  state: "Loading...",
  country: "Loading...",
  lat: 0,
  lon: 0,
  is_rural: false,
};

class FormWithAddressController extends Controller {
  static targets = [
    "addressAutocomplete",
    "line1",
    "line2",
    "city",
    "postcode",
    "state",
    "lat",
    "lon",
    "isRural",
  ];

  // Black magic from:
  //    https://github.com/hotwired/stimulus/issues/303
  declare addressAutocompleteTarget: HTMLElement;
  declare hasAddressAutocompleteTarget: boolean;

  declare line1Target: HTMLInputElement;
  declare hasLine1Target: boolean;

  declare line2Target: HTMLInputElement;
  declare hasLine2Target: boolean;

  declare cityTarget: HTMLInputElement;
  declare hasCityTarget: boolean;

  declare postcodeTarget: HTMLInputElement;
  declare hasPostcodeTarget: boolean;

  declare stateTarget: HTMLSelectElement;
  declare hasStateTarget: boolean;

  declare latTarget: HTMLInputElement;
  declare hasLatTarget: boolean;

  declare lonTarget: HTMLInputElement;
  declare hasLonTarget: boolean;

  declare isRuralTarget: HTMLInputElement;
  declare hasIsRuralTarget: boolean;

  connect() {
    const props = {
      beforeChange: this.handleBeforeChange.bind(this),
      onChange: this.handleChange.bind(this),
    };

    ReactDOM.render(
      React.createElement(AddressAutocomplete, props, null),
      this.addressAutocompleteTarget
    );
  }

  handleBeforeChange() {
    this.populateAddress(LOADING);
    this.loading(true); // Has to be _last_ as we can't `focus` a disabled element
  }

  handleChange(address: Address) {
    this.loading(false); // Has to be _first_ as we can't `focus` a disabled element
    this.populateAddress(address);
  }

  // Calls to `focus` are only used to get our fancy labels to work are
  // expected.
  populateAddress(address: Address) {
    if (this.hasLine1Target) {
      this.line1Target.focus();
      this.line1Target.value = address.line_1;
    }

    if (this.hasLine2Target) {
      this.line2Target.focus();
      this.line2Target.value = address.line_2;
    }

    if (this.hasCityTarget) {
      this.cityTarget.focus();
      this.cityTarget.value = address.city;
    }

    if (this.hasPostcodeTarget) {
      this.postcodeTarget.focus();
      this.postcodeTarget.value = address.postcode;
    }

    if (this.hasLatTarget) {
      this.latTarget.value = address.lat.toString();
    }

    if (this.hasLonTarget) {
      this.lonTarget.value = address.lon.toString();
    }

    if (this.hasIsRuralTarget) {
      this.isRuralTarget.checked = address.is_rural;
    }

    if (this.hasStateTarget) {
      this.selectState(address.state);
    }

    // Reset the `focus`
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }

  // Select by label rather than by value
  selectState = (label: string) => {
    const optionEls: HTMLOptionElement[] = Array.from(
      this.stateTarget.querySelectorAll("option")
    );
    const matches: HTMLOptionElement[] = optionEls.filter(
      (optionEl: HTMLOptionElement) => optionEl.innerText === label
    );

    if (matches.length > 0) {
      this.stateTarget.focus();
      this.stateTarget.value = matches[0].value;
    }
  };

  loading(isLoading: boolean = true) {
    FormWithAddressController.targets.forEach((name: string) => {
      const target: any = this.targets.find(name);

      if (target && "disabled" in target) {
        target.disabled = isLoading;
      }
    });
  }
}

export default FormWithAddressController;
