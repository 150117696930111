import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-responsive-modal";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import Cropper from "react-cropper";
import cloudinary from "cloudinary-core";
import Toggle from "react-toggle";
import Slider, { Range } from "rc-slider";

class CropImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      zoom: 0,
      initialZoom: 1,
      lowDPI: false,
      rotate: props.image.effects.rotate,
    };

    this.rotateUpdate = this.rotateUpdate.bind(this);
    this.dpiUpdate = this.dpiUpdate.bind(this);
    this.cropUpdated = this.cropUpdated.bind(this);
    this.cropperMounted = this.cropperMounted.bind(this);
    this.changeZoom = this.changeZoom.bind(this);
    this.saveCropDetails = this.saveCropDetails.bind(this);
    this.renderURL = this.renderURL.bind(this);
  }

  saveCropDetails() {
    this.props.saveComplete(this.props.image, {
      cx: parseInt(this.state.crop.x),
      cy: parseInt(this.state.crop.y),
      cw: parseInt(this.state.crop.width),
      ch: parseInt(this.state.crop.height),
      rotate: parseInt(this.state.rotate),
    });
  }

  changeZoom(val) {
    this.setState({ zoom: val });
    this.state.cropper.zoomTo(parseFloat(val));
  }

  cropperMounted() {
    if (this.state.cropper) {
      const canvasData = this.state.cropper.getCanvasData();
      const zoomRatio = canvasData.width / canvasData.naturalWidth;

      this.setState({
        initialZoom: zoomRatio,
        zoom: zoomRatio,
        loading: false,
        preview_width: this.state.cropper.getImageData().naturalWidth,
      });
    }
  }

  cropUpdated() {
    if (this.state.cropper) {
      let data = this.state.cropper.getData();
      this.setState({ crop: data }, () => this.dpiUpdate());
    }
  }

  dpiUpdate() {
    if (this.state.crop & this.state.preview_width) {
      let longest = Math.max(this.state.crop.width, this.state.crop.height);
      let scale = this.props.image.width / this.state.preview_width;
      let dpi = Math.round(
        (longest * scale) / this.props.template_longest_inches
      );
      this.setState({ dpi: dpi, lowDPI: dpi <= 90 });
    }
  }

  renderURL() {
    let cl = new cloudinary.Cloudinary({
      cloud_name: "framefox",
      secure: true,
    });
    let url = cl.url(this.props.image.original_url, {
      type: "fetch",
      angle: this.state.rotate,
    });
    return url;
  }

  rotateUpdate(rotate) {
    this.setState({
      rotate: rotate,
      loading: true,
    });
  }

  render() {
    let disabled = this.state.loading || this.state.lowDPI ? "disabled" : null;
    let classes = classNames(
      "btn",
      "btn-secondary",
      "btn-lg",
      "my-4",
      disabled
    );
    let cropperHeight = isMobile ? window.innerWidth : 400;
    let cropperWidth = isMobile ? window.innerWidth : 500;

    return (
      <div className="md:grid md:grid-cols-12">
        <div className="col-span-7">
          <div className="relative aspect-1">
            <Cropper
              ref="cropper"
              src={this.renderURL()}
              style={{ height: cropperHeight, maxWidth: cropperWidth }}
              zoomTo={parseFloat(this.state.zoom)}
              dragMode="move"
              aspectRatio={this.props.ratio}
              viewMode={1}
              autoCrop={true}
              autoCropArea={1}
              cropBoxMovable={false}
              cropBoxResizable={false}
              zoomOnWheel={false}
              movable={true}
              guides={true}
              onInitialized={(instance) => this.setState({ cropper: instance })}
              ready={this.cropperMounted}
              crop={this.cropUpdated}
            />
            {this.state.loading && (
              <div className="p-2 bg-white rounded-full absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 crop">
                <img src={this.props.preloader} className="w-8" />
              </div>
            )}
            <div className="m-4 text-center">
              <div className="hidden md:block">
                <Slider
                  min={this.state.initialZoom}
                  max={0.7}
                  step={0.025}
                  value={this.state.zoom}
                  onChange={this.changeZoom}
                />
              </div>
              {this.state.crop && <DPIControl dpi={this.state.dpi} />}
            </div>
          </div>
        </div>
        <div className="col-span-5 text-center text-md-left px-4">
          <h2 className="mt-5 mb-3">Crop your image</h2>
          <p>
            Adjust the crop region of your image for the{" "}
            {this.props.template_title} photo.
          </p>
          {this.props.ratio != 1 &&
            this.props.allow_orientation &&
            this.renderSwitch()}

          {this.state.lowDPI && !this.state.loading && (
            <div className="alert alert-danger">
              <i class="fa-solid fa-triangle-exclamation"></i> Image resolution
              is too low for a high-quality print at this size.
            </div>
          )}
          <div className="mb-3">
            <a href="#" onClick={this.saveCropDetails} className={classes}>
              Continue
            </a>
            <RotateControls
              rotateUpdate={this.rotateUpdate}
              effects={this.props.image.effects}
            />
          </div>
        </div>
      </div>
    );
  }
}

class RotateControls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rotate: props.effects.rotate || 0,
    };

    this.turnRight = this.turnRight.bind(this);
  }

  componentDidMount() {
    this.props.rotateUpdate(this.state.rotate);
  }

  turnRight() {
    const { rotate } = this.state;

    let newRotate = rotate == 270 ? 0 : rotate + 90;
    this.setState({ rotate: newRotate }, () =>
      this.props.rotateUpdate(this.state.rotate)
    );
  }

  render() {
    return (
      <div className="d-inline-block ml-2">
        <a href="#" onClick={this.turnRight} className="btn">
          <i className="fa-solid fa-rotate-right mr-1"></i> Rotate
        </a>
      </div>
    );
  }
}

const DPIControl = ({ dpi }) => {
  return (
    <div className="mini-heading text-dark mt-3">
      Print resolution {dpi} DPI · <DPIWarning dpi={dpi} />
    </div>
  );
};

const DPIWarning = ({ dpi }) => {
  if (dpi <= 90) {
    return <span className="text-danger">Too low</span>;
  } else if (dpi > 90 && dpi <= 125) {
    return <span className="text-warning">Passable</span>;
  } else {
    return <span className="text-success">Looks good</span>;
  }
};

export default CropImage;
