import React from "react";
import { Fragment } from "react";
import ReactDOM from "react-dom";
import { Transition, Dialog } from "@headlessui/react";
import { FolderOpenIcon, XMarkIcon } from "@heroicons/react/24/outline";

class ImageBrowser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      artworkModalOpen: false,
    };
    this.onArtworkSelect = this.onArtworkSelect.bind(this);
  }

  onArtworkSelect(item) {
    console.log(item);
  }

  render() {
    const { artworkModalOpen } = this.state;

    return (
      <div className="">
        <button
          onClick={(e) => this.setState({ artworkModalOpen: true })}
          className="btn btn-primary btn-pale mt-4 md:mt-7"
        >
          <i className="fa-solid fa-folder-image mr-2"></i>
          Choose from my previous uploads
        </button>
        <DetailModal
          images={this.props.images}
          artworkModalOpen={artworkModalOpen}
          closeModal={(e) => this.setState({ artworkModalOpen: false })}
        />
      </div>
    );
  }
}

function DetailModal(props) {
  return (
    <Transition.Root show={props.artworkModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={props.closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-stone-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-96">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-0"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-0"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="rounded-md text-stone-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => props.closeModal()}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full overflow-y-scroll bg-white p-8">
                    <div className="space-y-6 pb-16">
                      <ul
                        role="list"
                        className="grid grid-cols-2 gap-x-4 gap-y-8"
                      >
                        {props.images.map((image) => (
                          <li key={image.image_preview} className="relative">
                            <a
                              href={image.url}
                              className="group aspect-h-7 aspect-w-7 block w-full overflow-hidden rounded-lg bg-stone-100"
                            >
                              <img
                                src={image.image_preview}
                                alt=""
                                className="pointer-events-none object-cover group-hover:opacity-75"
                              />
                            </a>
                            <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-stone-900">
                              {image.filename}
                            </p>
                            <p className="pointer-events-none block text-sm font-medium text-stone-500">
                              {image.dimensions}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ImageBrowser;
