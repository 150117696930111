import React from "react";
import ReactDOM from "react-dom";
import Select from "react-select";

import SelectTheme from "../../select_theme";

const StyleColourSelect = ({ style, styles, setStyle }) => {
  function callback(e) {
    setStyle(styles.find((item) => item.id == e.value));
  }

  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
    control: (base) => ({
      ...base,
      height: 45,
      minHeight: 45,
    }),
  };

  const selectOptions = styles.map((style) => ({
    value: style.id,
    label: (
      <div className="flex">
        <img className="w-6 h-6 mr-3" src={style.icon} />
        <span className="grow hidden md:inline">{style.title}</span>
        <span className="mr-2 font-bold">
          {window.App.currencySymbol}
          {style.price}
        </span>
      </div>
    ),
  }));

  return (
    <Select
      className="style-select"
      classNamePrefix="style-select"
      value={selectOptions.find((item) => item.value == style.id)}
      styles={selectStyles}
      onChange={callback}
      options={selectOptions}
      isSearchable={false}
      theme={SelectTheme}
    />
  );
};

export default StyleColourSelect;
