import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import Modal from "react-responsive-modal";
import { RadioGroup } from "@headlessui/react";
import FrameCanvas from "components/frame-designer/designers/FrameCanvas";
import FrameGallery from "components/frame-designer/designers/FrameGallery";
import PreviewModeSelector from "components/frame-designer/designers/PreviewModeSelector";

class FrameShow extends React.Component {
  constructor(props) {
    super();
    this.state = {
      isImageLoading: true,
      frame_style: props.frame_style,
      preview_mode_index: 0,
      preview_modes: props.frame_style.gallery_images,
      preview_mode: props.frame_style.gallery_images[0],
      mat_style: props.mat_style,
      art_type: "",
      modalIsOpen: false,
    };
    this.switchPreviewMode = this.switchPreviewMode.bind(this);
    this.setArtType = this.setArtType.bind(this);
  }

  switchPreviewMode(newMode) {
    this.setState({ preview_mode: newMode, isImageLoading: true });
  }

  setArtType(art_type) {
    this.setState({
      art_type: art_type,
    });
  }

  render() {
    return (
      <div>
        <div className="md:grid md:grid-cols-6">
          <div className="md:col-span-3 lg:col-span-2 my-auto max-w-lg md:pl-12">
            <div className="mx-4 md:mx-0 text-center">
              <div className="text-center mb-4">
                <h1 className="text-5xl mb-3 mt-8 md:mt-0">
                  {this.state.frame_style.title}
                </h1>
                <p className="mb-2 text-xl text-muted">
                  {this.state.frame_style.excerpt}{" "}
                  {this.state.frame_style.width}mm wide and{" "}
                  {this.state.frame_style.depth}mm deep.
                </p>
              </div>

              <button
                type="button"
                className="btn btn-secondary btn-lg mb-5 mt-3"
                onClick={(e) => this.setState({ modalIsOpen: true })}
              >
                <i className="fa-solid fa-scalpel-line-dashed mr-2"></i>
                Customise this frame
              </button>

              <PreviewModeSelector
                saveValues={this.switchPreviewMode}
                options={this.state.preview_modes}
                defaultChecked={this.state.preview_mode}
                groupName="preview_mode"
              />
            </div>
          </div>
          <div className="md:col-span-3 lg:col-span-4">
            <img
              src={this.state.preview_mode.background}
              onLoad={(e) => this.setState({ isImageLoading: false })}
              className={classNames(this.state.isImageLoading && "blur-sm")}
            />
          </div>
        </div>
        <Modal
          open={this.state.modalIsOpen}
          onClose={(e) => this.setState({ modalIsOpen: false })}
          classNames={{
            overlay: "custom-overlay",
            modal: "custom-modal product-type-popup",
          }}
        >
          <div className="text-center">
            <h2 className="mt-5">
              Customise a {this.state.frame_style.title} frame
            </h2>

            <div className="pb-5 px-4 md:px-12">
              <div className="md:grid grid-cols-2 gap-x-16">
                <div className="max-w-sm my-5">
                  <div className="relative grid grid-cols-5 gap-x-4 gap-y-10 md:block">
                    <div className="col-span-2 overflow-hidden rounded-md  group-hover:opacity-75 max-w-xs">
                      <img src={this.props.icon_print_frame} className="w-22" />
                    </div>
                    <a
                      href={this.props.url_print_frame}
                      className="col-span-3 md:mt-3 block text-sm text-stone-900 text-left md:text-center"
                    >
                      <span
                        className="absolute inset-0 z-10"
                        aria-hidden="true"
                      />
                      <span className=" font-bold text-lg">
                        Frame a digital image
                      </span>
                      <div className="small text-muted help-text">
                        Upload a digital image and we'll print and frame it.
                      </div>
                      <div className="btn btn-primary btn-sm mt-3">
                        Continue
                        <i class="fa-solid fa-caret-right text-xs ml-2"></i>
                      </div>
                    </a>
                  </div>
                </div>
                {this.props.can_frame_only && (
                  <div className="max-w-sm  my-5">
                    <div className="relative grid grid-cols-5 gap-x-4 gap-y-10 md:block">
                      <div className="col-span-2 overflow-hidden rounded-md  group-hover:opacity-75 max-w-xs">
                        <img
                          src={this.props.icon_frame_only}
                          className="w-22"
                        />
                      </div>
                      <a
                        href={this.props.url_frame_only}
                        className="col-span-3 md:mt-3 block text-sm text-stone-900 text-left md:text-center"
                      >
                        <span
                          className="absolute inset-0 z-10"
                          aria-hidden="true"
                        />
                        <span className=" font-bold text-lg">
                          Frame a print/poster
                        </span>
                        <div className="small text-muted help-text">
                          Customise an empty frame to fit posters, prints &
                          more.
                        </div>
                        <div className="btn btn-primary btn-sm mt-3">
                          Continue
                          <i class="fa-solid fa-caret-right text-xs ml-2"></i>
                        </div>
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="bg-pale p-8 pb-12">
              <p className="font-bold mb-0 mt-3 text-lg">
                Other ways to frame digital photos
              </p>
              <p className="text-muted text-xs">
                (These products are available in limited frame styles that may
                not include {this.state.frame_style.title})
              </p>

              <div>
                <div className="md:grid grid-cols-3 gap-x-6">
                  <div className="max-w-sm my-5">
                    <div className="relative grid grid-cols-5 gap-x-4 gap-y-10 md:block">
                      <div className="col-span-2 overflow-hidden rounded-md  group-hover:opacity-75 max-w-xs">
                        <img src={this.props.icon_gallery_walls} className="" />
                      </div>
                      <a
                        href={this.props.url_gallery_walls}
                        className="col-span-3 mt-3 block text-sm text-stone-900 text-left md:text-center"
                      >
                        <span
                          className="absolute inset-0 z-10"
                          aria-hidden="true"
                        />
                        <span className="text-brand-600 font-bold text-lg">
                          Gallery Walls
                        </span>
                        <div className="small text-muted help-text">
                          Stunning arrangements of framed photos. Easy to hang.
                        </div>
                      </a>
                    </div>
                  </div>

                  <div className="max-w-sm my-5">
                    <div className="relative grid grid-cols-5 gap-x-4 gap-y-10 md:block">
                      <div className="col-span-2 overflow-hidden rounded-md  group-hover:opacity-75 max-w-xs">
                        <img
                          src={this.props.icon_collage_frames}
                          className=""
                        />
                      </div>
                      <a
                        href={this.props.url_collage_frames}
                        className="col-span-3 mt-3 block text-sm text-stone-900 text-left md:text-center"
                      >
                        <span
                          className="absolute inset-0 z-10"
                          aria-hidden="true"
                        />
                        <span className="text-brand-600 font-bold text-lg">
                          Multi-Photo Frames
                        </span>
                        <div className="small text-muted help-text">
                          Ready-to-hang multi-photo frames.
                        </div>
                      </a>
                    </div>
                  </div>

                  <div className="max-w-sm my-5">
                    <div className="relative grid grid-cols-5 gap-x-4 gap-y-10 md:block">
                      <div className="col-span-2 overflow-hidden rounded-md  group-hover:opacity-75 max-w-xs">
                        <img
                          src={this.props.icon_caption_frames}
                          className=""
                        />
                      </div>
                      <a
                        href={this.props.url_caption_frames}
                        className="col-span-3 mt-3 block text-sm text-stone-900 text-left md:text-center"
                      >
                        <span
                          className="absolute inset-0 z-10"
                          aria-hidden="true"
                        />
                        <span className="text-brand-600 font-bold text-lg">
                          Caption Frames
                        </span>
                        <div className="small text-muted help-text">
                          Personalise a treasured moment with a meaningful date
                          or something fun.
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default FrameShow;
