import React from "react";
import ReactDOM from "react-dom";
import NumberFormat from "react-number-format";

const CurrencyDisplay = ({ value, show_prefix }) => {
  return (
    <NumberFormat
      value={value}
      displayType={"text"}
      decimalScale={2}
      prefix={show_prefix != false && global.App.currencySymbol}
    />
  );
};

export default CurrencyDisplay;
