import { Theme } from "react-select";

const SelectTheme = (theme: Theme): Theme => ({
  ...theme,
  borderRadius: 3,
  colors: {
    ...theme.colors,
    primary: "#af8e7a",
    primary25: "#eee",
  },
  spacing: {
    ...theme.spacing,
    controlHeight: 48,
  },
});

export default SelectTheme;
