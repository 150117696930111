import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import Modal from "react-responsive-modal";
import AfterpayBreakdown from "components/afterpay/AfterpayBreakdown";
import AddToCartButton from "components/frame-designer/designers/AddToCartButton";
import { RadioGroup } from "@headlessui/react";

class GlassModalButton extends React.Component {
  constructor(props) {
    super();
    this.state = {
      modalIsOpen: false,
      glass_prices: props.glass_prices,
      glass_types: props.glass_types,
      glass_type: props.glass_type,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    return (
      <div>
        {this.props.glass_prices && this.props.glass_types.length > 1 ? (
          <AddToCartButton
            price={this.props.price}
            tax_rate={this.props.tax_rate}
            onClickEvent={this.openModal}
            buttonText="Finalise"
            disabled={this.props.priceLoading}
            afterpayLogo={this.props.afterpayUI.logo_white}
            isLoading={this.props.priceLoading}
          />
        ) : (
          <AddToCartButton
            price={this.props.price}
            tax_rate={this.props.tax_rate}
            onClickEvent={this.props.addToCart}
            buttonText="Add to cart"
            disabled={
              this.props.savingInProgress || this.props.outstandingTasks
            }
            afterpayLogo={this.props.afterpayUI.logo_white}
            isLoading={this.props.savingInProgress}
          />
        )}
        <Modal
          open={this.state.modalIsOpen}
          onOpen={this.afterOpenModal}
          onClose={this.closeModal}
          classNames={{
            overlay: "custom-overlay",
            modal: "custom-modal modal-small",
          }}
        >
          <div className="modal-scroll">
            <GlassSelectionRadio
              setActive={this.props.setActive}
              glass_prices={this.props.glass_prices}
              glass_types={this.props.glass_types}
              glass_type={this.props.glass_type}
            />

            {this.props.error && (
              <div className="mt-2 alert alert-danger">{this.props.error}</div>
            )}
            <div className="mt-3">
              <AddToCartButton
                price={this.props.price}
                tax_rate={this.props.tax_rate}
                onClickEvent={this.props.addToCart}
                buttonText="Add to cart"
                disabled={this.props.savingInProgress}
                afterpayLogo={this.props.afterpayUI.logo_white}
                isLoading={this.props.savingInProgress}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const GlassSelectionRadio = (props) => (
  <div>
    <div className="text-center">
      <h2>Please select a glaze</h2>
      <p className="text-muted text-sm mb-5 mt-2">
        Glazing materials, typically glass or acrylic, protect framed art from
        dust and damage.
      </p>
    </div>

    <RadioGroup value={props.glass_type} onChange={(e) => props.setActive(e)}>
      <div className="space-y-4">
        {props.glass_types.map((radio, i) => (
          <RadioGroup.Option
            value={radio}
            key={radio.id}
            className={({ checked, active }) =>
              classNames(
                checked ? "border-transparent" : "border-white",
                active ? "border-brand-600 ring-2 ring-brand-600" : "",
                "flex bg-brand-100 relative cursor-pointer rounded-lg border px-4 py-4 focus:outline-none"
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked
                      ? "bg-brand-600 border-transparent"
                      : "bg-white border-gray-300",
                    active ? "ring-2 ring-offset-2 ring-brand-600" : "",
                    "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <span className="flex items-center ml-3 grow">
                  <span className="flex flex-col text-sm">
                    <RadioGroup.Label
                      as="span"
                      className="font-bold text-gray-900"
                    >
                      {radio.title}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="text-gray-500">
                      <span className="block sm:inline">
                        {radio.description}
                      </span>
                    </RadioGroup.Description>
                  </span>
                </span>
                <RadioGroup.Description
                  as="span"
                  className="mt-2 flex text-sm sm:ml-4 sm:mt-0 sm:flex-col sm:text-right"
                >
                  <span className="font-bold text-gray-900">
                    {props.glass_prices[i].price_jump != "$0"
                      ? props.glass_prices[i].price_jump
                      : null}
                  </span>
                </RadioGroup.Description>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  </div>
);

export default GlassModalButton;
