import React from "react";
import reactCSS from "reactcss";
import { GithubPicker } from "react-color";
import { searchColReseneMapping } from "components/art/constants";

class ColourSearchPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pickerShowing: false,
      searchColReseneMapping: searchColReseneMapping,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleClick() {
    this.setState({ pickerShowing: !this.state.pickerShowing });
  }

  handleClose() {
    this.setState({ pickerShowing: false });
  }

  handleSelect(col) {
    const colObj = this.state.searchColReseneMapping.find(
      (item) => item.color == col.hex.toUpperCase()
    );
    // this.props.onChangeComplete(colObj)
  }

  render() {
    const styles = reactCSS({
      default: {
        button: {
          background: `${this.props.color && this.props.color.color}`,
          borderColor: `${this.props.color && this.props.color.color}`,
          cursor: "pointer",
        },
        color: {
          width: "36px",
          height: "14px",
          borderRadius: "2px",
          background: `${this.props.color && this.props.color.color}`,
        },
        popover: {
          position: "absolute",
          zIndex: "10",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });

    const { color } = this.props;

    return (
      <div>
        <div
          style={styles.button}
          className="text-left btn-block btn btn-primary"
          onClick={this.handleClick}
        >
          <i className="fa-solid fa-fill-drip"></i>{" "}
          {color ? color.title : "Search by colour"}
        </div>
        {this.state.pickerShowing ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <GithubPicker
              width={313}
              className="mt-3"
              triangle="top-left"
              color={this.props.color && this.props.color.color}
              colors={searchColReseneMapping.map((p) => p.color)}
              onSwatchHover={this.onHover}
              onChange={this.handleSelect}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ColourSearchPicker;
