import React from "react";
import ReactDOM from "react-dom";
import SwiperCore, { Navigation, Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay, Pagination]);

class Reviews extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination
        autoplay={{
          delay: 5000,
        }}
        loop={true}
      >
        <SwiperSlide>
          <div className="carousel-review d-block text-center w-100">
            <img src={this.props.stars} />
            <p className="mt-3 lead">
              "Thanks for fast and efficient service. The Grace frame is perfect
              for my classical print. The whole process from ordering to getting
              the print on the wall was super easy."
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="carousel-review d-block text-center w-100">
            <img src={this.props.stars} />
            <p className="mt-3 lead">
              "We’ve now ordered three frames from Framefox and will definitely
              order more. Great product, easy to order and prompt delivery.
              Really pleased we found you!"
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="carousel-review d-block text-center w-100">
            <img src={this.props.stars} />
            <p className="mt-3 lead">
              "Great quality and such a faithful reproduction, I’m absolutely
              stoked with my panorama. Thanks guys!!"
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="carousel-review d-block text-center w-100">
            <img src={this.props.stars} />
            <p className="mt-3 lead">
              "Love it, was so easy to order and choose. Then it arrived so
              quickly and looks amazing!"
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
    );
  }
}

export default Reviews;
