import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import cloudinary from "cloudinary-core";
import { TwitterPicker } from "react-color";
import { Tooltip } from "react-tippy";

class Personalisation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props.options,
      overlays: props.overlays,
      canvas: props.canvas_details,
    };
    this.renderImageUrl = this.renderImageUrl.bind(this);
    this.textsUpdated = this.textsUpdated.bind(this);
  }

  componentDidMount() {
    this.renderImageUrl();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.current_image.preview_url !==
      this.props.current_image.preview_url
    ) {
      this.renderImageUrl();
    }
  }

  renderImageUrl() {
    var cl = new cloudinary.Cloudinary({
      cloud_name: "framefox",
      secure: true,
    });
    let lpx = this.state.canvas.longest_pixels;
    let canvas = this.state.canvas;

    let transformations = [
      { height: canvas.image_h, width: canvas.image_w, crop: "fit" },
      {
        height: canvas.canvas_h,
        width: canvas.canvas_w,
        crop: "lpad",
        y: canvas.offset_y,
      },
    ];

    if (this.props.overlays) {
      this.props.overlays.map((item) => {
        transformations.push({
          overlay: new cloudinary.Layer().publicId(item.public_id),
          gravity: item.gravity,
          y: parseInt(item.offset_y * lpx),
          x: parseInt(item.offset_x * lpx),
          width: parseInt(item.scale * lpx),
        });
      });
    }

    let validLayers = this.state.options.filter(function (layer) {
      return layer.text_body != "";
    });

    validLayers.map((item) => {
      let body = item.uppercase ? item.text_body.toUpperCase() : item.text_body;
      body = body.replace(/\r?\n/g, "%0A");
      transformations.push({
        overlay: new cloudinary.TextLayer()
          .textAlign("center")
          .fontFamily(item.font)
          .fontWeight(item.f_weight)
          .letterSpacing(parseInt(item.l_spacing * lpx))
          .fontSize(parseInt(item.size * lpx))
          .text(body),
        gravity: item.gravity,
        y: parseInt(item.offset_y * lpx),
        x: parseInt(item.offset_x * lpx),
        color: item.colour,
        crop: "fit",
        width: parseInt(canvas.canvas_w * 0.9),
      });
    });

    var url = cl.url(this.props.current_image.preview_url, {
      type: "fetch",
      transformation: transformations,
    });

    this.props.onUpdate(this.state.options, url);
  }

  textsUpdated(options) {
    this.setState(
      { options: options },
      function () {
        this.renderImageUrl();
      }.bind(this)
    );
  }

  render() {
    return (
      <div>
        <PersonalisationTexts
          options={this.state.options}
          imageURL={this.props.current_image.source}
          textsUpdated={this.textsUpdated}
        />
      </div>
    );
  }
}

class PersonalisationTexts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: this.props.options,
    };
    this.textUpdate = this.textUpdate.bind(this);
    this.onUpdateColour = this.onUpdateColour.bind(this);
  }

  textUpdate(text, i) {
    let ids = [...this.state.options];
    let index = ids.findIndex((el) => el.id == i);
    ids[index].text_body = text;
    this.props.textsUpdated(ids);
  }

  onUpdateColour(colour, i) {
    let ids = [...this.state.options];
    let index = ids.findIndex((el) => el.id == i);
    ids[index].colour = colour;
    this.props.textsUpdated(ids);
  }

  render() {
    return (
      <div className="mb-5">
        <div>
          <div className="text-stone-900 mb-2 text-sm">
            Next: <span className="text-muted">Edit Text</span>
          </div>
          <div className="">
            {this.state.options.map(
              function (text, i) {
                return (
                  <div className="mb-2" key={text.id}>
                    <PersonalisationText
                      text={text}
                      onUpdateColour={this.onUpdateColour}
                      imageURL={this.props.imageURL}
                      textUpdate={this.textUpdate}
                    />
                  </div>
                );
              }.bind(this)
            )}
          </div>
        </div>
      </div>
    );
  }
}

class PersonalisationText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text_body: props.text.text_body ? props.text.text_body : "",
      text_body_persisted: props.text.text_body,
      focussed: false,
      colour: props.text.colour,
      id: props.text.id,
      remaining_char: props.text.max_char - props.text.text_body.length,
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.textFocus = this.textFocus.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.confirm = this.confirm.bind(this);
    this.onUpdateColour = this.onUpdateColour.bind(this);
  }

  changeHandler(e) {
    if (this.props.text.max_char >= e.target.value.length) {
      this.setState({
        text_body: e.target.value,
        remaining_char: this.props.text.max_char - e.target.value.length,
      });
    }
  }

  textFocus(e) {
    e.preventDefault();
    this.setState({ focussed: true });
  }

  cancelEdit(e) {
    e.preventDefault();
    this.setState({
      focussed: false,
      text_body: this.state.text_body_persisted,
    });
    this.props.textUpdate(this.state.text_body_persisted, this.state.id);
  }

  confirm(e) {
    e.preventDefault();
    this.setState({
      focussed: false,
      text_body: this.state.text_body,
      text_body_persisted: this.state.text_body,
    });
    this.props.textUpdate(this.state.text_body, this.state.id);
  }

  onUpdateColour(colour) {
    this.setState({ colour: colour });
    this.props.onUpdateColour(colour, this.state.id);
  }

  render() {
    var hidden = this.state.focussed ? null : "d-none";
    var disabled = this.state.isLoading ? "disabled" : null;
    var classes = classNames("btn btn-primary my-1", disabled, hidden);

    return (
      <div>
        {!this.state.focussed ? (
          <div>
            {this.state.text_body == "" ? (
              <a href="#" className="font-weight-bold" onClick={this.textFocus}>
                <i className="fa-solid fa-plus"></i> Add text
              </a>
            ) : (
              <div className="flex">
                <div className="grow">
                  <div
                    className="p-3 rounded bg-stone-100 font-bold"
                    onClick={this.textFocus}
                  >
                    <i className="fa-solid fa-bullseye-pointer mr-2 text-brand-600"></i>
                    {this.state.text_body}
                  </div>
                </div>
                <div>
                  <TextColourPicker
                    colour={this.state.colour}
                    imageURL={this.props.imageURL}
                    onUpdateColour={this.onUpdateColour}
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="p-4 bg-yellow-50 rounded">
            {this.props.text.multiline ? (
              <textarea
                className="form-control"
                value={this.state.text_body}
                rows="3"
                placeholder={"Personalise here..."}
                onChange={this.changeHandler}
                autoFocus
              />
            ) : (
              <input
                className="form-control"
                value={this.state.text_body}
                placeholder={"Personalise here..."}
                onChange={this.changeHandler}
                autoFocus
              />
            )}
            <div className="my-1 small text-muted">
              {this.state.remaining_char} remaining
            </div>
            <div>
              <button onClick={this.confirm} className={classes} type="submit">
                Save
              </button>
              <button
                onClick={this.cancelEdit}
                className="btn btn-secondary ml-2"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

class TextColourPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pickerShowing: false,
      colours: [
        "#FFFFFF",
        "#000000",
        "#aaaaaa",
        "#c5f2da",
        "#a3cce6",
        "#102f55",
        "#ebdcce",
        "#9b4e21",
        "#f4a23f",
        "#ff9d8e",
      ],
    };
    this.convertColors = this.convertColors.bind(this);
    this.rgbToHex = this.rgbToHex.bind(this);
    this.handleChangeComplete = this.handleChangeComplete.bind(this);
    this.togglePicker = this.togglePicker.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentDidMount() {
    $.ajax({
      method: "GET",
      url: this.props.imageURL + "-/preview/-/main_colors/",
    }).done(
      function (data) {
        this.convertColors(data);
      }.bind(this)
    );
  }

  convertColors(data) {
    let hexs = data.main_colors.map((item) => {
      return this.rgbToHex(item[0], item[1], item[2]);
    });
    let cols = this.state.colours;
    this.setState({ colours: hexs.concat(cols) });
  }

  rgbToHex(r, g, b) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }

  handleChangeComplete(color, event) {
    this.props.onUpdateColour(color.hex);
  }

  togglePicker(e) {
    e.preventDefault();
    if (!this.state.pickerShowing) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    this.setState({ pickerShowing: !this.state.pickerShowing });
  }

  handleOutsideClick(e) {
    if (e.target.classList.contains("picker-toggle-icon")) {
      return;
    }
    this.togglePicker(e);
  }

  render() {
    return (
      <div className=" relative inline-block mt-2">
        <a
          href="#"
          onClick={this.togglePicker}
          className="w-8 h-8 rounded-full block ml-3 shadow-md picker-toggle-icon"
          style={{ backgroundColor: this.props.colour }}
        ></a>
        {this.state.pickerShowing && (
          <div ref={(node) => (this.node = node)}>
            <TwitterPicker
              display={this.state.pickerShowing}
              triangle="top-right"
              color={this.props.colour}
              colors={this.state.colours}
              onClose={this.togglePicker}
              onChangeComplete={this.handleChangeComplete}
            />
          </div>
        )}
      </div>
    );
  }
}

export default Personalisation;
