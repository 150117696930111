import React from 'react';
import ReactDOM from 'react-dom';

const ShippingMethodShow = (props) => {
  return (
    <div className="row">
      <div className="col-18">
        <div>Shipping: <span className="text-muted">{props.shipping_method.title}</span> <span className="badge badge-default badge-sm">{props.shipping_method.size}</span>
          </div>
      </div>
      <div className="col-6">
        <div className="h5 text-right font-weight-normal shipping-price mb-0" >
          { props.has_price_overide ? "N/A": props.shipping_method.price }
        </div>
      </div>
    </div>
  )
}


export default ShippingMethodShow

