import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { Tooltip } from "react-tippy";
import { isMobile } from "react-device-detect";
import cloudinary from "cloudinary-core";
import Toggle from "react-toggle";
import { RadioGroup } from "@headlessui/react";

class EffectsControls extends React.Component {
  constructor(props) {
    super();
    this.state = {
      filter: props.effects.filter,
      viesus_correct:
        props.effects.viesus_correct == null
          ? true
          : props.effects.viesus_correct,
    };
    this.handleFilter = this.handleFilter.bind(this);
    this.toggleViesus = this.toggleViesus.bind(this);
    this.newEffects = this.newEffects.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
  }

  componentDidMount() {
    this.newEffects();
  }

  handleFilter(f) {
    if (this.state.filter == f) {
      f = null;
    }

    this.setState({ filter: f }, () => this.newEffects());
  }

  newEffects() {
    let effects = {
      filter: this.state.filter,
      viesus_correct: this.state.viesus_correct,
    };
    this.props.effectsUpdate(effects);
  }

  toggleViesus() {
    this.setState({ viesus_correct: !this.state.viesus_correct }, () =>
      this.newEffects()
    );
  }

  renderThumb(f) {
    let cl = new cloudinary.Cloudinary({
      cloud_name: "framefox",
      secure: true,
    });
    let filter = "art:" + f;
    let url = cl.url(this.props.url, {
      type: "fetch",
      transformation: [
        { width: 80, height: 80, crop: "fill" },
        { effect: filter },
      ],
    });
    return url;
  }

  render() {
    const { viesus_correct } = this.state;

    return (
      <div>
        <div className="mt-4">
          <div className="my-3">
            <Toggle
              id="orientation"
              icons={false}
              defaultChecked={viesus_correct}
              onChange={this.toggleViesus}
            />
            <label
              style={{ top: "-8px", position: "relative" }}
              className="ml-2 mini-heading"
              htmlFor="orientation"
            >
              <span className="text-dark">
                <i className="fa-solid fa-wand-magic-sparkles"></i>{" "}
                {viesus_correct ? "Smart Enhance On" : "Enhance Off"}
              </span>
            </label>
            <p className="text-sm mb-8 mt-3 text-muted">
              Automatically adjusts brightness & color, restores sharpness,
              removes noise and corrects for overexposure or underexposure.
            </p>
          </div>
        </div>
        <div className="mb-5 max-w-[20.5rem] mx-auto">
          <p className="text-sm font-bold mb-4">
            <i className="fa-solid fa-glasses-round mr-1"></i> Image Filters
          </p>
          {this.state.filter && (
            <button
              onClick={this.handleFilter.bind(this, null)}
              type="button"
              className="btn btn-pale float-right w-12 h-12"
            >
              <i className="fa-solid fa-xmark text-brand-600 text-lg"></i>
            </button>
          )}

          <RadioGroup
            value={this.state.filter}
            onChange={(e) => this.handleFilter(e)}
          >
            <div className="">
              {this.props.filters.map((filter) => (
                <RadioGroup.Option
                  key={filter}
                  value={filter}
                  className={({ active, checked }) =>
                    classNames(
                      active ? "ring-2 ring-brand-600 ring-offset-2" : "",
                      checked
                        ? "ring-2 ring-brand-600 ring-offset-2"
                        : "ring-1 ring-inset ring-white bg-white text-gray-900 hover:bg-gray-50",
                      "items-center justify-center rounded-md mr-2 mb-2 text-sm font-semibold  inline-block cursor-pointer"
                    )
                  }
                >
                  <RadioGroup.Label as="span">
                    <Tooltip
                      title={filter.charAt(0).toUpperCase() + filter.slice(1)}
                      arrow={true}
                      trigger="mouseenter"
                      key={filter}
                    >
                      <img
                        src={this.renderThumb(filter)}
                        className="rounded-md w-12 h-12 block"
                      />
                    </Tooltip>
                  </RadioGroup.Label>
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>
      </div>
    );
  }
}

export default EffectsControls;
