const searchCols = [
  "#e6c1be",
  "#574039",
  "#e2855e",
  "#e0c4b2",
  "#ac8a64",
  "#bd9769",
  "#ebd07f",
  "#7f8765",
  "#aec98e",
  "#359369",
  "#176352",
  "#426972",
  "#38afcd",
  "#99b1cb",
  "#2f5e97",
  "#2b2e43",
  "#6a6378",
  "#473854",
  "#875287",
  "#58304e",
  "#ac6075",
  "#6c2135",
  "#e3768c",
  "#ae2935",
];

const roomConfigs = [
  { id: 0 },
  {
    background: "wbh9lhaopsi2roi9fxdj",
    maxFrameSize: 500,
    x: -150,
    y: 150,
    id: 3,
    effect: "10:a4797d",
  },
  {
    background: "xtktenc2qrm8rtxr632f",
    maxFrameSize: 500,
    x: -150,
    y: 150,
    id: 3,
    effect: "10:a4797d",
  },
  {
    background: "xmm0oxvyunabvh1k6d0k",
    maxFrameSize: 500,
    x: 0,
    y: 130,
    id: 4,
    effect: "20:5088ab",
  },
  {
    background: "bquqju9vcjevgh4rtzak",
    maxFrameSize: 500,
    x: 0,
    y: 150,
    id: 5,
    effect: "20:5088ab",
  },
  {
    background: "t67xobiuom3aamtiewur",
    maxFrameSize: 500,
    x: 145,
    y: 170,
    id: 6,
    effect: "0:5088ab",
  },
];

const framePreviewURL = ({
  mat_width,
  width,
  height,
  artwork_url,
  style,
  moulding_width,
  frame_type,
  maxPX,
}) => {
  // const ratio = (width / height).toFixed(2)
  // const w = ratio > 1 ? 800 : 800 * ratio
  // const h = ratio > 1 ? 800 / ratio : 800

  const params = {
    artwork: artwork_url,
    pattern: style,
    mouldingWidth: moulding_width,
    artWidthMM: width,
    artHeightMM: height,
    matL: mat_width,
    matR: mat_width,
    matT: mat_width,
    matB: mat_width,
    matColor: "#fff",
    matCore: "#fff",
    maxPX: maxPX,
    frameType: frame_type,
  };

  const queryString = Object.keys(params)
    .map((key) => key + "=" + encodeURIComponent(params[key]))
    .join("&");
  return `${global.App.framePreviewURL}${queryString}`;
};

const MODE = {
  DEFAULT: "noop",
  PAPER: "paper",
  ROOM: "room-preview",
  FRAME_STYLE: "frame-style",
  BORDERS: "borders",
  GLASS: "glass",
};

const FRAME_OPTION = {
  BORDERS: "framed_border",
  NO_BORDER: "framed_no_border",
  UNFRAMED: "unframed",
  CANVAS: "canvas",
};

const MAT_STYLE_MAPPING = {
  framed_border: 16,
  framed_no_border: 1,
  unframed: 16,
  canvas: 1,
};

const searchColReseneMapping = [
  { title: "Dust Storm", color: "#E9D1CB", base: "#e6c1be" },
  { title: "Brown Derby", color: "#594537", base: "#574039" },
  { title: "Roxy", color: "#EB9A62", base: "#e2855e" },
  { title: "Half Sour Dough", color: "#E3D0C0", base: "#e0c4b2" },
  { title: "Backcountry", color: "#AB8E6B", base: "#ac8a64" },
  { title: "Flesh", color: "#CCA483", base: "#bd9769" },
  { title: "Marzipan", color: "#F4CD9A", base: "#ebd07f" },
  { title: "Flax", color: "#98967E", base: "#7f8765" },
  { title: "Caper", color: "#C1C79B", base: "#aec98e" },
  { title: "Deep Sea", color: "#167E65", base: "#359369" },
  { title: "Salem", color: "#177B4D", base: "#176352" },
  { title: "Streetwise", color: "#658086", base: "#426972" },
  { title: "Aquarius", color: "#5FB8CD", base: "#38afcd" },
  { title: "Relax", color: "#A8BFD7", base: "#99b1cb" },
  { title: "St Tropaz", color: "#4372A0", base: "#2f5e97" },
  { title: "Black Rock", color: "#444958", base: "#2f5e97" },
  { title: "Dolphin", color: "#7C7E88", base: "#6a6378" },
  { title: "Violent Violet", color: "#2E2249", base: "#473854" },
  { title: "De Janeiro", color: "#97829A", base: "#875287" },
  { title: "Fandango", color: "#5A3851", base: "#58304e" },
  { title: "Can Can", color: "#D08A9B", base: "#ac6075" },
  { title: "Hot Chile", color: "#8D4340", base: "#6c2135" },
  { title: "Carissma", color: "#E79CA6", base: "#e3768c" },
  { title: "Del Toro", color: "#B11D20", base: "#ae2935" },
];

const defaultColourOptions = [
  { color: "#F3F3F0", label: "Quarter Black White" },
  { color: "#EBE9E5", label: "Black White" },
  { color: "#C4CBC8", label: "Duck Egg Blue" },
  { color: "#D6D1C6", label: "Half Linen" },
  { color: "#CEC4B8", label: "Tea" },
  { color: "#C59E7B", label: "Gold Coast" },
  { color: "#4C4F52", label: "Foundry" },
  { color: "#4B5963", label: "Coast" },
  { color: "#835142", label: "Hairy Heath" },
  { color: "#716960", label: "Mondo" },
];

const colourOptions = [
  { label: "Resene Afghan Tan", color: "#B47E3B" },
  { label: "Resene Amazon", color: "#4C8B6E" },
  { label: "Resene Antique Brass", color: "#926742" },
  { label: "Resene Aqua", color: "#A1DAD5" },
];

export {
  MODE,
  FRAME_OPTION,
  MAT_STYLE_MAPPING,
  framePreviewURL,
  roomConfigs,
  colourOptions,
  defaultColourOptions,
  searchCols,
  searchColReseneMapping,
};
