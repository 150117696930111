import { Controller } from "stimulus";
import { Stripe } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { meta, appendHTMLString } from "../utils";
import {
  DISABLE_WITH,
  ERRORS,
  PROCESSING_OVERLAY,
  STYLE,
} from "./payment_form_controller/constants";

class PaymentFormController extends Controller {
  static targets = ["applyCredit", "cardErrors", "submit", "loadingIndicator"];

  processingOverlay: HTMLElement | null = null;

  private submitTargetEnableWith: string = "";

  // Black magic from:
  //    https://github.com/hotwired/stimulus/issues/303
  declare applyCreditTarget: HTMLElement | null;
  declare hasApplyCreditTarget: boolean;
  declare cardErrorsTarget: HTMLElement;
  declare submitTarget: HTMLInputElement;
  declare loadingIndicatorTarget: HTMLElement;

  connect() {
    const stripePK = meta("stripe-key");

    this.processingOverlay = appendHTMLString(
      document.body,
      PROCESSING_OVERLAY
    );

    this.submitTargetEnableWith = this.submitTarget.value;

    if (stripePK) {
      loadStripe(stripePK).then((stripe: Stripe | null) => stripe && this.setup(stripe));
      this.submitTarget.disabled = false;
    } else {
      console.error(ERRORS["stripe_key_missing"]);
    }
  }

  setup(stripe: Stripe) {
    const form: HTMLFormElement = this.element as HTMLFormElement;
    const clientSecret = form.dataset.clientSecret;
    const elements = stripe.elements({ clientSecret, appearance: STYLE });
    const paymentElement = elements.create("payment");

    paymentElement.mount("#payment-element");

    if (this.loadingIndicatorTarget) {
      this.loadingIndicatorTarget.classList.add("d-none");
    }

    form.addEventListener("submit", async (event) => {
      event.preventDefault();

      this.processing();
      this.clearError();

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${form.dataset.returnUrl}`,
        },
      });

      if (error) {
        this.showError(`${error.message}`);
        this.processing(false);
      } else {
        // Customer will be redirected to your `return_url`. For some payment
        // methods like AfterPay/ClearPay, the customer will be redirected to
        // an intermediate site first to authorize the payment, then redirected
        // to the `return_url`.
      }
    });
  }

  showError(message: string) {
    this.cardErrorsTarget.classList.remove("d-none");
    this.cardErrorsTarget.textContent = message;
  }

  clearError() {
    this.cardErrorsTarget.classList.add("d-none");
    this.cardErrorsTarget.innerHTML = "";
  }

  processing(isProcessing: boolean = true) {
    this._toggleOverlay(isProcessing);
    this._disableDangerousActions(isProcessing);
  }

  _toggleOverlay(toggle: boolean = true) {
    if (!this.processingOverlay) return;

    if (toggle) {
      this.processingOverlay.classList.replace("hidden", "block");
      document.body.classList.add("modal-open");
    } else {
      this.processingOverlay.classList.replace("block", "hidden");
      document.body.classList.remove("modal-open");
    }
  }

  _disableDangerousActions(toggle: boolean = true) {
    if (toggle) {
      this.submitTarget.disabled = true;
      this.submitTarget.value = DISABLE_WITH;

      if (this.hasApplyCreditTarget) {
        // @ts-ignore
        this.applyCreditTarget.classList.add("disabled");
      }
    } else {
      this.submitTarget.disabled = false;
      this.submitTarget.value = this.submitTargetEnableWith;

      if (this.hasApplyCreditTarget) {
        // @ts-ignore
        this.applyCreditTarget.classList.remove("disabled");
      }
    }
  }
}

export default PaymentFormController;
