import React, { useState } from "react";
import ReactDOM from "react-dom";
import Modal from "react-responsive-modal";
import { Tooltip } from "react-tippy";

const MeasureHelpButton = (props) => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <div>
      <Tooltip
        title="⚠️ Important!"
        open={props.callout}
        arrow={true}
        distance={20}
        position="right"
      >
        <a className="btn btn-light" onClick={onOpenModal} href="#">
          Read our measuring guide
        </a>
      </Tooltip>
      <Modal
        open={open}
        onClose={onCloseModal}
        classNames={{
          overlay: "custom-overlay",
          modal: "custom-modal bg-pale",
        }}
      >
        <MeasureHelp {...props} closeModal={onCloseModal} />
      </Modal>
    </div>
  );
};

class MeasureHelp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      section: 0,
      buttonText: [
        "Step 2: Need a mat?",
        "Step 3: Technical Stuff",
        "Step 4: Summary",
        "OK, close this guide",
      ],
    };

    this.next = this.next.bind(this);
    this.back = this.back.bind(this);
    this.renderStep = this.renderStep.bind(this);
    this.renderBack = this.renderBack.bind(this);
  }

  next() {
    if (this.state.section == 3) {
      this.props.closeModal();
    } else {
      this.setState({ section: this.state.section + 1 });
    }
  }

  back() {
    this.setState({ section: this.state.section - 1 });
  }

  renderStep() {
    if (this.state.section == 0) {
      return (
        <div className="row text-center justify-content-center">
          <div className="col-sm-18">
            <h4>
              Measure the width and height of your artwork in mm, cm or inches.
            </h4>
            <img src={this.props.image1} className="img-fluid" />
          </div>
        </div>
      );
    } else if (this.state.section == 1) {
      return (
        <div className="row text-center justify-content-center">
          <div className="col-sm-18">
            <h4>
              If you add a mat it will surround your artwork and overlap by 3mm
              on each side.
            </h4>
            <img src={this.props.image2} className="img-fluid" />
          </div>
        </div>
      );
    } else if (this.state.section == 2) {
      return (
        <div className="row text-center justify-content-center">
          <div className="col-sm-18">
            <h4>
              If your artwork has a title or signature you'd like to show, bear
              this in mind when measuring.
            </h4>
            <img src={this.props.image3} className="img-fluid" />
          </div>
        </div>
      );
    } else if (this.state.section == 3) {
      return (
        <div className="row text-center justify-content-center">
          <div className="col-sm-18">
            <h4 className="my-4">
              If you're still uncertain about what measurements to enter just{" "}
              <a href="/contact" id="">
                contact us
              </a>{" "}
              and we'll be happy to help.
            </h4>
          </div>
        </div>
      );
    }
  }

  renderBack() {
    return (
      <a
        onClick={this.back}
        href="#"
        className="btn btn-secondary btn-sm float-left mt-3"
      ></a>
    );
  }

  render() {
    return (
      <div className="row text-center justify-content-center">
        <div className="col-sm-24">
          {this.renderStep()}
          {this.state.section != 0 ? this.renderBack() : null}
          <a onClick={this.next} href="#" className="btn btn-primary mt-3">
            {this.state.buttonText[this.state.section]}{" "}
          </a>
        </div>
      </div>
    );
  }
}

export default MeasureHelpButton;
