import React from "react";
import ReactDOM from "react-dom";
import uploadcare from "uploadcare-widget";
import { isMobile } from "react-device-detect";
import { Tooltip } from "react-tippy";
import Modal from "react-responsive-modal";
import classNames from "classnames";

class MultiImagePicker extends React.Component {
  constructor(props) {
    super();
    this.state = {
      fromSources: "file facebook instagram dropbox url",
      art_type_id: props.art_type_id,
      images_to_save: [],
      undersizedImages: false,
    };
    this.pushImageIntoState = this.pushImageIntoState.bind(this);
    this.sizeWarning = this.sizeWarning.bind(this);
    this.saveToDatabase = this.saveToDatabase.bind(this);
    this.saveSuccess = this.saveSuccess.bind(this);
    this.saveFail = this.saveFail.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    let widget = uploadcare.Widget("[role=uploadcare-uploader]");

    const maxResolution = (fileInfo) => {
      if (fileInfo.originalImageInfo) {
        if (
          fileInfo.originalImageInfo.width * fileInfo.originalImageInfo.height >
          75000000
        ) {
          throw new Error("maxResolutionError");
        }
      }
    };

    const maxFileSize = (fileInfo) => {
      if (fileInfo.size !== null && fileInfo.size > 100 * 1024 * 1024) {
        throw new Error("maxFileSizeError");
      }
    };

    widget.onChange((groupInstance) => {
      if (groupInstance) {
        groupInstance.files().forEach((file) => {
          file.done((fileInfo) => {
            this.pushImageIntoState(fileInfo, groupInstance.files().length);
          });
        });
      }
    });

    widget.validators.push(maxFileSize);
    widget.validators.push(maxResolution);
  }

  pushImageIntoState(data, count) {
    let newImage = {
      width: data.originalImageInfo.width,
      height: data.originalImageInfo.height,
      filename: data.name,
      external_id: data.uuid,
      host: "uploadcare",
      path: data.uuid + "/file_" + data.uuid,
      source: data.sourceInfo.source,
      url: data.cdnUrl,
      filesize: data.size,
      format: data.originalImageInfo.format,
      art_type_id: this.state.art_type_id,
    };

    if (this.sizeWarning(newImage)) {
      this.setState({ undersizedImages: true });
    }
    var joined = this.state.images_to_save.concat(newImage);
    this.setState({ images_to_save: joined });
    if (this.state.images_to_save.length == count) {
      this.saveToDatabase();
    }
  }

  sizeWarning(newImage) {
    let dpih = newImage.height / this.props.big_frame_height;
    let dpiw = newImage.width / this.props.big_frame_width;
    if (Math.min(dpih, dpiw) < 90) {
      return true;
    }
  }

  saveToDatabase() {
    $.ajax({
      method: "PATCH",
      url: this.props.post_image_url,
      data: {
        selection: { images_attributes: this.state.images_to_save },
      },
    })
      .done(this.saveSuccess)
      .fail(this.saveFail);
  }

  saveSuccess(data) {
    this.setState({ images_to_save: [] });
    this.props.onImagesUploaded(data.images);
    let widget = uploadcare.Widget("[role=uploadcare-uploader]");
    widget.value(null);
  }

  saveFail() {
    console.log("Failed to save");
  }

  closeModal() {
    this.setState({ undersizedImages: false });
  }

  render() {
    return (
      <div
        className={classNames(
          "tray-button",
          this.props.deleteImageMode && "deleteImageMode"
        )}
      >
        <Tooltip
          title={`👇 ${
            !isMobile ? "Click here or drag & drop" : "Tap here"
          } to upload files`}
          open={true}
          arrow={true}
          position="top"
          distance={30}
          open={this.props.showTooltip}
        >
          <input
            type="hidden"
            role="uploadcare-uploader"
            name="content"
            data-tabs={this.state.fromSources}
            data-images-only="true"
            data-preview-step="true"
            data-clearable="true"
            data-multiple="true"
            data-image-shrink={this.state.shrink}
          />
        </Tooltip>
        {!this.props.trayIsOpen && this.props.images && (
          <button
            className={classNames(
              "text-sm hidden md:block mx-auto mt-3",
              this.props.deleteImageMode
                ? "btn btn-primary px-8"
                : "text-brand-500 underline"
            )}
            onClick={this.props.toggleDeleteImageMode}
          >
            {this.props.deleteImageMode ? "Done" : "Edit"}
          </button>
        )}
        <Modal
          open={this.state.undersizedImages}
          onClose={function () {}}
          classNames={{
            overlay: "custom-overlay",
            modal: "custom-modal small",
          }}
        >
          <div className="text-center">
            <h3 className="font-weight-bold">
              <i className="fa-solid fa-triangle-exclamation text-red-600"></i>{" "}
              Whoops!
            </h3>
            <p className="lead">
              The resolution of one or more of your photos is too low for us to
              ensure a high-quality print.
            </p>
            <div
              className="btn btn-primary btn-lg mt-3"
              onClick={this.closeModal}
            >
              OK
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default MultiImagePicker;
